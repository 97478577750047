import { alpha, Theme } from '@mui/material/styles'
import { AlignItems } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function FormControl(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          [breakpoints.up('xs')]: {
            marginBottom: pxToRem(4),
          },
          '& .MuiInputBase-root.MuiInputBase-formControl': {
            marginBottom: 0,
          },
          '& .MuiCustomInput-root': {
            '&.PhoneInput-root': {
              '& .MuiTypography-root.InputLabel-root': {
                padding: 0,
                marginBottom: pxToRem(6),
                fontSize: pxToRem(12),
                fontWeight: 500,
                position: 'absolute',
                display: 'block',
                transformOrigin: 'top left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                left: 0,
                top: 0,
                color: theme.palette.common.black,
                transform: 'translate(14px, 9px) scale(1)',
                zIndex: 1,
                pointerEvents: 'auto',
                userSelect: 'none',
                [breakpoints.up('md')]: {
                  fontSize: pxToRem(14),
                },
                '&.Mui-focused': {
                  marginBottom: 0,
                  paddingLeft: pxToRem(8),
                  paddingRight: pxToRem(8),
                  display: 'inline-block',
                  visibility: 'visible',
                  transform: 'translate(8px, -7px) scale(0.85)',
                  color: theme.palette.blue.light,
                  backgroundColor: palette.common.white,
                  '&.Mui-disabled': {
                    color: alpha(theme.palette.common.black, 0.38),
                    WebkitTextFillColor: alpha(theme.palette.common.black, 0.38),
                    '& .MuiInputLabel-asterisk.MuiFormLabel-asterisk': {
                      color: alpha(theme.palette.error.main, 0.38),
                    },
                  },
                  '& .MuiInputLabel-asterisk.MuiFormLabel-asterisk': {
                    color: palette.error.main,
                  },
                  '&.Mui-error': {
                    color: palette.error.main,
                    fontWeight: 500,
                  },
                  '& .MuiInputLabel-asterisk': {
                    marginLeft: pxToRem(2),
                    color: palette.error.main,
                  }
                },
              },
              '& .MuiBox-root.PhoneInputContainer-root': {
                marginBottom: 0,
                paddingRight: 0,
                background: 'transparent',
                overflow: 'unset',
                lineHeight: pxToRem(18),
                '& .PhoneInput': {
                  width: '100%',
                  '& .PhoneInputCountry': {
                    marginRight: pxToRem(8),
                    padding: `${pxToRem(8)} 0 ${pxToRem(8)} ${pxToRem(12)}`,
                    boxSizing: 'content-box',
                    display: 'flex',
                    alignItems: AlignItems.center,
                    height: pxToRem(20.13),
                  },
                  '& .PhoneInputInput': {
                    padding: `${pxToRem(8)} ${pxToRem(12)} ${pxToRem(8)} 0`,
                    boxSizing: 'content-box',
                    border: 'none',
                    display: 'flex',
                    alignItems: AlignItems.center,
                    width: '100%',
                    height: pxToRem(20.13),
                    font: 'inherit',
                    letterSpacing: 'inherit',
                    color: 'currentColor',
                    background: 'none',
                    '&:focus-visible': {
                      outline: 'none',
                    },
                  },
                  '&.PhoneInput--disabled': {
                    '& .PhoneInputCountry': {
                      opacity: 0.38,
                    },
                    '& .PhoneInputInput': {
                      color: alpha(theme.palette.common.black, 0.38),
                    },
                  },
                },
              },
              '& .PhoneInputWrapper-root': {
                '&.Mui-error': {
                  borderColor: palette.error.main,
                },
              },
              '& .PhoneInputHelperText-root': {
                marginTop: pxToRem(4),
                marginBottom: 0,
                marginLeft: pxToRem(14),
                marginRight: pxToRem(14),
                fontWeight: 400,
                textAlign: 'left',
                fontSize: pxToRem(12),
                lineHeight: pxToRem(16),
                [breakpoints.up('md')]: {
                  fontSize: pxToRem(14),
                  lineHeight: pxToRem(18),
                },
                '&.Mui-error': {
                  color: palette.error.main,
                  fontWeight: 500,
                },
              },
            },
          },
        },
      },
    },
  }
}