import React, { memo } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Language } from 'redux/utils/enums'
import { SessionData, StoredSession } from 'redux/utils/session.types'
import { ReduxStore } from 'redux/utils/types'
import {
  BASE_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PASSWORD_RESET_PATH,
  SIGNUP_PATH,
  STORAGE_KEY_USER_PROFILE,
  USERS_HOME_PATH,
  USERS_VALIDATE_EMAIL_PATH,
} from 'utils/constants'
import { getFromLocalStorage } from 'utils/libs'

type Props = {
  language: Language
  isSignedIn: boolean
  pathname: string
  sessionData: SessionData
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AccessControl = (WrappedComponent: any) => {
  const SecuredControl = (props: Props) => {
    const { isSignedIn, language, pathname } = props
    const storedSession = getFromLocalStorage<StoredSession>(STORAGE_KEY_USER_PROFILE)
    const storedIsSignedIn = storedSession?.isSignedIn

    const isUserLogged = isSignedIn || storedIsSignedIn

    if (!pathname) {
      return null
    }

    const pathIsGuestHome = pathname.includes(`${BASE_PATH}${HOME_PATH}`) || pathname === BASE_PATH
    const pathIsLoginPage = pathname.includes(`${BASE_PATH}${LOGIN_PATH[language]}`)
    const pathIsSignupPage = pathname.includes(`${BASE_PATH}${SIGNUP_PATH[language]}`)
    const pathIsValidateEmailPage = pathname.includes(`${BASE_PATH}${USERS_VALIDATE_EMAIL_PATH[language]}`)
    const pathIsForgotPasswordPage = pathname.includes(`${BASE_PATH}${FORGOT_PASSWORD_PATH[language]}`)
    const pathIsPasswordResetPage = pathname.includes(`${BASE_PATH}${PASSWORD_RESET_PATH[language]}`)
    const loginPath = `${BASE_PATH}${LOGIN_PATH[language]}`
    const usersHomePath = `${BASE_PATH}${USERS_HOME_PATH[language]}`

    const nonRestrictedPages = pathIsGuestHome
      || pathIsLoginPage
      || pathIsSignupPage
      || pathIsValidateEmailPage
      || pathIsPasswordResetPage
      || pathIsForgotPasswordPage

    // If the user is not logged and tries to access a restricted page, redirect to the Sign-In page.
    if (!isUserLogged && !nonRestrictedPages) {
      return <Navigate to={loginPath} />
    }

    // If the user is already logged and tries to access any Non-Restricted users, redirect to the Users' Home page.
    if (isUserLogged && nonRestrictedPages) {
      return <Navigate to={usersHomePath} />
    }

    // Render the requested page.
    return <WrappedComponent {...props} />
  }

  const mapStateToProps = ({ languageStore, locationStore, sessionStore }: ReduxStore) => {
    const { language } = languageStore
    const { pathname } = locationStore
    const {
      isSignedIn,
      sessionData,
    } = sessionStore

    return {
      language,
      isSignedIn,
      pathname,
      sessionData,
    }
  }

  return connect(mapStateToProps)(memo(SecuredControl))
}

export default AccessControl