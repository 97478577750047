import { Theme, alpha } from '@mui/material/styles'
import { AlignItems } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function Dialog(theme: Theme) {
  const { breakpoints, palette } = theme
  const alphaLevel = 0.3
  const iconSizeXs = pxToRem(16)
  const iconSizeMd = pxToRem(17.6)
  const iconSize = { xs: iconSizeXs, md: iconSizeMd }
  const gridGap = pxToRem(4)
  const commomPropsForSingleIcon = {
    borderBottom: 'none',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap,
    '& .MuiSvgIcon-root': {
      fontSize: iconSize,
    },
  }
  const commomPropsForDoubleIcon = {
    gridTemplateColumns: 'auto 1fr auto',
  }

  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-container': {
            '& .MuiPaper-root.MuiDialog-paper': {
              margin: pxToRem(16),
              '& .MuiTypography-root.MuiDialogTitle-root': {
                padding: pxToRem(16),
                '&.MuiDialogTitle-root': {
                  padding: pxToRem(8),
                  marginBottom: 0,
                  display: 'flex',
                  alignItems: AlignItems.center,
                  color: palette.common.black,
                  backgroundColor: palette.common.white,
                  borderBottom: `1px solid ${palette.app.border}`,
                  cursor: 'default',
                  '&.info': {
                    color: palette.info.dark,
                    backgroundColor: alpha(palette.info.light, alphaLevel),
                    ...commomPropsForSingleIcon,
                    '&.closable': {
                      ...commomPropsForDoubleIcon
                    },
                  },
                  '&.success': {
                    color: palette.success.dark,
                    backgroundColor: alpha(palette.success.light, alphaLevel),
                    ...commomPropsForSingleIcon,
                    '&.closable': {
                      ...commomPropsForDoubleIcon
                    },
                  },
                  '&.warning': {
                    color: palette.warning.dark,
                    backgroundColor: alpha(palette.warning.light, alphaLevel),
                    ...commomPropsForSingleIcon,
                    '&.closable': {
                      ...commomPropsForDoubleIcon
                    },
                  },
                  '&.error': {
                    color: palette.error.dark,
                    backgroundColor: alpha(palette.error.light, alphaLevel),
                    ...commomPropsForSingleIcon,
                    '&.closable': {
                      ...commomPropsForDoubleIcon,
                    },
                  },
                  '&.closable': {
                    gridTemplateColumns: '1fr auto',
                    gridGap,
                    '& .MuiSvgIcon-root': {
                      fontSize: iconSize,
                      '&:last-child': {
                        cursor: 'pointer',
                      },
                    },
                  },
                },
              },
              '& .MuiDialogContent-root': {
                padding: pxToRem(8),
              },
              '& .MuiDialogActions-root': {
                '& .MuiButtonBase-root.MuiButton-root': {
                  fontSize: pxToRem(11),
                  [breakpoints.up('md')]: {
                    fontSize: pxToRem(12),
                  },
                  [breakpoints.up('lg')]: {
                    fontSize: pxToRem(12),
                  },
                },
              }
            },
          },
        },
      },
    },
  }
}