import { api } from 'api'
import { Action, ActionResponseType } from 'redux/utils/enums'
import { formatApiParams } from 'redux/utils/libs'
import { GetStatesParams, StatesResponse } from 'redux/utils/states.types'
import { ServerResponse } from 'redux/utils/types'

export const getStates = (jsonParams: GetStatesParams) => async (dispatch) => {
  try {
    dispatch({
      type: Action.STATES_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    // Trigger the Get States List API.
    const serverResponse: ServerResponse<ActionResponseType.statesList> = (await api.get('api/states.php', {
      params,
    })).data

    const statesResponse: StatesResponse[] = serverResponse.response

    dispatch({
      type: Action.STATES_READ,
      payload: statesResponse,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.STATES_LOADING,
      payload: false,
    })
  }
}
