import { Theme, alpha } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'
import { BorderStyle } from 'theme/utils/enums'

export default function TextField(theme: Theme) {
  const { palette } = theme

  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiInputLabel-root': {
              '&.Mui-disabled': {
                marginBottom: 0,
                transform: 'translate(8px, -7px) scale(0.85)',
                backgroundColor: palette.common.white,
                paddingLeft: pxToRem(8),
                paddingRight: pxToRem(8),
              },
            },
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.common.black,
            transform: 'translate(14px, 9px) scale(1)',
            '& .MuiInputLabel-asterisk.MuiFormLabel-asterisk': {
              color: palette.error.main,
            },
            '&.Mui-focused': {
              marginBottom: 0,
              paddingLeft: pxToRem(8),
              paddingRight: pxToRem(8),
              transform: 'translate(8px, -7px) scale(0.85)',
              color: theme.palette.blue.light,
              backgroundColor: palette.common.white,
              '&.Mui-error': {
                color: palette.error.main,
                fontWeight: 500,
              },
            },
            '&.Mui-disabled': {
              color: alpha(theme.palette.common.black, 0.38),
              WebkitTextFillColor: alpha(theme.palette.common.black, 0.38),
            },
          },
          '& .MuiInputBase-root': {
            color: theme.palette.common.black,
            paddingRight: 0,
            overflow: 'hidden',
            '&.MuiFilledInput-root': {
              backgroundColor: palette.common.white,
            },
            '&.MuiOutlinedInput-root': {
              backgroundColor: 'transparent',
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: pxToRem(1),
                borderStyle: BorderStyle.solid,
                borderColor: theme.palette.app.border,
              },
              '&.Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.error.main,
                  fontWeight: 500,
                },
              },
            },
            '&.MuiInput-underline': {
              backgroundColor: 'transparent',
              '&:before': {
                borderBottomWidth: pxToRem(1),
                borderBottomStyle: BorderStyle.solid,
                borderBottomColor: theme.palette.app.border,
              },
              '&.Mui-error': {
                '&:before': {
                  borderBottomColor: theme.palette.error.main,
                  fontWeight: 500,
                },
              },
            },
            '&.Mui-disabled': {
              color: alpha(theme.palette.common.black, 0.38),
              WebkitTextFillColor: alpha(theme.palette.common.black, 0.38),
              '&.MuiFilledInput-root': {
                backgroundColor: theme.palette.grey[100],
                borderColor: alpha(theme.palette.app.border, 0.23),
              },
              '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: alpha(theme.palette.app.border, 0.23),
              },
              '&.MuiInput-underline:before': {
                borderBottomColor: alpha(theme.palette.app.border, 0.23),
              },
            },
            '& .MuiInputBase-input': {
              padding: `${pxToRem(8)} ${pxToRem(12)}`,
              boxShadow: `0 0 0 1000px ${theme.palette.common.white} inset`,
            },
            '& .MuiInputAdornment-root': {
              '&.MuiInputAdornment-sizeSmall': {
                height: pxToRem(34.13),
                maxHeight: 'unset',
              },
            },
          },
          '& .MuiFormHelperText-root.Mui-error': {
            fontWeight: 500,
          },
        },
      },
    },
  }
}