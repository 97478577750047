import { Theme } from '@mui/material/styles'
import { JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function BottomNavigation(theme: Theme) {
  const { palette } = theme
  return {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: palette.app.navigation.background,
          width: '100%',
          '& .MuiButtonBase-root.MuiBottomNavigationAction-root': {
            color: palette.app.navigation.inactiveLink,
            fontSize: pxToRem(24),
            ['@media (max-width: 314px)']: {
              paddingTop: pxToRem(12),
              fontSize: pxToRem(16),
              justifyContent: `${JustifyContent.flexStart}`,
              minWidth: pxToRem(64),
            },
            '&.Mui-selected': {
              color: palette.app.navigation.activeLink,
            },
            '& .MuiBottomNavigationAction-label': {
              fontSize: pxToRem(9),
            },
            '& .MuiBadge-root': {
              '& .MuiBadge-badge': {
                padding: `0 ${pxToRem(4)}`,
                minWidth: 'unset',
                maxWidth: pxToRem(24),
                width: 'auto',
                height: pxToRem(16),
                color: palette.common.black,
                backgroundColor: palette.secondary.main,
                fontSize: pxToRem(9),
                borderRadius: pxToRem(8),
              },
            },
          },
        },
      },
    },
  }
}