import { StyleSheet } from '@react-pdf/renderer'
import { AlignItems, JustifyContent } from 'components/utils/enums'

export const styles = StyleSheet.create({
  body: {
    padding: 16,
    position: 'relative',
    fontSize: 9,
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    flexDirection: 'column',
    backgroundColor: '#ffffff'
  },
  headerContainer: {
    flexDirection: 'row',
  },
  headerLogo: {
    marginRight: 8,
    width: 30,
    height: 30
  },
  headerDescriptionContainer: {
    flexDirection: 'column',
  },
  headerDescriptionTitle: {
    marginBottom: 4,
    fontFamily: 'Montserrat-Bold',
    fontSize: 11,
    fontWeight: 700
  },
  headerDescriptionSubtitle: {
    marginBottom: 4,
    fontFamily: 'Montserrat',
    fontSize: 9,
  },
  machineCard: {
    marginTop: 16,
    marginBottom: 8,
    flexDirection: 'row',
    borderRadius: 4,
  },
  machinePhotoContainer: {
    margin: 0,
    padding: 0,
    width: '50%',
    border: '1px solid #cccccc',
    borderRight: '0 solid #ffffff',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  machinePhoto: {
    width: '290px',
    height: '196px',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    objectFit: 'cover'
  },
  machineDetailsContainer: {
    margin: 0,
    padding: 8,
    width: '50%',
    border: '1px solid #cccccc',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    flexDirection: 'column'
  },
  machineDetailsRow: {
    marginBottom: 4,
    flexDirection: 'row',
  },
  machineCategory: {
    width: '100%',
    fontFamily: 'Montserrat-Bold',
  },
  machineDescription: {
    width: 116,
    fontFamily: 'Montserrat-Bold',
  },
  machineValue: {
    width: 'calc(100% - 116px)',
  },
  descriptionCard: {
    marginTop: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: `${JustifyContent.center}`,
    border: '1px solid #cccccc',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    flexWrap: 'wrap',
    textAlign: 'justify',
  },
  descriptionTitle: {
    padding: 8,
    width: '100%',
    fontFamily: 'Montserrat-Bold',
    color: '#ffffff',
    backgroundColor: 'rgb(66, 133, 244)',
    textAlign: 'left',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  descriptionContent: {
    padding: 8,
    width: '100%',
  },
  quotationCard: {
    marginTop: 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: `${JustifyContent.center}`,
    border: '1px solid #cccccc',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  quotationTitle: {
    padding: 8,
    width: '100%',
    fontFamily: 'Montserrat-Bold',
    color: '#ffffff',
    backgroundColor: 'rgb(66, 133, 244)',
    textAlign: 'left',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  quotationTableRowOdd: {
    padding: '4px 8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: `${AlignItems.center}`,
  },
  quotationTableRowEven: {
    padding: '4px 8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: `${AlignItems.center}`,
    backgroundColor: 'rgb(207, 224, 252)',
  },
  quotationDescription: {
    width: '40%',
    fontFamily: 'Montserrat-Bold',
  },
  quotationValue: {
    width: '60%',
    display: 'flex',
    textAlign: 'right',
  },
  photosContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: `${JustifyContent.spaceBetween}`,
  },
  photoBorder: {
    marginVertical: 8,
    padding: 0,
    width: 170,
    height: 102,
    borderRadius: 4,
    border: '1px solid #000',
  },
  photo: {
    width: 168,
    height: 100,
    borderRadius: 3,
    objectFit: 'cover',
  },
  footer: {
    marginTop: 16,
    width: '100%',
    borderTop: '1px solid #000',
    position: 'absolute',
    bottom: 16,
    left: 16,
    right: 16,
    overflow: 'hidden',
  },
})