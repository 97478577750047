import {
  CheckCircleOutlined as SuccessIcon,
  ErrorOutlineOutlined as ErrorIcon,
  WarningAmberOutlined as WarningIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material'
import { NotificationType } from 'components/utils/enums'
import { MUIIcon } from 'utils/types'

export const useGetDialogIcon = (dialogType?: NotificationType): MUIIcon | null => {
  switch (dialogType) {
    case NotificationType.info:
      return InfoIcon
    case NotificationType.success:
      return SuccessIcon
    case NotificationType.warning:
      return WarningIcon
    case NotificationType.error:
      return ErrorIcon
    default:
      return null
  }
}