import { Typography } from '@mui/material'
import AccessControl from 'helpers/AccessControl'
import kebabCase from 'lodash/kebabCase'
import { HomeCarousel as Carousel } from 'pages/components/Carousel'
import PageContainer from 'pages/components/PageContainer'
import React, { FC, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { getMachinesCarousel } from 'redux/actions/machinery'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { MachinesCarouselResponse } from 'redux/utils/machinery.types'
import { ReduxStore } from 'redux/utils/types'
import { BASE_PATH, MACHINERY_CATALOG_PATH } from 'utils/constants'
import { CarouselItem } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  machinesCarousel: MachinesCarouselResponse[]
  machinesCarouselLoading: boolean
  dispatchGetMachinesCarousel: () => void
}

const UnconnectedHome: FC<Props> = ({
  dictionary,
  language,
  machinesCarousel,
  machinesCarouselLoading,
  dispatchGetMachinesCarousel
}) => {
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([])
  const homeDictionary = dictionary.home
  const machineryDictionary = dictionary.machinery

  useEffect(() => {
    dispatchGetMachinesCarousel()
  }, [])

  useEffect(() => {
    if (machinesCarousel?.length) {
      const newCarouselItems: CarouselItem[] = machinesCarousel.map(machine => {
        const { category, manufacturer, model, photo, reference } = machine
        const categoryPath = kebabCase(category)
        const manufacturerPath = kebabCase(manufacturer)
        const modelPath = kebabCase(model)

        return {
          id: machine.reference,
          title: `${category} ${manufacturer} ${model}`,
          imagePath: photo,
          footer: `${machineryDictionary.reference}: ${reference}`,
          linkLabel: machineryDictionary.viewDetails,
          linkURL: `${BASE_PATH}${MACHINERY_CATALOG_PATH[language]}/${categoryPath}/${manufacturerPath}/${modelPath}/${reference}`,
        }
      })

      setCarouselItems(newCarouselItems)
    }
  }, [machinesCarousel])

  return (
    <PageContainer ogDescription={dictionary.home.pageDescription}>
      <Typography variant="caption">
        {ReactHtmlParser(homeDictionary.content)}
      </Typography>
      <Carousel
        items={carouselItems}
        width={520}
        height={255}
        auto
        loading={machinesCarouselLoading}
      />
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, machineryStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { machinesCarousel, machinesCarouselLoading } = machineryStore

  return {
    dictionary,
    language,
    machinesCarousel,
    machinesCarouselLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetMachinesCarousel: () => dispatch(getMachinesCarousel()),
})

const Home: any = AccessControl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedHome))

export default Home