import { useGoogleLogin } from '@react-oauth/google'
import { gapi } from 'api'
import { GoogleButton, GoogleIconStyle } from 'components/styledComponents'
import React from 'react'
import { connect } from 'react-redux'
import { updateGoogleProfile } from 'redux/actions/session'
import { checkUsernameAvailability } from 'redux/actions/users'
import { LanguageDictionary } from 'redux/utils/language.types'
import { formatResponseParams } from 'redux/utils/libs'
import { GoogleProfileData } from 'redux/utils/session.types'
import { ReduxStore } from 'redux/utils/types'

type Props = {
  dictionary: LanguageDictionary
  isSignedIn: boolean
  dispatchCheckUsernameAvailability: (username: string) => void
  dispatchUpdateGoogleProfile: (googleProfile: GoogleProfileData) => void
}

const GoogleSignUp = (props: Props) => {
  const {
    dictionary: { signUp: signUpDictionary },
    isSignedIn,
    dispatchUpdateGoogleProfile,
    dispatchCheckUsernameAvailability,
    // dispatchGoogleSignIn,
  } = props
  /**
   * Performs a Sign-Up action when the user clicks on the "Google" button for authentication.
   */
  const handleGoogleButtonClick = () => useGoogleLogin({
    onSuccess: async(codeResponse) => {
      const googleResponse: GoogleProfileData = (await gapi.get(`userinfo?access_token=${codeResponse?.access_token}`, {
        headers: {
          authorization: `Bearer ${codeResponse?.access_token}`,
          accept: 'application/json'
        },
      })).data

      const formattedResponse = formatResponseParams<GoogleProfileData>(googleResponse)

      if (formattedResponse) {
        dispatchUpdateGoogleProfile(formattedResponse)
        dispatchCheckUsernameAvailability(formattedResponse.email)
      }
    },
    onError: (error) => console.error('Google Authentication Failed', error),
    flow: 'implicit',
  })

  if (isSignedIn) {
    return null
  }

  return (
    <GoogleButton
      variant="outlined"
      onClick={handleGoogleButtonClick}
    >
      <GoogleIconStyle size={14} viewBox="0 0 48 48" />
      {signUpDictionary.googleSignUp}
    </GoogleButton>
  )
}

const mapStateToProps = ({ languageStore, sessionStore }: ReduxStore) => {
  const { dictionary } = languageStore
  const { isSignedIn } = sessionStore

  return {
    dictionary,
    isSignedIn,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchCheckUsernameAvailability: (username: string) => dispatch(checkUsernameAvailability(username)),
  dispatchUpdateGoogleProfile: (googleProfile: GoogleProfileData) => dispatch(updateGoogleProfile(googleProfile)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleSignUp)