import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Button(theme: Theme) {
  const { breakpoints, palette, shape } = theme
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          [breakpoints.up('md')]: {
            marginBottom: pxToRem(8),
          },
          [breakpoints.up('xl')]: {
            marginBottom: pxToRem(16),
          },
          marginBottom: pxToRem(16),
          padding: pxToRem(8),
          borderRadius: shape.borderRadius,
          backgroundColor: palette.app.breadcrumbs.background,
          width: '100%',
          '& .MuiBreadcrumbs-li': {
            marginBottom: 0,
            '& .MuiTypography-caption': {
              color: palette.app.breadcrumbs.inactivePath,
              fontWeight: 400,
              '&.MuiLink-root': {
                color: palette.app.breadcrumbs.inactivePath,
                '&:hover': {
                  color: palette.app.breadcrumbs.activePath,
                },
              },
            },
          },
          '& .MuiBreadcrumbs-separator': {
            marginBottom: 0,
          },
        },
      },
    },
  }
}
