import GuestsMenuOptions from 'components/Navigation/Header/GuestsMenuOptions'
import MobileMenu from 'components/Navigation/Header/MobileMenu'
import Logo from 'components/Navigation/Logo'
import { HeaderContainerDesktop, MainContainer } from 'components/Navigation/styledComponents'
import { DeviceVisibility } from 'components/utils/enums'
import isEqual from 'lodash/isEqual'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { updateLocation } from 'redux/actions/location'
import { LocationState } from 'redux/utils/location.types'
import { ReduxStore } from 'redux/utils/types'

type Props = {
  reduxLocation: LocationState
  isSignedIn: boolean
  dispatchUpdateLocation: (newLocation: LocationState) => void
}

const Header: FC<Props> = ({
  reduxLocation,
  isSignedIn,
  dispatchUpdateLocation,
}) => {
  const location = useLocation() || {}

  if (!isEqual(location, reduxLocation)) {
    dispatchUpdateLocation(location)
  }

  return (
    <>
      {!isSignedIn && (
        <HeaderContainerDesktop>
          <MainContainer>
            <Logo />
            <GuestsMenuOptions device={DeviceVisibility.desktop} />
          </MainContainer>
        </HeaderContainerDesktop>
      )}
      <MobileMenu />
    </>
  )
}

const mapStateToProps = ({ locationStore, sessionStore }: ReduxStore) => {
  const reduxLocation = locationStore
  const { isSignedIn } = sessionStore

  return {
    reduxLocation,
    isSignedIn,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateLocation: (newLocation: LocationState) => dispatch(updateLocation(newLocation)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
