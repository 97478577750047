import { Typography } from '@mui/material'
import CustomInputLabel from 'components/FormFields/CustomInputLabel'
import {
  CustomInputInnerContainer,
  CustomInputMainContainer,
  CustomInputWrapper,
  StyledFormControl,
} from 'components/FormFields/styledComponents'
import { InputSize, InputVariant } from 'components/utils/enums'
import React, { FC, FocusEvent, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  dictionary: LanguageDictionary
  label: string
  value?: string
  placeholder?: string
  size?: InputSize
  fullWidth?: boolean
  required?: boolean
  isMissing?: boolean
  disabled?: boolean
  marginTop?: string | number
  marginBottom?: string | number
  onBlur?: (hasErrors?: boolean) => void
  onChange: (newValue: string) => void
}

const UnconnectedPhoneNumberInput: FC<Props> = ({
  dictionary: { error: errorDictionary },
  label,
  value = '',
  placeholder,
  size = InputSize.small,
  fullWidth,
  required,
  isMissing,
  disabled,
  marginTop = pxToRem(8),
  marginBottom = pxToRem(16),
  onBlur,
  onChange,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isTouched, setIsTouched] = useState<boolean>(false)

  const isError: boolean = !!errorMessage

  // Initialize the "className" prop.
  let inputWrapperClassName: string = 'PhoneInputWrapper-root'
  let helperClassName: string = 'PhoneInputHelperText-root'

  // Add the "Mui-error" class when the "error" prop has been provided.
  if (isError) {
    inputWrapperClassName += ' Mui-error'
    helperClassName += ' Mui-error'
  }

  /**
   * Handles the event when the input loses its focus.
   */
   const handleBlur = (_event: FocusEvent<HTMLInputElement>): void => {
    // Set the input has been touched, which means the input has been focused at least once.
    setIsTouched(true)

    // Check wheter the input value has an error due it's required.
    const isRequiredError = required && !value

    // Keep the normal Blur behavior when there is no error.
    if (!isRequiredError) {
      onBlur?.()
      return
    }

    // Define the error message
    if (isRequiredError) {
      setErrorMessage(errorDictionary.requiredValue)
    }

    onBlur?.(isRequiredError)
  }

  const handleValueChange = (newValue: string) => {
    onChange(newValue)
  }

  useEffect(() => {
    // Set an error message when the field is required but it is missing.
    const isRequiredError = required && !value && (isTouched || isMissing)
    const newErrorMessage = isRequiredError ? errorDictionary.requiredValue : ''
    setErrorMessage(newErrorMessage)

    // Set the field as touched when it is required and missing and the "isTouched" flag is off.
    if (required && isMissing && !isTouched) {
      setIsTouched(true)
    }
  }, [value, required, isTouched, isMissing])

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      marginTop={marginTop}
      marginBottom={marginBottom}
      variant={InputVariant.outlined}
      size={size}
    >
      <CustomInputMainContainer className="MuiCustomInput-root PhoneInput-root">
        <CustomInputLabel
          label={label}
          error={isError}
          required={required}
          disabled={disabled}
          focused
        />
        <CustomInputInnerContainer className="PhoneInputContainer-root">
          <PhoneInput
            placeholder={placeholder}
            countryCallingCodeEditable={false}
            defaultCountry="MX"
            countryOptionsOrder={['MX', 'US', 'CA', '...']}
            international={false}
            value={value}
            disabled={disabled}
            onChange={handleValueChange}
            onBlur={handleBlur}
          />
          <CustomInputWrapper className={inputWrapperClassName} />
        </CustomInputInnerContainer>
        {!!errorMessage && (
          <Typography className={helperClassName}>
            {errorMessage}
          </Typography>
        )}
      </CustomInputMainContainer>
    </StyledFormControl>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const PhoneNumberInput = connect(
  mapStateToProps,
)(UnconnectedPhoneNumberInput)