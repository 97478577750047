import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { StyledFormControl } from 'components/FormFields/styledComponents'
import { InputSize, InputVariant } from 'components/utils/enums'
import { DropdownStructure } from 'components/utils/types'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  id: string
  dictionary: LanguageDictionary
  disabled?: boolean
  fullWidth?: boolean
  label: string
  marginBottom?: string | number
  marginTop?: string | number
  options: DropdownStructure[]
  required?: boolean
  size?: InputSize
  value: string[]
  variant?: InputVariant
  onBlur?: (hasErrors?: boolean) => void
  onChange: (newValue: string[]) => void
}

export const UnconnectedDropdownMultiple: FC<Props> = ({
  id,
  dictionary: { erro: errorDictionary },
  disabled,
  fullWidth,
  label,
  marginBottom = pxToRem(16),
  marginTop = pxToRem(8),
  options,
  required,
  size = InputSize.small,
  value,
  variant = InputVariant.outlined,
  onBlur,
  onChange,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isTouched, setIsTouched] = useState<boolean>(false)

  const className: string | undefined = required ? 'required' : undefined

  /**
   * Handles the event when the input value has changed.
   * @param event The input event handler which contains the selected value.
   */
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target
    const newValue = typeof value === 'string'
      ? value.split(',')
      : value

    onChange(newValue)
  }

  /**
   * Handles the event when the input loses focus.
   */
   const handleBlur = (): void => {
    // Set the input has been touched, which means the input has been focused at least once.
    setIsTouched(true)

    // Check wheter the input value has an error due it's required.
    const isRequiredError = required && !value

    // Keep the normal Blur behavior when there is no error.
    if (!isRequiredError) {
      onBlur?.()
      return
    }

    // Define the error message
    setErrorMessage(errorDictionary.requiredValue)
    onBlur?.(true)
  }

  useEffect(() => {
    const isRequiredError = required && !value && isTouched

    if (isRequiredError) {
      setErrorMessage(errorDictionary.requiredValue)
    } else {
      setErrorMessage('')
    }
  }, [value])

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      marginTop={marginTop}
      marginBottom={marginBottom}
      variant={variant}
      size={size}
      error={!!errorMessage}
    >
      <InputLabel id={`${id}-label`} className={className}>
        {label}
      </InputLabel>
      <Select
        className={className}
        id={id}
        label={label}
        value={value}
        size={size}
        multiple
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const DropdownMultiple = connect(
  mapStateToProps,
)(UnconnectedDropdownMultiple)
