import { api } from 'api'
import { Action, ActionResponseType } from 'redux/utils/enums'
import { formatApiParams } from 'redux/utils/libs'
import {
  GetQuotationParams,
  GetQuotationResponse,
  GetQuotationDestinationsParams,
  GetQuotationDestinationsResponse,
} from 'redux/utils/quotation.types'
import { ServerResponse } from 'redux/utils/types'

export const getMachineQuotationData = (jsonParams: GetQuotationParams) => async (dispatch) => {
  try {
    dispatch({
      type: Action.QUOTATION_DATA_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    // Trigger the Get Quotation API.
    const serverResponse: ServerResponse<ActionResponseType.quotationData> = (await api.get('api/machinery/quotations.php', {
      params,
    })).data

    const quotationDataResponse: GetQuotationResponse[] = serverResponse.response

    if (quotationDataResponse) {
      quotationDataResponse.forEach((quotationData, index) => {
        Object.keys(quotationData).forEach(key => {
          if (!['country', 'state', 'distance'].includes(key)) {
            quotationDataResponse[index][key] = Number.parseFloat(Number(quotationDataResponse[index][key]).toFixed(3))
          }
        })
      })
    }

    dispatch({
      type: Action.QUOTATION_DATA_READ,
      payload: quotationDataResponse,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.QUOTATION_DATA_LOADING,
      payload: false,
    })
  }
}

export const getQuotationDestinations = (jsonParams: GetQuotationDestinationsParams) => async dispatch => {
  try {
    dispatch({
      type: Action.QUOTATION_DESTINATIONS_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    // Trigger the Get Quotation Destinations List API.
    const serverResponse: ServerResponse<ActionResponseType.quotationDestinations> = (await api.get('api/machinery/quotations.php/destinations', {
      params,
    })).data

    const quotationDestinationsResponse: GetQuotationDestinationsResponse = serverResponse.response

    dispatch({
      type: Action.QUOTATION_DESTINATIONS_READ,
      payload: quotationDestinationsResponse,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.QUOTATION_DESTINATIONS_LOADING,
      payload: false,
    })
  }
}
