import { COUNTRIES_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { CountriesAction, CountriesState } from 'redux/utils/countries.types'

export const countries = (state: CountriesState = COUNTRIES_INITIAL_STATE, action: CountriesAction): CountriesState => {
  switch (action.type) {
    case Action.COUNTRIES_LOADING:
      return {
        ...state,
        countriesLoading: action.payload,
      }
    case Action.COUNTRIES_READ:
      return {
        ...state,
        countries: action.payload,
      }
    default:
      return state
  }
}

export default countries