import { TypographyOptions } from '@mui/material/styles/createTypography'
import { THEME_FONT_FAMILY } from 'theme/utils/constants'
import { PropertyBreakPoints } from 'theme/utils/types'

export const pxToRem = (value: number) => `${value / 16}rem`

export const responsiveProperty = (properties: string[], breakpointValues: PropertyBreakPoints[]) => ({
  ...(properties.reduce((acum, cur, index) => ({
    ...acum,
    [cur]: pxToRem(breakpointValues[index].xs),
  }), {})),
  '@media (min-width: 425px)': {
    ...(properties.reduce((acum, cur, index) => ({
      ...acum,
      [cur]: pxToRem(breakpointValues[index].sm),
    }), {})),
  },
  '@media (min-width: 728px)': {
    ...(properties.reduce((acum, cur, index) => ({
      ...acum,
      [cur]: pxToRem(breakpointValues[index].md),
    }), {})),
  },
  '@media (min-width: 1024px)': {
    ...(properties.reduce((acum, cur, index) => ({
      ...acum,
      [cur]: pxToRem(breakpointValues[index].lg),
    }), {})),
  },
  '@media (min-width: 1440px)': {
    ...(properties.reduce((acum, cur, index) => ({
      ...acum,
      [cur]: pxToRem(breakpointValues[index].xl),
    }), {})),
  },
})

const typography: TypographyOptions = {
  fontFamily: THEME_FONT_FAMILY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 900,
  h1: {
    fontWeight: 800,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 20, sm: 20, md: 22, lg: 22, xl: 22 },
        { xs: 24, sm: 24, md: 26, lg: 26, xl: 26 },
        { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 },
      ],
    ),
  },
  h2: {
    fontWeight: 700,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 18, sm: 18, md: 20, lg: 20, xl: 20 },
        { xs: 22, sm: 22, md: 24, lg: 24, xl: 24 },
        { xs: 10, sm: 10, md: 12, lg: 12, xl: 12 },
      ],
    ),
  },
  h3: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        { xs: 20, sm: 20, md: 22, lg: 22, xl: 22 },
        { xs: 8, sm: 8, md: 10, lg: 10, xl: 10 },
      ],
    ),
  },
  h4: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 },
        { xs: 18, sm: 18, md: 20, lg: 20, xl: 20 },
        { xs: 6, sm: 6, md: 8, lg: 8, xl: 8 },
      ],
    ),
  },
  h5: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 },
        { xs: 18, sm: 18, md: 20, lg: 20, xl: 20 },
        { xs: 6, sm: 6, md: 8, lg: 8, xl: 8 },
      ],
    ),
  },
  h6: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 },
        { xs: 18, sm: 18, md: 20, lg: 20, xl: 20 },
        { xs: 6, sm: 6, md: 8, lg: 8, xl: 8 },
      ],
    ),
  },
  subtitle1: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        { xs: 20, sm: 20, md: 22, lg: 22, xl: 22 },
        { xs: 8, sm: 8, md: 10, lg: 10, xl: 10 },
      ],
    ),
  },
  subtitle2: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 },
        { xs: 18, sm: 18, md: 20, lg: 20, xl: 20 },
        { xs: 6, sm: 6, md: 8, lg: 8, xl: 8 },
      ],
    ),
  },
  body1: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 },
        { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        { xs: 4, sm: 4, md: 6, lg: 6, xl: 6 },
      ],
    ),
  },
  body2: {
    fontWeight: 400,
    ...responsiveProperty(
      ['fontSize', 'lineHeight', 'marginBottom'],
      [
        { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 },
        { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        { xs: 4, sm: 4, md: 6, lg: 6, xl: 6 },
      ],
    ),
  },
  caption: {
    fontWeight: 400,
    ...responsiveProperty(
      ['fontSize', 'lineHeight'],
      [
        { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 },
        { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
      ],
    ),
  },
  overline: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight'],
      [
        { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 },
        { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
      ],
    ),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    ...responsiveProperty(
      ['fontSize', 'lineHeight'],
      [
        { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 },
        { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
      ],
    ),
    textTransform: 'none',
  },
}

export default typography