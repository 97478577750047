import { StyledComponent } from '@emotion/styled'
import { Avatar, BottomNavigation, Box, List, ListItem, ListItemIcon } from '@mui/material'
import { styled } from '@mui/system'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { AvatarProps } from 'components/utils/types'
import isUndefined from 'lodash/isUndefined'
import { pxToRem } from 'theme/typography'
import {
  BOTTOM_FOOTER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'utils/constants'

export const BackIconContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mr: pxToRem(8),
  })
)

export const NavbarContainer: StyledComponent<any> = styled(ListItem)(({ theme }) =>
  theme.unstable_sx({
    width: 'auto',
  })
)

export const IconContainer: StyledComponent<any> = styled(ListItemIcon)(({ theme }) =>
  theme.unstable_sx({
    color: 'inherit',
    minWidth: pxToRem(20),
  })
)

export const HeaderContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(16),
    py: pxToRem(8),
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    justifyContent: `${JustifyContent.center}`,
    height: { xs: pxToRem(HEADER_HEIGHT_MOBILE), lg: pxToRem(HEADER_HEIGHT_DESKTOP)},
    width: '100%',
    backgroundColor: theme.palette.app.navigation.background,
    zIndex: 10,
  })
)

export const HeaderContainerDesktop: StyledComponent<any> = styled(HeaderContainer)(({ theme}) =>
  theme.unstable_sx({
    display: { xs: 'none', lg: 'flex'},
  })
)

export const HeaderContainerMobile: StyledComponent<any> = styled(HeaderContainer)(({ theme}) =>
  theme.unstable_sx({
    px: pxToRem(8),
    display: { xs: 'flex', lg: 'none'},
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  })
)

export const MainContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    width: { xs: '100%', lg: pxToRem(theme.breakpoints.values.lg), xl: pxToRem(theme.breakpoints.values.xl) },
    '&.mobile': {
      boxShadow: 'none',
      borderBottomStyle: 'none',
      borderRadius: 0,
      color: theme.palette.common.white,
      backgroundColor: 'transparent',
    },
  })
)

export const MenuContainerMobile: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    width: pxToRem(250),
    height: '100%',
  })
)

export const UsersMenuLogoContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: AlignItems.center,
    justifyContent: JustifyContent.center,
    width: '100%',
  })
)

export const GreetingMainContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    py: pxToRem(8),
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: AlignItems.center,
    width: '100%',
  })
)

export const GreetingUserDataContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  })
)

export const StyledAvatar: StyledComponent<any> = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<AvatarProps>(({ theme, backgroundColor }) =>
  theme.unstable_sx({
    ...(!isUndefined(backgroundColor) && {
      backgroundColor,
    }),
  })
)

export const GreetingContainerMobile: StyledComponent<any> = styled(Box)(({ theme}) =>
  theme.unstable_sx({
    p: pxToRem(8),
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    backgroundColor: theme.palette.app.navigation.background,
    width: '100%',
    '& .MuiTypography-body2': {
      mb: 0,
    }
  })
)

export const GreetingMobile: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  })
)

export const UsersMenuOptionsContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
  })
)

export const MainListContainerMobile: StyledComponent<any> = styled(List)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    // flexGrow: 1,
  })
)

export const FooterContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(16),
    py: pxToRem(8),
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    justifyContent: `${JustifyContent.center}`,
    height: { xs: pxToRem(HEADER_HEIGHT_MOBILE), lg: pxToRem(BOTTOM_FOOTER_HEIGHT_DESKTOP)},
    width: '100%',
    backgroundColor: theme.palette.app.navigation.background,
    '&.mobile': {

    },
    zIndex: 10,
  })
)

export const FooterMobileContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: { xs: 'flex', lg: 'none' },
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 'auto',
    boxshadow: `0 ${pxToRem(-6)} ${pxToRem(40)} rgba(0, 0, 0, 0.15)`,
    borderRadius: 0,
  })
)

export const FooterMobileBottomNavigation: StyledComponent<any> = styled(BottomNavigation)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: theme.palette.app.navigation.background,
  })
)