/**
 * HELPER:
 * Any chars means it can allow: Letters, Digits and any Special Character.
 * Alphanumeric chars means they only allow: Letters and Digits.
 * Word chars are means they only allow: Letters, Digits and Underscore.
 * Dash char is also known as Hyphen (-).
 *
 * REGEX GLOSSARY:
 *   - Sequences:
 *     ^$ = An empty string (opening and closing pattern)
 *     \w = Any word character
 *     \W = Any non-word character
 *     \s = Ay white space
 *     \S = Any non-white space
 *     \d = Any digit
 *     \D = Any non-digit
 *     [a-z] = Any character in the range: a-z
 *     [A-Z] = Any character in the range: A-Z
 *     [a-zA-Z] = Any character in the range: a-z or A-Z
 *     [^abc] = A character except: a, b or c.
 *     \ = Makes any character literal. For example: "\+" means the plus sign
 *         can be used into the Regex.
 *
 *   - Quantifiers:
 *     . = Any single character
 *     ? = Zero or one
 *     * = Zero or more
 *     + = One or more
 *
 *   - Anchors
 *     ^ = Start of pattern
 *     $ = End of pattern
 *     | = Or (used to specify more than one pattern to validate)
 *
 *   - Modifiers:
 *     /g = global match
 *     /i = case insensitive match
 *     /s = single line match
 *     /m = multi line match
 */
// Allows any character but it should start with an alphanumeric char.
export const REGEX_ANY_CHAR_STARTING_WITH_ALPHANUMERIC = /^$|^([^\W\s_].*?)$/gi

// Only allows word characters.
export const REGEX_WORD_CHARS = /^$|^[\w]*$/

// Only allow word characters, but it must start with an alphanumeric char.
export const REGEX_WORD_CHARS_STARTING_WITH_ALPHANUMERIC = /^$|^([^\W\s_][\w]*?)$/gi

// Only allow word characters (letters in lowercase).
export const REGEX_WORD_CHARS_IN_LOWERCASE = /^$|^[a-z\d_]*?$/

// Only allow word characters (letters in lowercase), but it must start with an alphanumeric char.
export const REGEX_WORD_CHARS_IN_LOWERCASE_STARTING_WITH_ALPHANUMERIC = /^$|^([^\W\s_][a-z\d_]*?)$/g

// Only allows digits.
export const REGEX_DIGITS_ONLY = /^$|^[\d]*$/

// Only allows a single URL starting using the prefix "http" or "https".
export const REGEX_URL_WITH_PREFIX = /^$|^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%.\+#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%\+.#?&\/=]*)$/

// Only allows a single URL without using the prefix "http" or "https".
export const REGEX_URL_WITHOUT_PREFIX = /^$|^[-a-zA-Z0-9@:%.\+#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%\+.#?&//=]*)$/

// Only allows a single valid email.
export const REGEX_SINGLE_EMAIL = /^$|^\w+([\.-]?\w+)+@\w+([\.-]?\w+)(\.\w{2,3})+$/

// Allows multiple valid emails.
export const REGEX_MULTIPLE_EMAILS = /^$|^(\w+([\.-]?\w+)+@\w+([\.-]?\w+)(\.\w{2,3})(\s*,\s*|\s*$))*$/gi

// Look for the last comma in a comma separated string.
export const REGEX_LAST_COMMA = /,(?=[^,]+$)/