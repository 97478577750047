import { ListItemButton, ListItemText, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IconContainer, NavbarContainer } from 'components/Navigation/styledComponents'
import { GUESTS_MENU_OPTIONS } from 'components/utils/constants'
import { DeviceVisibility } from 'components/utils/enums'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { BASE_PATH, GUEST_MENU_MAP } from 'utils/constants'
import { getPageFromPath } from 'utils/libs'
import { Route } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  isSignedIn: boolean
  device: DeviceVisibility.desktop | DeviceVisibility.mobile
}

const GuestsMenuOptions: FC<Props> = ({
  dictionary,
  dictionary: { navbar: navbarDictionary },
  language,
  isSignedIn,
  device,
}) => {
  const { pathname } = useLocation() || {}
  const navigate = useNavigate()
  const { breakpoints } = useTheme()
  const [navOption, setNavOption] = useState<string>('')

  const isXLView = !useMediaQuery(breakpoints.down('xl'))
  const isMobileView = !useMediaQuery(breakpoints.up('lg'))
  const page: Route | undefined = getPageFromPath(language, dictionary, pathname)
  const ignoreDevice = device === DeviceVisibility.desktop
    ? DeviceVisibility.mobile
    : DeviceVisibility.desktop

  const handleMenuChange = (newNavOption: string) => () => {
    // Store the selected menu into the State.
    setNavOption(newNavOption)

    // Move to the selected page.
    navigate(GUEST_MENU_MAP(language)[newNavOption])
  }
  
  useEffect(() => {
    const shouldResetNavOption = (!page || pathname === BASE_PATH) && !!navOption && navOption !== 'home'
    const shouldUpdateNavOption = page && navOption !== page.navOption

    if (shouldResetNavOption) {
      setNavOption('')
    } else if (shouldUpdateNavOption) {
      const { navOption: newNavOption } = page
      setNavOption(newNavOption)
    }
  }, [page])

  if (isSignedIn) {
    return null
  }

  return (
    <>
      {GUESTS_MENU_OPTIONS.filter(option => option.deviceVisibility !== ignoreDevice).map(menuOption => {
        const { id, icon: IconComponent } = menuOption

        return (
          <NavbarContainer
            key={id}
            className="Navbar"
            selected={navOption === id}
            disablePadding
            onClick={handleMenuChange(id)}
          >
            <ListItemButton>
              {(isXLView || isMobileView) && (
                <IconContainer>
                  {IconComponent && (
                    <IconComponent style={{ fontSize: pxToRem(16) }} />
                  )}
                </IconContainer>
              )}
              <ListItemText primary={navbarDictionary[id]} />
            </ListItemButton>
          </NavbarContainer>
        )
      })}
    </>
  )
}

const mapStateToProps = ({ languageStore, sessionStore }: ReduxStore) => {
  const { language, dictionary } = languageStore
  const { isSignedIn } = sessionStore

  return {
    language,
    dictionary,
    isSignedIn,
  }
}

export default connect(
  mapStateToProps
)(GuestsMenuOptions)
