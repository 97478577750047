import { encode as base64Encode, decode as base64Decode } from 'base-64'
import { AppRoutes } from 'helpers/AppRoutes'
import { matchPath } from 'react-router'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { encode as utf8Encode, decode as utf8Decode } from 'utf8'
import {
  LOWERCASE_CHARS,
  NUMBER_CHARS,
  ONE_DAY,
  ONE_HOUR,
  ONE_MINUTE,
  ONE_SECOND,
  ONE_WEEK,
  PASSWORD_GENERATOR_VALID_CHARS,
  SPECIAL_CHARS,
  UPPERCASE_CHARS,
} from 'utils/constants'
import { TimeMeasurement } from 'utils/enums'
import { NestedRoutes, Route } from 'utils/types'

export const encodeContent = (content: string) =>
  base64Encode(content)

export const encodeContentUtf8 = (content: string) =>
  base64Encode(utf8Encode(content))

export const decodeContent = (content: string) =>
  base64Decode(content)

export const decodeContentUtf8 = (content: string) =>
  utf8Decode(base64Decode(content))

const recursiveRouterHelper = (
  routesList: Route[],
  subroute: NestedRoutes,
  parentPath: string,
): Route[] | null => {
  if (!subroute) {
    return null
  }

  const curretPath: string = `${parentPath}${subroute.path}`

  const {
    headerTitle,
    pageTitle,
    navOption,
    dictionaryGroup,
    breadcrumbs,
    isExternal,
    Component,
  } = subroute

  routesList.push({
    headerTitle,
    pageTitle,
    navOption,
    dictionaryGroup,
    path: curretPath,
    breadcrumbs,
    isExternal,
    Component,
  })

  if (subroute.children) {
    subroute.children.map(page => recursiveRouterHelper(routesList, page, curretPath))
  }

  return routesList
}

/**
 * Gets the list of the existing routes.
 * @param language Specifies the language to be used for the routes.
 * @param dictionary Specifies the dictionary to use (based on the language) for the routes.
 */
export const routesList = (language: Language, dictionary: LanguageDictionary): Route[] => (
  recursiveRouterHelper([], AppRoutes(language, dictionary), '') || []
)

/**
 * Gets the information of a page based on the provided path.
 * @param language Specifies the language to be used for getting the page information.
 * @param dictionary Specifies the dictionary to use (based on the language) for getting the page information.
 * @param path The path to be used to get the page information.
 */
export const getPageFromPath = (language: Language, dictionary: LanguageDictionary, path: string): Route | undefined =>
  routesList(language, dictionary).find(route => matchPath(route.path, path))

/**
 * 
 * Gets the information of a page based on the provided navigation menu option.
 * @param language Specifies the language to be used for getting the page information.
 * @param dictionary Specifies the dictionary to use (based on the language) for getting the page information.
 * @param navOption The navigation menu option to be used to get the page information.
 */
export const getPageFromNavOption = (language: Language, dictionary: LanguageDictionary, navOption: string): Route | undefined =>
  routesList(language, dictionary).find(route => route.navOption === navOption)

/**
 * Retrieves parsed data from the Local Storage that has been stored previously.
 * @param key The key of the item to be fetched.
 */
export const getFromLocalStorage = <T>(key: string): T =>
  JSON.parse(localStorage.getItem(key) as string)

/**
 * Stores data into the Local Storage based on the provided Key and the value to be stored.
 * @param key The key of the item to be stored.
 * @param value The value to be stored into the Local Storage.
 */
export const setIntoLocalStorage = <T>(key: string, value: T): void =>
  localStorage.setItem(key, JSON.stringify(value))

/**
 * Removes a key from the Local Storage.
 * @param key The key of the item to be removed.
 */
export const deleteFromLocalStorage = (key: string): void =>
  localStorage.removeItem(key)

/**
 * Generates a random password that can be used by the users on demand.
 * @param maxLength The maximum lenght the password will handle.
 */
export const generateSecurePassword = (maxLength: number = 8) => {
  let password: string = ''

  for (let index = 0; index < maxLength; index++) {
    const randomIndex = Math.floor(Math.random() * PASSWORD_GENERATOR_VALID_CHARS.length)
    password += PASSWORD_GENERATOR_VALID_CHARS.substring(randomIndex, randomIndex + 1)
  }

  const containsNumber = NUMBER_CHARS.split('').findIndex(char => password.includes(char))
  const containsLowercase = LOWERCASE_CHARS.split('').findIndex(char => password.includes(char))
  const containsUppercase = UPPERCASE_CHARS.split('').findIndex(char => password.includes(char))
  const containsSpecial = SPECIAL_CHARS.split('').findIndex(char => password.includes(char))

  if (containsNumber === -1) {
    const randomIndex = Math.floor(Math.random() * NUMBER_CHARS.length)
    password += NUMBER_CHARS.substring(randomIndex, randomIndex + 1)
  }

  if (containsLowercase === -1) {
    const randomIndex = Math.floor(Math.random() * LOWERCASE_CHARS.length)
    password += LOWERCASE_CHARS.substring(randomIndex, randomIndex + 1)
  }

  if (containsUppercase === -1) {
    const randomIndex = Math.floor(Math.random() * UPPERCASE_CHARS.length)
    password += UPPERCASE_CHARS.substring(randomIndex, randomIndex + 1)
  }

  if (containsSpecial === -1) {
    const randomIndex = Math.floor(Math.random() * SPECIAL_CHARS.length)
    password += SPECIAL_CHARS.substring(randomIndex, randomIndex + 1)
  }

  return password
}

export const elapsedTime = (dateTimeStart: string, dateTimeEnd: string) => {
  const start = new Date(dateTimeStart)
  const end = new Date(dateTimeEnd)
  let timeDiff = end.getTime() - start.getTime()
  const weeks = Math.floor(timeDiff / ONE_WEEK)
  timeDiff -= weeks * ONE_WEEK
  const days = Math.floor(timeDiff / ONE_DAY)
  timeDiff -= days * ONE_DAY
  const hours = Math.floor(timeDiff / ONE_HOUR)
  timeDiff -= hours * ONE_HOUR
  const minutes = Math.floor(timeDiff / ONE_MINUTE)
  timeDiff -= minutes * ONE_MINUTE
  const seconds = Math.floor(timeDiff / ONE_SECOND)

  return {
    weeks,
    days,
    hours,
    minutes,
    seconds,
  }
}

export const linkExpiration = (dateTimeStart: string, dateTimeEnd: string, timeMeasurement: TimeMeasurement, upToTime: number): boolean => {
  const { weeks, days, hours, minutes, seconds } = elapsedTime(dateTimeStart, dateTimeEnd)

  switch (timeMeasurement) {
    case TimeMeasurement.weeks:
      return weeks > upToTime
    case TimeMeasurement.days:
      return weeks > 0 || days > upToTime
    case TimeMeasurement.hours:
      return weeks > 0 || days > 0 || hours > upToTime
    case TimeMeasurement.minutes:
      return weeks > 0 || days > 0 || hours > 0 || minutes > upToTime
    case TimeMeasurement.seconds:
      return weeks > 0 || days > 0 || hours > 0 || minutes > 0 || seconds > upToTime
    default:
      return true
  }
}

export const stringToColor = (string: string, saturation = 100, lightness = 75) => {
  /* eslint-disable no-bitwise */
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
    hash &= hash
  }
  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`
  /* eslint-enable no-bitwise */
}