import emailjs from '@emailjs/browser'
import { Card, Typography } from '@mui/material'
import { DynamicRow, PasswordInput, PrimaryButton, SecondaryButton } from 'components/FormFields'
import { Row } from 'components/styledComponents'
import { AlignItems, JustifyContent, NotificationType } from 'components/utils/enums'
import AccessControl from 'helpers/AccessControl'
import moment from 'moment'
import PageContainer from 'pages/components/PageContainer'
import { ADORNMENT_BG_COLOR } from 'pages/utils/constants'
import { ValidationCodeStatus } from 'pages/utils/enums'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { showAlert } from 'redux/actions/alert'
import { resetUpdatePasswordAction, updatePassword } from 'redux/actions/session'
import { ShowAlertParams } from 'redux/utils/alerts.types'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { PasswordResetParams } from 'redux/utils/session.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import {
  APP_URL,
  DATETIME_FORMATS,
  EMAIL_JS_GENERAL_TEMPLATE_ID,
  EMAIL_JS_PUBLIC_KEY,
  EMAIL_JS_SERVICE_ID,
  LOGIN_PATH,
  GUEST_MENU_MAP,
  PASSWORD_RESET_PATH,
} from 'utils/constants'
import { TimeMeasurement } from 'utils/enums'
import { decodeContentUtf8, encodeContentUtf8, linkExpiration } from 'utils/libs'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  isPasswordResetLoading?: boolean
  isPasswordResetSuccessful: boolean | null
  dispatchResetUpdatePasswordAction: () => void
  dispatchShowAlert: (alertProps: ShowAlertParams) => void
  dispatchUpdatePassword: (username: string, jsonParams: PasswordResetParams) => void
}

const UnconnectedPasswordReset: FC<Props> = ({
  dictionary: {
    error: errorDictionary,
    forgotPassword: forgotPasswordDictionary,
    passwordReset: passwordResetDictionary,
    shared: sharedDictionary,
    signIn: signInDictionary,
    signUp: signUpDictionary,
  },
  language,
  isPasswordResetLoading,
  isPasswordResetSuccessful,
  dispatchResetUpdatePasswordAction,
  dispatchShowAlert,
  dispatchUpdatePassword,
}) => {
  const navigate = useNavigate()
  const { encodedLink } = useParams()
  const { register, setValue } = useForm()
  const formRef = useRef() as MutableRefObject<HTMLFormElement>
  const [validationCodeStatus, setValidationCodeStatus] = useState<ValidationCodeStatus>(ValidationCodeStatus.unset)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [isMismatchError, setIsMismatchError] = useState<boolean>(false)
  const [isSuccessEmailSent, setIsSuccessEmailSent] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [recipientName, setRecipientName] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [buttonLabel, setButtonLabel] = useState<string>('')

  /**
   * Updates the Password field with the typed value.
   * @param newPassword The new typed value for the Password.
   */
  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword)
  }

  /**
   * Updates the Confirm Password field with the typed value.
   * @param newConfirmPassword The new typed value for the Confirm Password.
   */
  const handleConfirmPasswordChange = (newConfirmPassword: string) => {
    setConfirmPassword(newConfirmPassword)
  }

  /**
   * Evaluate both passwords when the user blurs from the "Confirm Password" field.
   * @param password The provided password.
   * @param confirmPassword The value that need to match with the password to make sure the user provided the desired password.
   */
  const handleComparePasswords = (password: string, confirmPassword: string) => (): void => {
    // Verify if both passwords match.
    const passwordMismatch: boolean = password !== confirmPassword

    // Update the matching validation.
    setIsMismatchError(passwordMismatch)
  }

  const handleSubmitPassword = () => {
    // Avoid the submission if passwords mismatch.
    if (isMismatchError) {
      return
    }

    // Set the API params for resetting the password.
    const apiParams: PasswordResetParams = {
      password,
    }

    // Trigger the API for resetting the user's password.
    dispatchUpdatePassword(username, apiParams)
  }

  /**
   * Sends a Forgot Password email since the validation code has expired and the user requested to re-send it.
   */
  const handleSendForgotPasswordEmail = () => {
    // Avoid sending the email if no "username" or "email" have been set.
    if (!username || !email) {
      return
    }

    // Set the params to be sent throug the Password Reset URL.
    const emailValidationParams = {
      username,
      email,
      recipientName,
      timestamp: moment(),
    }

    // Encode the link to make it safer.
    const encodedLink = encodeContentUtf8(JSON.stringify(emailValidationParams))

    // Set the URL for the Password Reset.
    const emailValidationUrl = `${APP_URL}/${PASSWORD_RESET_PATH[language]}/${encodedLink}`

    const subject = forgotPasswordDictionary.emailJSPasswordResetRequestSubject
    const greeting = recipientName
      ? `${sharedDictionary.hello} ${recipientName}`
      : sharedDictionary.dearUser
    const body = forgotPasswordDictionary.emailJSPasswordResetRequestBody

    // Update the emailJS params for the email validation.
    setValue('subject', subject)
    setValue('to_email', email)
    setValue('greeting', greeting)
    setValue('email_body', body)
    setValue('button_label', sharedDictionary.resetPassword)
    setValue('email_validation_url', emailValidationUrl)

    // Send the Password Reset email to the user.
    emailjs.sendForm(EMAIL_JS_SERVICE_ID, EMAIL_JS_GENERAL_TEMPLATE_ID, formRef.current, EMAIL_JS_PUBLIC_KEY)
      .then((_result) => {
        // Display a success message.
        const alertProps: ShowAlertParams = {
          type: NotificationType.success,
          title: sharedDictionary.congratulations,
          message: forgotPasswordDictionary.emailJSPasswordResetRequestSuccess,
          buttonLabel: sharedDictionary.ok,
        }
    
        // Trigger the "showAlert" action for displaying the Alert modal.
        dispatchShowAlert(alertProps)
      }, (_error) => {
        // Display an error message.
        const alertProps: ShowAlertParams = {
          type: NotificationType.error,
          title: errorDictionary.somethingWentWrong,
          message: forgotPasswordDictionary.emailJSPasswordResetRequestError,
          buttonLabel: sharedDictionary.ok,
        }
    
        // Trigger the "showAlert" action for displaying the Alert modal.
        dispatchShowAlert(alertProps)
      })
  }

  /**
   * Sends an email to the user letting him/her know the password reset was successful.
   */
  const handleSendSuccessEmail = () => {
    // Set the params to be sent throug the Email Validation URL.
    const emailValidationParams = { email }

    // Encode the link to make it safer.
    const encodedLink = encodeContentUtf8(JSON.stringify(emailValidationParams))

    // Set the URL for the Email Validation.
    const loginUrl = `${APP_URL}/${LOGIN_PATH[language]}/${encodedLink}`

    const subject = passwordResetDictionary.emailJSPasswordResetSubmitSubject
    const body = passwordResetDictionary.emailJSPasswordResetSubmitBody

    // Update the emailJS params for the email validation.
    setValue('subject', subject)
    setValue('to_email', email)
    setValue('greeting', `${sharedDictionary.hello} ${recipientName}`)
    setValue('email_body', body)
    setValue('button_label', signInDictionary.signIn)
    setValue('email_validation_url', loginUrl)

    // Send the Password Reset email to the user.
    emailjs.sendForm(EMAIL_JS_SERVICE_ID, EMAIL_JS_GENERAL_TEMPLATE_ID, formRef.current, EMAIL_JS_PUBLIC_KEY)
      .then((_result) => {
        // Reset the password fields.
        setPassword('')
        setConfirmPassword('')

        // Set the page content with its button label.
        setContent(passwordResetDictionary.congratulations)
        setButtonLabel(passwordResetDictionary.signInNow)

        // Set the Validation Code Status.
        setValidationCodeStatus(ValidationCodeStatus.unset)

        // Display a success message.
        const alertProps: ShowAlertParams = {
          type: NotificationType.success,
          title: sharedDictionary.congratulations,
          message: passwordResetDictionary.emailJSPasswordResetSubmitSuccess,
          buttonLabel: sharedDictionary.ok,
        }

        // Trigger the "showAlert" action for displaying the Alert modal.
        dispatchShowAlert(alertProps)

        // Reset the verification for User's Password Reset.
        dispatchResetUpdatePasswordAction()

        // Enabled the flag that let the system know we have sent a success message.
        setIsSuccessEmailSent(true)
      }, (_error) => {
        // Display an error message.
        const alertProps: ShowAlertParams = {
          type: NotificationType.error,
          title: errorDictionary.somethingWentWrong,
          message: passwordResetDictionary.emailJSPasswordResetSubmitError,
          buttonLabel: sharedDictionary.ok,
        }

        // Trigger the "showAlert" action for displaying the Alert modal.
        dispatchShowAlert(alertProps)
      })
  }

  /**
   * Redirects the user to the Sign-in page.
   */
  const handleRedirectToHomeClick = (): void => {
    navigate(GUEST_MENU_MAP(language).home)
  }

  /**
   * Defines the action to trigger once the Primary button has been clicked.
   * @param codeStatus The Status for the Validation Code: "unset", "invalid", "expired", "valid".
   */
  const handleButtonClick = (codeStatus: ValidationCodeStatus) => (): void => {
    if (isSuccessEmailSent) {
      // Reset the confirmation for the Successful Email Sent.
      setIsSuccessEmailSent(false)
    } else if (codeStatus === ValidationCodeStatus.expired) {
      // Trigger the event for re-sending the forgot password email.
      handleSendForgotPasswordEmail()
    } else if (!isSuccessEmailSent && [ValidationCodeStatus.unset, ValidationCodeStatus.invalid].includes(codeStatus)) {
      handleRedirectToHomeClick
    }
  }

  useEffect(() => {
    // Define the different error messages to be used.
    const unexistenCodeMessage = `${passwordResetDictionary.notification} ${errorDictionary.unexistentValidationCode}`
    const invalidCodeMessage = `${passwordResetDictionary.notification} ${errorDictionary.invalidValidationCode}`
    const expiredCodeMessage = `${passwordResetDictionary.notification} ${errorDictionary.expiredValidationCode}`

    // Set an error message when there is no encoded link.
    if (!encodedLink) {
      setContent(unexistenCodeMessage)
      setButtonLabel('')
    } else {
      try {
        // Decode the Validation Code.
        const decodedLink = JSON.parse(decodeContentUtf8(encodedLink))

        // Set an error message if any of the expected params is missing.
        if (!decodedLink.username || !decodedLink.email || !decodedLink.recipientName || !decodedLink.timestamp) {
          setContent(invalidCodeMessage)
          setButtonLabel(sharedDictionary.goToHomePage)
        } else {
          // Get the Validation Code props.
          const { username: usernameProp, email: emailProp, recipientName: recipientProp, timestamp: timestampProp } = decodedLink

          // Store the User's Username, Email and Name into the state to be used for sending the email.
          setUsername(usernameProp)
          setEmail(emailProp)
          setRecipientName(recipientProp)

          // Format the timestamp prop and the current date.
          const formattedLinkTimestamp = moment(timestampProp).format(DATETIME_FORMATS.fullDatetime)
          const formattedCurrentTimestamp = moment().format(DATETIME_FORMATS.fullDatetime)

          // Verify if the validation code has expired or not.
          const isLinkExpired = linkExpiration(formattedLinkTimestamp, formattedCurrentTimestamp, TimeMeasurement.days, 1)

          if (isLinkExpired) {
            // Set an error message when the link has expired.
            setContent(expiredCodeMessage)
            setButtonLabel(passwordResetDictionary.resendPasswordResetButton)

            // Set the Validation Code Status.
            setValidationCodeStatus(ValidationCodeStatus.expired)
          } else {
            // Set an initial content when the code is valid.
            setContent(passwordResetDictionary.content)

            // Set the Validation Code Status.
            setValidationCodeStatus(ValidationCodeStatus.valid)
          }
        }
      } catch(error) {
        // Set the initial content with the corresponding error.
        setContent(invalidCodeMessage)
        setButtonLabel('')

        // Set the Validation Code Status.
        setValidationCodeStatus(ValidationCodeStatus.invalid)
      }
    }
  }, [])

  useEffect(() => {
    if (isPasswordResetSuccessful && !isPasswordResetLoading) {
      // Send an email letting the user know the change was successful.
      handleSendSuccessEmail()
    }
  }, [isPasswordResetLoading, isPasswordResetSuccessful])

  return (
    <PageContainer>
      <Typography variant="body2">{content}</Typography>
      {validationCodeStatus === ValidationCodeStatus.valid && (
        <Card className="login-card" variant="outlined">
          <PasswordInput
            id="password"
            label={signUpDictionary.fieldPassword}
            placeholder={signUpDictionary.placeholderPassword}
            value={password}
            adornmentBgColor={ADORNMENT_BG_COLOR}
            fullWidth
            generatePassword
            onChange={handlePasswordChange}
          />
          <PasswordInput
            id="confirmPassword"
            label={signUpDictionary.fieldConfirmPassword}
            placeholder={signUpDictionary.placeholderConfirmPassword}
            value={confirmPassword}
            adornmentBgColor={ADORNMENT_BG_COLOR}
            fullWidth
            isMismatchError={isMismatchError}
            onChange={handleConfirmPasswordChange}
            onBlur={handleComparePasswords(password, confirmPassword)}
          />
          <Row marginTop={pxToRem(16)} justifyContent={JustifyContent.center}>
            <DynamicRow
              width={{ md: pxToRem(272) }}
              alignItems={AlignItems.baseline}
              justifyContent={JustifyContent.center}
              groupedByColumns={{ xs: '1', md: '2' }}
              gridRowGap={{ xs: pxToRem(8) }}
              gridColumnGap={{ xs: '0', md: pxToRem(8) }}
            >
              <SecondaryButton
                label={sharedDictionary.cancel}
                loading={isPasswordResetLoading}
                onClick={handleRedirectToHomeClick}
              />
              <PrimaryButton
                label={sharedDictionary.submit}
                loading={isPasswordResetLoading}
                onClick={handleSubmitPassword}
              />
            </DynamicRow>
          </Row>
        </Card>
      )}
      {validationCodeStatus !== ValidationCodeStatus.valid && (
        <Row marginTop={pxToRem(16)} justifyContent={JustifyContent.center}>
          <DynamicRow
            width={{ xs: pxToRem(200), md: pxToRem(496) }}
            alignItems={AlignItems.baseline}
            justifyContent={JustifyContent.center}
            groupedByColumns={{ xs: '1', md: '2' }}
            gridRowGap={{ xs: pxToRem(8) }}
            gridColumnGap={{ xs: '0', md: pxToRem(8) }}
          >
            <SecondaryButton
              label={sharedDictionary.goToHomePage}
              onClick={handleRedirectToHomeClick}
            />
            {!!buttonLabel && (
              <PrimaryButton
                label={buttonLabel}
                onClick={handleButtonClick(validationCodeStatus)}
              />
            )}
          </DynamicRow>
        </Row>
      )}
      <form ref={formRef} style={{ display: 'none' }}>
        <input value="" {...register('subject')} />
        <input value="" {...register('to_email')} />
        <input value="" {...register('greeting')} />
        <input value="" {...register('email_body')} />
        <input value="" {...register('button_label')} />
        <input value={sharedDictionary.emailJSClosing} {...register('email_closing')} />
        <input value={sharedDictionary.emailJSSignature} {...register('email_signature')} />
        <input value="" {...register('email_validation_url')} />
        <input value="" {...register('reply_to')} />
        <input type="submit" />
      </form>
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, sessionStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { isPasswordResetLoading, isPasswordResetSuccessful } = sessionStore

  return {
    dictionary,
    language,
    isPasswordResetLoading,
    isPasswordResetSuccessful,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchResetUpdatePasswordAction: () => dispatch(resetUpdatePasswordAction()),
  dispatchShowAlert: (alertProps: ShowAlertParams) => dispatch(showAlert(alertProps)),
  dispatchUpdatePassword: (username: string, jsonParams: PasswordResetParams) => dispatch(updatePassword(username, jsonParams)),
})

const PasswordReset: any = AccessControl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPasswordReset))

export default PasswordReset
