import { CloseOutlined as CloseIcon } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { ResponsiveStyleValue } from '@mui/system'
import { PrimaryButton } from 'components/FormFields'
import { LabelWithoutMargin } from 'components/styledComponents'
import { useGetDialogIcon } from 'hooks'
import React, { FC, forwardRef, ReactElement, Ref } from 'react'
import { connect } from 'react-redux'
import { AlertState } from 'redux/utils/alerts.types'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'

const Transition = forwardRef((props: TransitionProps & { children: ReactElement<any, any>}, ref: Ref<unknown>) =>
  <Slide direction="down" ref={ref} {...props} />
)

type Props = AlertState & {
  dictionary: LanguageDictionary
  width?: string | ResponsiveStyleValue<string>
  closeable?: boolean
  useTransition?: boolean
  onClose: () => void
}

const UnconnectedAlertDialog: FC<Props> = ({
  dictionary: { shared: sharedDictionary },
  isOpen,
  title,
  type,
  closeable,
  useTransition,
  width,
  message,
  buttonLabel = sharedDictionary.ok,
  onClose,
}) => {
  const dialogStyles = {
    '& .MuiDialog-paperScrollPaper': {
      width: width || 'auto',
    },
  }

  const iconType = useGetDialogIcon(type)

  const handleOnClose = (_event, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }

    onClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={useTransition ? Transition : undefined}
        keepMounted
        sx={dialogStyles}
        onClose={handleOnClose}
      >
        <DialogTitle className={`${type} ${closeable ? 'closable' : ''}`}>
          {iconType && (
            <Icon color="inherit" component={iconType} fontSize="small" />
          )}
          {title}
          {closeable && (
            <Icon color="inherit" component={CloseIcon} fontSize="small" onClick={onClose} />
          )}
        </DialogTitle>
        <DialogContent>
          <LabelWithoutMargin>
            {message}
          </LabelWithoutMargin>
        </DialogContent>
          <DialogActions>
            <PrimaryButton
              label={buttonLabel}
              onClick={onClose}
            />
          </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

const AlertDialog = connect(
  mapStateToProps,
)(UnconnectedAlertDialog)

export default AlertDialog