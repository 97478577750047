import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import PageContainer from 'pages/components/PageContainer'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'

type Props = {
  dictionary: LanguageDictionary
}

const UnconnectedFAQ: FC<Props> = ({ dictionary }) => {
  const [questions, setQuestions] = useState<string[]>([])
  const [answers, setAnswers] = useState<string[]>([])
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    const newQuestions = Object.entries(dictionary.faq)
      .filter(item => item[0].includes('question'))
      .map(item => item[1])

    const newAnswers = Object.entries(dictionary.faq)
      .filter(item => item[0].includes('answer'))
      .map(item => item[1])

    setQuestions(newQuestions)
    setAnswers(newAnswers)
  }, [dictionary])

  return (
    <PageContainer>
      <Typography variant="body2">{dictionary.faq.description}</Typography>
      {questions.map((question, index) => (
        <Accordion
          key={`accordion-${index}`}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            id={`panel${index}-header`}
            aria-controls={`panel${index}-content`}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="body1">
              {question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {answers[index]}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )) }
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

const FAQ: any = connect(
  mapStateToProps,
)(UnconnectedFAQ)

export default FAQ
