import AccessControl from 'helpers/AccessControl'
import ComingSoon from 'pages/ComingSoon'
import PageContainer from 'pages/components/PageContainer'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'

type Props = {
  dictionary: LanguageDictionary
}

const Profile: FC<Props> = ({
  dictionary: { publishMachine: publishMachineDictionary },
}) => (
  <PageContainer ogDescription={publishMachineDictionary.pageDescription}>
    <ComingSoon />
  </PageContainer>
)

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export default AccessControl(connect(
  mapStateToProps,
  // mapDispatchToProps,
)(Profile))