import { Action } from 'redux/utils/enums'
import { ShowConfirmParams } from 'redux/utils/confirm.types'

export const showConfirm = (confirmProps: ShowConfirmParams) => ({
  type: Action.CONFIRM_SHOW,
  payload: confirmProps,
})

export const hideConfirm = () => (dispatch, getState) => {
  const { confirmStore: { isOpen } } = getState()

  if (isOpen) {
    dispatch({
      type: Action.CONFIRM_HIDE,
    })
  }
}
