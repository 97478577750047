import { MACHINERY_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { MachineryAction, MachineryState } from 'redux/utils/machinery.types'

const machinery = (state: MachineryState = MACHINERY_INITIAL_STATE, action: MachineryAction): MachineryState => {
  switch (action.type) {
    case Action.CATEGORIES_READ:
      return {
        ...state,
        categories: action.payload,
      }
    case Action.CATEGORIES_LOADING:
      return {
        ...state,
        categoriesLoading: action.payload,
      }
    case Action.CATEGORIES_MAP_UPDATE:
      return {
        ...state,
        categoriesMap: action.payload,
      }
    case Action.MANUFACTURERS_READ:
      return {
        ...state,
        manufacturers: action.payload,
      }
    case Action.MANUFACTURERS_LOADING:
      return {
        ...state,
        manufacturersLoading: action.payload,
      }
    case Action.MANUFACTURERS_MAP_UPDATE:
      return {
        ...state,
        manufacturersMap: action.payload,
      }
    case Action.MODELS_READ:
      return {
        ...state,
        models: action.payload,
      }
    case Action.MODELS_LOADING:
      return {
        ...state,
        modelsLoading: action.payload,
      }
    case Action.MODELS_MAP_UPDATE:
      return {
        ...state,
        modelsMap: action.payload,
      }
    case Action.MACHINES_READ:
      return {
        ...state,
        machines: action.payload,
      }
    case Action.MACHINES_LOADING:
      return {
        ...state,
        machinesLoading: action.payload,
      }
    case Action.MACHINES_ERROR:
      return {
        ...state,
        machinesError: action.payload,
      }
    case Action.MACHINE_DETAILS_READ:
      return {
        ...state,
        machineDetails: action.payload,
      }
    case Action.MACHINE_DETAILS_LOADING:
      return {
        ...state,
        machineDetailsLoading: action.payload,
      }
    case Action.MACHINES_CAROUSEL_READ:
      return {
        ...state,
        machinesCarousel: action.payload,
      }
    case Action.MACHINES_CAROUSEL_LOADING:
      return {
        ...state,
        machinesCarouselLoading: action.payload,
      }
    default:
      return state
  }
}

export default machinery