import { Close as CloseIcon } from '@mui/icons-material'
import { Alert, IconButton } from '@mui/material'
import { NotificationContainer } from 'components/FormFields/styledComponents'
import { NotificationType } from 'components/utils/enums'
import React, { FC, useEffect } from 'react'

type Props = {
  isOpen: boolean
  type: NotificationType
  message: string
  duration?: number
  onHide: () => void
}

export const NotificationAlert: FC<Props> = ({
  isOpen,
  type,
  message,
  duration = 0,
  onHide,
}) => {
  const alertClass = isOpen ? 'show' : ''

  useEffect(() => {
    const timer = setTimeout(() => {
      onHide()
    }, duration)

    return () => clearTimeout(timer)
  }, [])

  return (
    <NotificationContainer>
      <Alert
        severity={type}
        className={`notification ${alertClass}`}
        action={
          <>
            {!duration && (
              <IconButton
                color="inherit"
                size="small"
                onClick={onHide}
              >
                <CloseIcon />
              </IconButton>
            )}
          </>
        }
      >
        {message}
      </Alert>
    </NotificationContainer>
  )
}
