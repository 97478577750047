import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { FC } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

type Props = {
  auto?: boolean
  index: number
  children: JSX.Element | JSX.Element[]
  onChangeIndex: (newStep: number) => void
}

const ViewsContainer: FC<Props> = ({ auto, index, children, onChangeIndex }) => {
  const { palette } = useTheme()

  return (
    <Box
      sx={{
        borderLeft: `1px solid ${palette.app.border}`,
        borderRight: `1px solid ${palette.app.border}`,
      }}
    >
      {auto && (
        <AutoPlaySwipeableViews
          axis="x"
          index={index}
          enableMouseEvents
          onChangeIndex={onChangeIndex}
        >
          {children}
        </AutoPlaySwipeableViews>
      )}
      {!auto && (
        <SwipeableViews
          axis="x"
          index={index}
          enableMouseEvents
          onChangeIndex={onChangeIndex}
        >
          {children}
        </SwipeableViews>
      )}
    </Box>
  )
}

export default ViewsContainer