import FooterGuests from 'components/Navigation/Footer/Guests'
import FooterUsers from 'components/Navigation/Footer/Users'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { ReduxStore } from 'redux/utils/types'

type Props = {
  isSignedIn: boolean
}

const Footer: FC<Props> = ({ isSignedIn }) => {
  if (isSignedIn) {
    return <FooterUsers />
  }

  return <FooterGuests />
}

const mapStateToProps = ({ sessionStore }: ReduxStore) => {
  const { isSignedIn } = sessionStore

  return {
    isSignedIn,
  }
}

export default connect(
  mapStateToProps
)(Footer)
