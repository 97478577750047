import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_SERVER_URL,
  headers: {
    authorization: '',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const gapi = axios.create({
  baseURL: 'https://www.googleapis.com/oauth2/v1/',
  headers: {
    authorization: '',
  },
})
