import { pxToRem } from 'theme/typography'

export default function ListItem() {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          '&.bullet-list': {
            listStyle: 'disc',
            '& .MuiListItem-root': {
              marginLeft: pxToRem(24),
              display: 'list-item',
            },
          },
        },
      },
    }
  }
}
