import emailjs from '@emailjs/browser'
import { Typography } from '@mui/material'
import { PrimaryButton, StringInput } from 'components/FormFields'
import { Row } from 'components/styledComponents'
import { JustifyContent, NotificationType } from 'components/utils/enums'
import moment from 'moment'
import PageContainer from 'pages/components/PageContainer'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { showAlert } from 'redux/actions/alert'
import { resetUsernameValidation, validateUsername } from 'redux/actions/users'
import { ShowAlertParams } from 'redux/utils/alerts.types'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { APP_URL, EMAIL_JS_GENERAL_TEMPLATE_ID, EMAIL_JS_PUBLIC_KEY, EMAIL_JS_SERVICE_ID, PASSWORD_RESET_PATH } from 'utils/constants'
import { encodeContentUtf8 } from 'utils/libs'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  email?: string
  name?: string
  dispatchResetUsernameValidation: () => void
  dispatchShowAlert: (alertProps: ShowAlertParams) => void
  dispatchValidateUsername: (username: string) => void
}

const UnconnectedForgotPassword: FC<Props> = ({
  dictionary: {
    error: errorDictionary,
    forgotPassword: forgotPasswordDictionary,
    shared: sharedDictionary,
    signUp: signUpDictionary,
  },
  language,
  email,
  name,
  dispatchResetUsernameValidation,
  dispatchShowAlert,
  dispatchValidateUsername,
}) => {
  const { register, setValue } = useForm()
  const formRef = useRef() as MutableRefObject<HTMLFormElement>
  const [username, setUsername] = useState<string>('')

  const handleUsernameChange = (newValue: string): void => {
    setUsername(newValue)
  }

  const handleSubmitClick = (): void => {
    dispatchValidateUsername(username)
  }

  useEffect(() => {
    if (!!email && !!name) {
      // Set the params to be sent throug the Password Reset URL.
      const emailValidationParams = {
        username,
        email,
        recipientName: name,
        timestamp: moment(),
      }

      // Encode the link to make it safer.
      const encodedLink = encodeContentUtf8(JSON.stringify(emailValidationParams))

      // Set the URL for the Password Reset.
      const emailValidationUrl = `${APP_URL}/${PASSWORD_RESET_PATH[language]}/${encodedLink}`

      const subject = forgotPasswordDictionary.emailJSPasswordResetRequestSubject
      const greeting = name
        ? `${sharedDictionary.hello} ${name}`
        : sharedDictionary.dearUser
      const body = forgotPasswordDictionary.emailJSPasswordResetRequestBody

      // Update the emailJS params for the email validation.
      setValue('subject', subject)
      setValue('to_email', email)
      setValue('greeting', greeting)
      setValue('email_body', body)
      setValue('button_label', sharedDictionary.resetPassword)
      setValue('email_validation_url', emailValidationUrl)

      // Send the Password Reset email to the user.
      emailjs.sendForm(EMAIL_JS_SERVICE_ID, EMAIL_JS_GENERAL_TEMPLATE_ID, formRef.current, EMAIL_JS_PUBLIC_KEY)
      .then((_result) => {
        // Display a success message.
        const alertProps: ShowAlertParams = {
          type: NotificationType.success,
          title: sharedDictionary.congratulations,
          message: forgotPasswordDictionary.emailJSPasswordResetRequestSuccess,
          buttonLabel: sharedDictionary.ok,
        }
    
        // Trigger the "showAlert" action for displaying the Alert modal.
        dispatchShowAlert(alertProps)

        // Reset the username field.
        setUsername('')

        // Reset the Username Validation.
        dispatchResetUsernameValidation()
      }, (_error) => {
        // Display an error message.
        const alertProps: ShowAlertParams = {
          type: NotificationType.error,
          title: errorDictionary.somethingWentWrong,
          message: forgotPasswordDictionary.emailJSPasswordResetRequestError,
          buttonLabel: sharedDictionary.ok,
        }
    
        // Trigger the "showAlert" action for displaying the Alert modal.
        dispatchShowAlert(alertProps)
      })
    }
  }, [email, name])

  return (
    <PageContainer ogDescription={forgotPasswordDictionary.pageDescription}>
      <Typography variant="body2">
        {forgotPasswordDictionary.content}
      </Typography>
      <br />
      <StringInput
        id="username"
        label={signUpDictionary.fieldUsername}
        value={username}
        fullWidth
        onChange={handleUsernameChange}
      />
      <Row marginTop={pxToRem(16)} justifyContent={JustifyContent.center}>
        <PrimaryButton
          label={sharedDictionary.submit}
          onClick={handleSubmitClick}
        />
      </Row>
      <form ref={formRef} style={{ display: 'none' }}>
        <input value="" {...register('subject')} />
        <input value="" {...register('to_email')} />
        <input value="" {...register('greeting')} />
        <input value="" {...register('email_body')} />
        <input value="" {...register('button_label')} />
        <input value={sharedDictionary.emailJSClosing} {...register('email_closing')} />
        <input value={sharedDictionary.emailJSSignature} {...register('email_signature')} />
        <input value="" {...register('email_validation_url')} />
        <input value="" {...register('reply_to')} />
        <input type="submit" />
      </form>
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, usersStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { email, name } = usersStore

  return {
    dictionary,
    language,
    email,
    name,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchResetUsernameValidation: () => dispatch(resetUsernameValidation()),
  dispatchShowAlert: (alertProps: ShowAlertParams) => dispatch(showAlert(alertProps)),
  dispatchValidateUsername: (username: string) => dispatch(validateUsername(username)),
})

const ForgotPassword: any = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedForgotPassword)

export default ForgotPassword
