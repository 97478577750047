import { Card, CardContent, Typography } from '@mui/material'
import PageContainer from 'pages/components/PageContainer'
import React, { FC } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  dictionary: LanguageDictionary
}

const AboutUs: FC<Props> = ({ dictionary: { aboutUs: aboutUsDictionary } }) => (
  <PageContainer ogDescription={aboutUsDictionary.pageDescription}>
    <Card variant="outlined" sx={{ mb: pxToRem(16) }}>
      <CardContent>
        <Typography variant="h3">
          {aboutUsDictionary.missionTitle}
        </Typography>
        <Typography variant="caption">
          {ReactHtmlParser(aboutUsDictionary.missionContent)}
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h3">
          {aboutUsDictionary.advantagesTitle}
        </Typography>
        <Typography variant="caption">
          {ReactHtmlParser(aboutUsDictionary.advantagesContent)}
        </Typography>
      </CardContent>
    </Card>
  </PageContainer>
)

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export default connect(
  mapStateToProps,
)(AboutUs)

// export default AboutUs
