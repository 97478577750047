import { useWindowDimensions } from 'hooks'
import { getCurrentDevice } from 'pages/Machinery/utils/libs'

export const useGetTableBoundaries = () => {
  const { windowWidth } = useWindowDimensions()
  const { isSmartphone, isSmartphoneXs, isDesktop, isTablet } = getCurrentDevice()

  let siblingCount: number = -1
  let boundaryCount: number = 1

  if (isSmartphoneXs && windowWidth >= 320) {
    siblingCount = 0
  } else if (isSmartphone && !isSmartphoneXs) {
    siblingCount = 1
  } else if (isTablet) {
    siblingCount = 3
    boundaryCount = 2
  } else if (isDesktop) {
    siblingCount = 5
    boundaryCount = 3
  }

  return {
    siblingCount,
    boundaryCount,
  }
}