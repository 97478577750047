import { Theme } from '@mui/material/styles'

export default function CircularProgress(theme: Theme) {
  const { palette } = theme

  return {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          '&.action-button': {
            color: palette.common.white,
          },
        },
      },
    },
  }
}