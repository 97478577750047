import { AlertActions, AlertState } from 'redux/utils/alerts.types'
import { ALERT_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'

export const alert = (state: AlertState = ALERT_INITIAL_STATE, action: AlertActions): AlertState => {
  switch (action.type) {
    case Action.ALERT_SHOW:
      return {
        ...action.payload,
        isOpen: true,
      }
    case Action.ALERT_HIDE:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state
  }
}