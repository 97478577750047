import {
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import { StyledFormControl } from 'components/FormFields/styledComponents'
import { InputSize, InputVariant } from 'components/utils/enums'
import { DropdownStructure } from 'components/utils/types'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  dictionary: LanguageDictionary
  id: string
  label?: string
  value: string
  options: DropdownStructure[]
  nonValueLabel?: string
  size?: InputSize
  width?: string | ResponsiveStyleValue<string>
  fullWidth?: boolean
  required?: boolean
  isMissing?: boolean
  disabled?: boolean
  variant?: InputVariant
  marginTop?: string | number
  marginBottom?: string | number
  onBlur?: (hasErrors?: boolean) => void
  onChange: (newValue: string) => void
}

export const UnconnectedDropdownSingle: FC<Props> = ({
  dictionary: { error: errorDictionary },
  id,
  label,
  value,
  options,
  nonValueLabel,
  size = InputSize.small,
  width,
  fullWidth,
  required,
  isMissing,
  disabled,
  variant = InputVariant.outlined,
  marginTop = pxToRem(8),
  marginBottom = pxToRem(16),
  onBlur,
  onChange,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isTouched, setIsTouched] = useState<boolean>(false)

  // Initialize the "className" for the "Label" and "Select" components.
  const labelClassNameGroup: string[] = required ? ['required'] : []
  const selectClassName: string | undefined = required ? 'required' : undefined

  if (disabled) {
    labelClassNameGroup.push('Mui-disabled')
  }

  if (errorMessage) {
    labelClassNameGroup.push('Mui-error')
  }

  const labelClassName = labelClassNameGroup.join(' ')

  /**
   * Handles the event when the input value has changed.
   * @param event The input event handler which contains the selected value.
   */
  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value: newValue } = event.target

    onChange(newValue)
  }

  /**
   * Handles the event when the input loses focus.
   */
   const handleBlur = (): void => {
    // Set the input has been touched, which means the input has been focused at least once.
    setIsTouched(true)

    // Check wheter the input value has an error due it's required.
    const isRequiredError = required && (!value || value === '-1')

    // Keep the normal Blur behavior when there is no error.
    if (!isRequiredError) {
      onBlur?.()
      return
    }

    // Define the error message
    setErrorMessage(errorDictionary.requiredValue)
    onBlur?.(true)
  }

  useEffect(() => {
    // Set an error message when the field is required but it is missing.
    const isRequiredError = required && (!value || value === '-1') && (isTouched || isMissing)
    const newErrorMessage = isRequiredError ? errorDictionary.requiredValue : ''
    setErrorMessage(newErrorMessage)

    // Set the field as touched when it is required and missing and the "isTouched" flag is off.
    if (required && isMissing && !isTouched) {
      setIsTouched(true)
    }
  }, [value, isMissing])

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      width={width}
      marginTop={marginTop}
      marginBottom={marginBottom}
      variant={variant}
      size={size}
      error={!!errorMessage}
    >
      {!!label && (
        <InputLabel id={`${id}-label`} className={labelClassName}>
          {label}
        </InputLabel>
      )}
      <Select
        className={selectClassName}
        id={id}
        label={label}
        value={value}
        size={size}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {!!nonValueLabel && (
          <MenuItem value="-1">
            {nonValueLabel}
          </MenuItem>
        )}
        {options.map(option => {
          if (option.isGroup) {
            return (
              <ListSubheader key={option.label} sx={{ position: 'unset' }}>
                <Typography>{option.label}</Typography>
                <Typography className='additional-value'>{option.groupLabelRightSide}</Typography>
              </ListSubheader>
            )
          }

          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
      {!!errorMessage && (
        <Typography className="MuiSelectHelperText-root Mui-error">
          {errorMessage}
        </Typography>
      )}
    </StyledFormControl>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const DropdownSingle = connect(
  mapStateToProps,
)(UnconnectedDropdownSingle)
