import { Icon, IconTypeMap, Tooltip } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { ClickableBox } from 'components/styledComponents'
import { ClickableBoxProps } from 'components/utils/types'
import React, { FC } from 'react'

type Props = ClickableBoxProps & {
  component: OverridableComponent<IconTypeMap> & { muiName: string }
  tooltipTitle?: string
  onClick: () => void
}

export const ClickableIcon: FC<Props> = ({
  component,
  tooltipTitle,
  onClick,
  ...props
}) => (
  <Tooltip title={tooltipTitle} placement="top">
    <ClickableBox {...props} onClick={onClick}>
      <Icon
        color="inherit"
        component={component}
        fontSize="small"
      />
    </ClickableBox>
  </Tooltip>
)