import { TIMEZONE_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { TimezoneActions, TimezoneState } from 'redux/utils/timezone.types'

export const timezone = (state: TimezoneState = TIMEZONE_INITIAL_STATE, action: TimezoneActions): TimezoneState => {
  switch (action.type) {
    case Action.TIMEZONE_UPDATE:
      return {
        timezone: action.payload,
      }
    default:
      return state
  }
}

export default timezone