import { PaletteColor, SimplePaletteColorOptions, TypeAction } from '@mui/material'
import { alpha, CommonColors } from '@mui/material/styles'
import {
  CustomColor,
  PaletteAppColors,
  PaletteGoogleColors,
  PaletteInstagramColors,
  PaletteSocialMediaColors,
} from 'theme/utils/types'

export const THEME_COLOR_COMMON: CommonColors = {
  black: '#000000',
  white: '#ffffff',
}

export const THEME_COLOR_GREY: CustomColor = {
  100: '#f9fafb',
  200: '#f4f4f5',
  300: '#dfe3e8',
  400: '#c4cdd5',
  500: '#919eab',
  600: '#637381',
  700: '#454f5b',
  800: '#212b36',
  900: '#161c24',
  500_8: alpha('#919eab', 0.08),
  500_12: alpha('#919eab', 0.12),
  500_16: alpha('#919eab', 0.16),
  500_24: alpha('#919eab', 0.24),
  500_32: alpha('#919eab', 0.32),
  500_40: alpha('#919eab', 0.40),
  500_48: alpha('#919eab', 0.48),
  500_56: alpha('#919eab', 0.56),
  500_80: alpha('#919eab', 0.80),
}

export const THEME_COLOR_BLUE: SimplePaletteColorOptions = {
  light: '#4285f4',
  main: '#0000ff',
  dark: '#0000cc',
}

export const THEME_COLOR_ORANGE: SimplePaletteColorOptions = {
  light: '#ffd166',
  main: '#fcba03',
  dark: '#e09e00',
}

export const THEME_COLOR_YELLOW = '#fceb2a'

const THEME_COLOR_GOOGLE: PaletteGoogleColors = {
  blue: '#4285F4',
  green: '#0f9d58',
  red: '#db4437',
  yellow: '#f4b400'
}

const THEME_COLOR_INSTAGRAM: PaletteInstagramColors = {
  blue: '#5851d8',
  blueRoyal: '#050ae6',
  orange: '#f77737',
  orangeDark: '#F56040',
  pinkDark: '#c13584',
  purple: '#833ab4',
  purpleRed: '#e1306c',
  red: '#fd244c',
  yellow: '#fcaf45',
  yellowLight: '#ffdc80',
}

export const THEME_COLOR_SOCIAL_MEDIA: PaletteSocialMediaColors = {
  facebook: '#1877F2',
  google: { ...THEME_COLOR_GOOGLE },
  instagram: { ...THEME_COLOR_INSTAGRAM },
  twitter: '#55acee',
  youtube: '#ff0000',
  whatsapp: '#25D366',
}

export const THEME_APP_COLORS: PaletteAppColors = {
  background: THEME_COLOR_COMMON.white,
  badge: '#0ca3b1',
  border: '#cccccc',
  breadcrumbs: {
    background: THEME_COLOR_GREY[300],
    activePath: THEME_COLOR_COMMON.black,
    inactivePath: THEME_COLOR_GREY[600],
  },
  machineThumbnail: {
    text: THEME_COLOR_COMMON.black,
    detailsLink: THEME_COLOR_BLUE.light as string,
    price: {
      main: THEME_COLOR_ORANGE.light as string,
      contrastText: THEME_COLOR_COMMON.white,
    },
    actionButtons: {
      link: {
        main: THEME_COLOR_GREY[500],
        contrastText: THEME_COLOR_COMMON.white,
      },
      contact: {
        main: THEME_COLOR_COMMON.black,
        contrastText: THEME_COLOR_COMMON.white,
      },
      whatsApp: {
        main: THEME_COLOR_SOCIAL_MEDIA.whatsapp,
        contrastText: THEME_COLOR_COMMON.white,
      },
      share: {
        main: THEME_COLOR_BLUE.light as string,
        contrastText: THEME_COLOR_COMMON.white,
      }
    },
  },
  navigation: {
    background: THEME_COLOR_BLUE.dark as string,
    activeLink: THEME_COLOR_COMMON.white,
    inactiveLink: THEME_COLOR_GREY[600],
  },
  pagination: {
    active: {
      main: THEME_COLOR_BLUE.dark as string,
      contrastText: THEME_COLOR_COMMON.white,
    },
    inactive: {
      main: THEME_COLOR_BLUE.light as string,
      contrastText: THEME_COLOR_GREY[500],
    },
    hover: {
      main: THEME_COLOR_BLUE.main,
      contrastText: THEME_COLOR_COMMON.white,
    },
    disabled: {
      main: THEME_COLOR_GREY[700],
      contrastText: THEME_COLOR_GREY[500],
    },
  },
  shadow: alpha(THEME_COLOR_COMMON.black, 0.85),
  table: {
    header: THEME_COLOR_BLUE.main,
    oddRow: THEME_COLOR_COMMON.white,
    evenRow: alpha(THEME_COLOR_ORANGE.light as string, 0.3),
  },
}

export const THEME_COLOR_PRIMARY: PaletteColor = {
  light: alpha(THEME_COLOR_BLUE.light as string, 0.7),
  main: THEME_COLOR_BLUE.light as string,
  dark: THEME_COLOR_BLUE.main as string,
  contrastText: THEME_COLOR_COMMON.white,
}

export const THEME_COLOR_SECONDARY: PaletteColor = {
  light: alpha(THEME_COLOR_ORANGE.light as string, 0.7),
  main: THEME_COLOR_ORANGE.light as string,
  dark: THEME_COLOR_ORANGE.main as string,
  contrastText: THEME_COLOR_COMMON.black,
}

export const THEME_COLOR_INFO: PaletteColor = {
  light: '#74caff',
  main: '#1890ff',
  dark: '#0c53b7',
  contrastText: THEME_COLOR_COMMON.white,
}

export const THEME_COLOR_SUCCESS: PaletteColor = {
  light: '#aaf27f',
  main: '#54d62c',
  dark: '#229a16',
  contrastText: THEME_COLOR_GREY[800],
}

export const THEME_COLOR_WARNING: PaletteColor = {
  light: '#ffe699',
  main: '#f1c21b',
  dark: '#e6ac00',
  contrastText: THEME_COLOR_GREY[800],
}

export const THEME_COLOR_ERROR: PaletteColor = {
  light: '#ffa48d',
  main: '#ff4842',
  dark: '#b72136',
  contrastText: THEME_COLOR_COMMON.white,
}

export const THEME_COLOR_ACTIONS: TypeAction = {
  active: THEME_COLOR_GREY[600],
  activatedOpacity: 0.16,
  disabled: THEME_COLOR_GREY[500_80],
  disabledBackground: THEME_COLOR_GREY[500_24],
  disabledOpacity: 0.48,
  hover: THEME_COLOR_GREY[500_8],
  hoverOpacity: 0.08,
  focus: THEME_COLOR_GREY[500_24],
  focusOpacity: 0.16,
  selected: THEME_COLOR_GREY[500_16],
  selectedOpacity: 0.16,
}

export const THEME_FONT_FAMILY = 'Montserrat, sans-serif'
