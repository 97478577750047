import { dictionaryList, languageLabel } from 'languages'
import { LANGUAGE_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { LanguageAction, LanguageState } from 'redux/utils/language.types'

export const language = (state: LanguageState = LANGUAGE_INITIAL_STATE, action: LanguageAction): LanguageState => {
  switch (action.type) {
    case Action.LANGUAGE_UPDATE:
      return {
        language: action.payload,
        dictionary: dictionaryList[action.payload],
        label: languageLabel[action.payload],
      }
    default:
      return state
  }
}

export default language