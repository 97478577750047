import { Box } from '@mui/material'
import React, { FC } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

type Props = {
  auto?: boolean
  index: number
  children: JSX.Element | JSX.Element[]
  onChangeIndex: (newStep: number) => void
}

const ViewsContainer: FC<Props> = ({ auto, index, children, onChangeIndex }) => (
  <Box>
    {auto && (
      <AutoPlaySwipeableViews
        axis="x"
        index={index}
        enableMouseEvents
        onChangeIndex={onChangeIndex}
      >
        {children}
      </AutoPlaySwipeableViews>
    )}
    {!auto && (
      <SwipeableViews
        axis="x"
        index={index}
        enableMouseEvents
        onChangeIndex={onChangeIndex}
      >
        {children}
      </SwipeableViews>
    )}
  </Box>
)

export default ViewsContainer