import { PlatformType } from 'pages/Machinery/utils/enums'
import { formatValueToMoney } from 'pages/Machinery/utils/libs'
import { GetQuotationResponse, QuotationValues } from 'redux/utils/quotation.types'

export const useCalculateQuotation = (
  origin: string,
  destination: string,
  platformType: PlatformType,
  platformName: string,
  includeInsurance: boolean,
  naLabel: string,
  price?: number,
  quotationResponse?: GetQuotationResponse[],
): QuotationValues | null => {
  if (!quotationResponse || !price) {
    return null
  }

  const [usaQuoation, mexQuotation] = quotationResponse
  const usaShipping: number = usaQuoation.distance * usaQuoation[platformType]
  const usaInsurance: number = includeInsurance
    ? ((price + usaShipping) * 0.0055 + 150)
    : 0
  const mexShipping: number = mexQuotation
    ? mexQuotation.distance * mexQuotation[platformType]
    : 0
  const mexFee: number = mexQuotation
    ? mexQuotation.feeBase + (price * mexQuotation.feeFactor)
    : 0
  const mexHandling: number = mexQuotation
    ? mexFee * mexQuotation[`${platformType}Factor`]
    : 0
  const total: number = Number.parseFloat((price + usaShipping + usaInsurance + mexShipping + mexFee + mexHandling).toFixed(2))

  const formattedUSAShipping: string = formatValueToMoney(usaShipping)
  const formattedUSAInsurance: string = usaInsurance
    ? formatValueToMoney(usaInsurance)
    : naLabel
  const formattedMEXShipping: string = mexShipping
    ? formatValueToMoney(mexShipping)
    : naLabel
  const formattedMEXFee: string = mexFee
    ? formatValueToMoney(mexFee)
    : naLabel
  const formattedMEXHandling: string = mexHandling
    ? formatValueToMoney(mexHandling)
    : naLabel
  const formattedTotal: string = formatValueToMoney(total)

  return {
    origin,
    destination,
    platform: platformName,
    usaShipping: formattedUSAShipping,
    usaInsurance: formattedUSAInsurance,
    mexShipping: formattedMEXShipping,
    mexFee: formattedMEXFee,
    mexHandling: formattedMEXHandling,
    total: formattedTotal,
  }
}