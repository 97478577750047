import { InputAdornment, TextField } from '@mui/material'
import { StyledFormControl } from 'components/FormFields/styledComponents'
import { AdornmentPosition, InputSize, InputType, InputVariant } from 'components/utils/enums'
import { InputProps } from 'components/utils/types'
import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'


type Props = {
  id?: string
  dictionary: LanguageDictionary
  disabled?: boolean
  endAdornment?: ReactNode
  fullWidth?: boolean
  label: string
  marginBottom?: string | number
  marginTop?: string | number
  maxRows?: number
  placeholder?: string
  required?: boolean
  rows?: number
  size?: InputSize
  startAdornment?: ReactNode
  value?: string
  variant?: InputVariant
  onBlur?: (hasErrors?: boolean) => void
  onChange: (newValue: string) => void
  onKeyDown?: (event: any) => void
  onKeyUpCapture?: (event: any) => void
}

const UnconnectedMultiTextBox: FC<Props> = ({
  id,
  dictionary: { error: errorDictionary },
  disabled,
  endAdornment,
  fullWidth,
  label,
  marginBottom = pxToRem(16),
  marginTop = pxToRem(8),
  maxRows = 5,
  placeholder,
  required,
  rows = 5,
  size = InputSize.small,
  startAdornment,
  value,
  variant = InputVariant.outlined,
  onBlur,
  onChange,
  onKeyDown,
  onKeyUpCapture,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const inputProps: InputProps = {}

  if (startAdornment) {
    inputProps.startAdornment = (
      <InputAdornment position={AdornmentPosition.start}>
        {startAdornment}
      </InputAdornment>
    )
  }

  if (endAdornment) {
    inputProps.endAdornment = (
      <InputAdornment position={AdornmentPosition.end}>
        {endAdornment}
      </InputAdornment>
    )
  }


  /**
   * Handles the event when the input value has changed.
   * @param event The input event handler which contains the typed value.
   */
   const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value: newValue } = event.target
    onChange(newValue)
  }

  /**
   * Handles the event when the input loses focus.
   */
  const handleBlur = (): void => {
    // Set the input has been touched, which means the input has been focused at least once.
    setIsTouched(true)

    // Check wheter the input value has an error due it's required.
    const isRequiredError = required && !value

    // Keep the normal Blur behavior when there is no error.
    if (!isRequiredError) {
      onBlur?.()
      return
    }

    // Define the error message
    setErrorMessage(errorDictionary.requiredValue)
    onBlur?.(true)
  }

  useEffect(() => {
    const isRequiredError = required && !value && isTouched

    if (isRequiredError) {
      setErrorMessage(errorDictionary.requiredValue)
    } else {
      setErrorMessage('')
    }
  }, [value])

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      marginTop={marginTop}
      marginBottom={marginBottom}
      variant={variant}
      size={size}
    >
      <TextField
        id={id}
        type={InputType.text}
        placeholder={placeholder}
        label={label}
        value={value}
        size={size}
        rows={rows}
        maxRows={maxRows}
        variant={variant}
        InputProps={inputProps}
        required={required}
        disabled={disabled}
        multiline
        error={!!errorMessage}
        helperText={errorMessage}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        onKeyUpCapture={onKeyUpCapture}
      />
    </StyledFormControl>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const MultiTextBox = connect(
  mapStateToProps,
)(UnconnectedMultiTextBox)