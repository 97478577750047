import { Theme, alpha } from '@mui/material/styles'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function Button(theme: Theme) {
  const { palette } = theme
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          height: pxToRem(24),
          '&.MuiButton-containedPrimary': {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.main,
            borderColor: palette.primary.main,
            '&.Mui-disabled': {
              backgroundColor: alpha(palette.primary.main, 0.5),
              borderColor: alpha(palette.primary.main, 0.5),
            },
            '&:hover': {
              backgroundColor: palette.primary.main,
              borderColor: palette.primary.main,
            },
          },
          '&.MuiButton-outlinedPrimary': {
            color: palette.common.black,
            backgroundColor: 'transparent',
            borderColor: palette.primary.main,
            '&.Mui-disabled': {
              color: alpha(palette.common.black, 0.5),
              borderColor: alpha(palette.primary.main, 0.5),
            },
          },
          '&.MuiButton-textPrimary': {
            color: palette.primary.main,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              color: alpha(palette.primary.main, 0.5),
            },
          },
          '&.MuiButton-containedSecondary': {
            color: palette.secondary.contrastText,
            backgroundColor: palette.secondary.dark,
            borderColor: palette.secondary.dark,
            '&.Mui-disabled': {
              color: alpha(palette.secondary.contrastText, 0.5),
              backgroundColor: alpha(palette.secondary.dark, 0.5),
              borderColor: alpha(palette.secondary.dark, 0.5),
            },
            '&:hover': {
              backgroundColor: palette.secondary.dark,
              borderColor: palette.secondary.dark,
            },
            '& .MuiCircularProgress-root': {
              color: palette.secondary.contrastText,
            },
          },
          '&.MuiButton-outlinedSecondary': {
            color: palette.secondary.contrastText,
            backgroundColor: 'transparent',
            borderColor: palette.secondary.main,
            '&.Mui-disabled': {
              color: alpha(palette.secondary.contrastText, 0.5),
              borderColor: alpha(palette.secondary.main, 0.5),
            },
          },
          '&.MuiButton-textSecondary': {
            color: palette.secondary.dark,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              color: alpha(palette.secondary.dark, 0.5),
            },
          },
          '&.MuiButton-containedInfo': {
            color: palette.info.contrastText,
            backgroundColor: palette.info.main,
            borderColor: palette.info.main,
            '&.Mui-disabled': {
              backgroundColor: alpha(palette.info.main, 0.5),
              borderColor: alpha(palette.info.main, 0.5),
            },
            '&:hover': {
              backgroundColor: palette.info.main,
              borderColor: palette.info.main,
            },
          },
          '&.MuiButton-outlinedInfo': {
            color: palette.info.contrastText,
            backgroundColor: 'transparent',
            borderColor: palette.info.main,
            '&.Mui-disabled': {
              color: alpha(palette.info.contrastText, 0.5),
              borderColor: alpha(palette.info.main, 0.5),
            },
          },
          '&.MuiButton-textInfo': {
            color: palette.info.dark,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              color: alpha(palette.info.dark, 0.5),
            },
          },
          '&.MuiButton-containedWarning': {
            color: palette.warning.contrastText,
            backgroundColor: palette.warning.main,
            borderColor: palette.warning.main,
            '&.Mui-disabled': {
              color: alpha(palette.warning.contrastText, 0.5),
              backgroundColor: alpha(palette.warning.main, 0.5),
              borderColor: alpha(palette.warning.main, 0.5),
            },
            '&:hover': {
              backgroundColor: palette.warning.main,
              borderColor: palette.warning.main,
            },
          },
          '&.MuiButton-outlinedWarning': {
            color: palette.warning.contrastText,
            backgroundColor: 'transparent',
            borderColor: palette.warning.main,
            '&.Mui-disabled': {
              color: alpha(palette.warning.contrastText, 0.5),
              borderColor: alpha(palette.warning.main, 0.5),
            },
          },
          '&.MuiButton-textWarning': {
            color: palette.warning.dark,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              color: alpha(palette.warning.dark, 0.5),
            },
          },
          '&.MuiButton-containedError': {
            color: palette.error.contrastText,
            backgroundColor: palette.error.dark,
            borderColor: palette.error.dark,
            '&.Mui-disabled': {
              backgroundColor: alpha(palette.error.dark, 0.5),
              borderColor: alpha(palette.error.dark, 0.5),
            },
            '&:hover': {
              backgroundColor: palette.error.dark,
              borderColor: palette.error.dark,
            },
          },
          '&.MuiButton-outlinedError': {
            color: palette.error.contrastText,
            backgroundColor: 'transparent',
            borderColor: palette.error.main,
            '&.Mui-disabled': {
              color: alpha(palette.error.contrastText, 0.5),
              borderColor: alpha(palette.error.main, 0.5),
            },
          },
          '&.MuiButton-textError': {
            color: palette.error.dark,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              color: alpha(palette.error.dark, 0.5),
            },
          },
          '&.MuiButton-containedSuccess': {
            color: palette.success.contrastText,
            backgroundColor: palette.success.main,
            borderColor: palette.success.main,
            '&.Mui-disabled': {
              color: alpha(palette.success.contrastText, 0.5),
              backgroundColor: alpha(palette.success.main, 0.5),
              borderColor: alpha(palette.success.main, 0.5),
            },
            '&:hover': {
              backgroundColor: palette.success.main,
              borderColor: palette.success.main,
            },
          },
          '&.MuiButton-outlinedSuccess': {
            color: palette.success.contrastText,
            backgroundColor: 'transparent',
            borderColor: palette.success.main,
            '&.Mui-disabled': {
              color: alpha(palette.success.contrastText, 0.5),
              borderColor: alpha(palette.success.main, 0.5),
            },
          },
          '&.MuiButton-textSuccess': {
            color: palette.success.dark,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              color: alpha(palette.success.dark, 0.5),
            },
          },
          '&.loading': {
            '& .MuiTypography-root': {
              visibility: 'hidden',
            },
          },
          '&.action-button': {
            padding: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: `${AlignItems.center}`,
            justifyContent: `${JustifyContent.center}`,
            color: palette.common.white,
          },
          '&.social-media': {
            display: 'flex',
            alignItems: `${AlignItems.center}`,
            justifyContent: `${JustifyContent.center}`,
            color: palette.common.white,
            '&.whatsapp': {
              backgroundColor: palette.socialMedia.whatsapp,
            },
            '&.facebook': {
              backgroundColor: palette.socialMedia.facebook,
            },
            '&.twitter': {
              backgroundColor: palette.socialMedia.twitter,
            },
          },
          '& .MuiCircularProgress-root': {
            margin: '0 auto',
            position: 'absolute',
            top: pxToRem(4),
            color: 'inherit',
            width: pxToRem(16),
            height: pxToRem(16),
          },
       },
      },
    },
  }
}