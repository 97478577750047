import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function FormControl(theme: Theme) {
  const { palette } = theme

  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: pxToRem(-4),
          marginBottom: pxToRem(4),
          marginLeft: 0,
          marginRight: 0,
          '& .MuiCheckbox-root': {
            padding: pxToRem(4),
            color: palette.app.border,
            '&.Mui-checked': {
              color: palette.blue.light,
            },
          },
          '& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label': {
            marginBottom: 0,
          },
        },
      },
    },
  }
}
