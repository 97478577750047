import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'
import { SIDE_MENU_WIDTH } from 'utils/constants'

export default function Drawer(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiDrawer: {
      styleOverrides: {
        root: {
          '&.users-menu': {
            '&.desktop': {
              '& .MuiPaper-root': {
                padding: `${pxToRem(8)} ${pxToRem(16)}`,
                position: 'relative',
                backgroundColor: palette.grey[200],
                width: pxToRem(SIDE_MENU_WIDTH),
                [breakpoints.down('lg')]: {
                  display: 'none',
                },
              },
            },
            '&.mobile': {
              '& .MuiList-root': {
                padding: `${pxToRem(8)} ${pxToRem(16)}`,
              },
            },
            '& .MuiPaper-root': {
              '& .MuiDivider-root': {
                marginBottom: pxToRem(8),
              },
              '& .MuiButtonBase-root.MuiListItemButton-root': {
                '&.desktop': {
                  margin: `0 ${pxToRem(-8)} ${pxToRem(8)}`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  '&:last-of-type': {
                    marginBottom: 0,
                  },
                  '&.Mui-selected': {
                    color: palette.common.white,
                    backgroundColor: palette.blue.main,
                    borderRadius: pxToRem(4),
                    '& .MuiListItemIcon-root': {
                      color: palette.common.white,
                    },
                  },
                },
                '&.mobile': {
                  '&.Mui-selected': {
                    color: palette.common.black,
                    backgroundColor: palette.common.white,
                    '& .MuiListItemIcon-root': {
                      color: palette.common.black,
                    },
                  },
                },
                '& .MuiListItemIcon-root': {
                  marginRight: pxToRem(16),
                  fontSize: pxToRem(18),
                  width: pxToRem(18),
                  minWidth: 'unset',
                  color: palette.common.black,
                },
                '& .MuiListItemText-root': {
                  marginTop: 0,
                  marginBottom: 0,
                  '& .MuiTypography-root': {
                    marginBottom: 0,
                  },
                },
              },
            },
          },
        },
      },
    }
  }
}