import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FooterContainer, MainContainer } from 'components/Navigation/styledComponents'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'

type Props = {
  dictionary: LanguageDictionary
  isSignedIn: boolean
}

const FooterGuests: FC<Props> = ({ dictionary, isSignedIn }) => {
  const { palette } = useTheme()

  if (isSignedIn) {
    return null
  }

  return (
    <FooterContainer>
      <MainContainer>
        <Typography variant="body2" color={palette.common.white}>
          {dictionary.footer.label}
        </Typography>
      </MainContainer>
    </FooterContainer>
  )
}

const mapStateToProps = ({ languageStore, sessionStore, timezoneStore }: ReduxStore) => {
  const { dictionary } = languageStore
  const { isSignedIn } = sessionStore
  const { timezone } = timezoneStore

  return {
    dictionary,
    isSignedIn,
    timezone,
  }
}

export default connect(
  mapStateToProps
)(FooterGuests)
