import { Theme, alpha } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Switch(theme: Theme) {
  const { palette } = theme

  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)}`,
          width: pxToRem(56),
          height: pxToRem(34),
          '& .MuiSwitch-switchBase': {
            margin: pxToRem(1),
            padding: 0,
            transform: `translateX(${pxToRem(0)})`,
            top: pxToRem(4),
            '&.Mui-checked': {
              backgroundColor: palette.blue.main,
              color: palette.common.white,
              transform: `translateX(${pxToRem(30)})`,
              '& .MuiSwitch-thumb': {
                backgroundColor: palette.blue.main,
                '&:before': {
                  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    '#fff',
                  )}" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>')`,
                },
              },
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: alpha(palette.blue.main, 0.5),
              },
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: alpha(palette.grey[500], 0.2),
            },
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: palette.common.white,
            width: pxToRem(24),
            height: pxToRem(24),
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                '#000',
              )}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"/></svg>')`,
            },
          },
          '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: alpha(palette.common.white, 0.5),
            borderRadius: pxToRem(10),
          },
        },
      },
    },
  }
}
