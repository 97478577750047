import { StyledComponent } from '@emotion/styled'
import { Box, Button, FormControl, FormControlLabel } from '@mui/material'
import { styled } from '@mui/system'
import { AlignItems, TextAlign } from 'components/utils/enums'
import { ButtonProps, CustomFormControlProps, FormControlLabelProps } from 'components/utils/types'
import isUndefined from 'lodash/isUndefined'
import { pxToRem } from 'theme/typography'
import { BorderStyle } from 'theme/utils/enums'

export const StyledFormControl: StyledComponent<any> = styled(FormControl, {
  shouldForwardProp: prop => prop !== 'fullWidth' && prop !== 'width' && prop !== 'marginTop' && prop !== 'marginBottom',
})<CustomFormControlProps>(({ theme, fullWidth, width, marginTop, marginBottom }) =>
  theme.unstable_sx({
    mt: marginTop,
    mb: marginBottom,
    width: fullWidth ? '100%' : width || 'auto',
  })
)

export const FieldMainContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    display: 'flex',
    alignItems: `${AlignItems.center}`,
  })
)

export const ToggleContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: 'auto',
    display: 'flex',
    alignItems: `${AlignItems.center}`,
  })
)

export const CustomButton: StyledComponent<any> = styled(Button, {
  shouldForwardProp: prop => prop !== 'width' && prop !== 'fullWidth',
})<ButtonProps>(({ theme, width, fullWidth }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: `${AlignItems.center}`,
    width: fullWidth ? '100%' : width || 'auto',
  })
)

export const NotificationContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    overflow: 'hidden',
  })
)

export const PasswordContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  })
)

export const CustomInputMainContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
    mb: pxToRem(4),
    p: 0,
    border: 0,
    verticalAlign: 'top',
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
  })
)

export const CustomInputLabel: StyledComponent<any> = styled('label')(({ theme }) =>
  theme.unstable_sx({
    fontWeight: 500,
  })
)

export const CustomInputInnerContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mb: 0,
    pr: 0,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: AlignItems.center,
    position: 'relative',
    borderRadius: pxToRem(8),
  })
)

export const CustomInputWrapper: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
    py: 0,
    px: pxToRem(8),
    textAlign: TextAlign.left,
    position: 'absolute',
    bottom: 0,
    right: 0,
    top: pxToRem(1),
    left: 0,
    pointerEvents: 'none',
    borderRadius: pxToRem(8),
    borderStyle: BorderStyle.solid,
    borderWidth: pxToRem(1),
    borderColor: theme.palette.app.border,
    overflow: 'hidden',
    height: pxToRem(36.13),
  })
)
CustomInputWrapper.defaultProps = {
  component: 'fieldset',
}

export const CustomCheckboxContainer: StyledComponent<any> = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'labelColor' && prop !== 'labelFontSize' && prop !== 'checkboxFontSize',
})<FormControlLabelProps>(({ theme, labelColor, labelFontSize, checkboxFontSize }) =>
  theme.unstable_sx({
    '& .MuiCheckbox-root .MuiSvgIcon-root': {
      ...(!isUndefined(checkboxFontSize) && {
        fontSize: checkboxFontSize,
      }),
    },
    '& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label': {
      ...(!isUndefined(labelColor) && {
        color: labelColor,
      }),
      ...(!isUndefined(labelFontSize) && {
        fontSize: labelFontSize,
      }),
    },
  })
)
