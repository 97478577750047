import { Grid, Link } from '@mui/material'
import FallbackImage from 'assets/not_available.jpg'
import {
  LabelWithoutMargin,
  Row,
  SkeletonImage,
  SkeletonText,
  ThumbnailBodyContainer,
  ThumbnailBodyDescription,
  ThumbnailBodyItem,
  ThumbnailBodyReference,
  ThumbnailCard,
  ThumbnailPhoto,
  ThumbnailPhotoContainer,
  ThumbnailPriceContainer,
} from 'components/styledComponents'
import { formatValueToHours, formatValueToMoney } from 'pages/Machinery/utils/libs'
import { MachineThumbnail } from 'pages/Machinery/utils/types'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import ActionButtons from './MachineDetails/ActionButtons'

type Props = {
  dictionary: LanguageDictionary
  loading?: boolean
  machine?: MachineThumbnail
}

const UnconnectedThumbnail: FC<Props> = ({
  dictionary,
  loading,
  machine,
}) => {
  const { machinery: machineryDictionary } = dictionary
  const {
    category = '',
    manufacturer = '',
    model = '',
    reference = '',
    year = 0,
    hours = 0,
    price = 0,
    description = '',
    photo = '',
    machineDetailsURL = '',
  } = machine || {}

  const hoursValue = hours
    ? formatValueToHours(hours)
    : machineryDictionary.na
  const priceValue = price
    ? formatValueToMoney(price)
    : machineryDictionary.na
  const showSkeleton = loading || !machine
  const bodyRowTemplate = '31% 1fr'

  return (
    <ThumbnailCard>
      <ThumbnailPhotoContainer>
        {showSkeleton
          ? <SkeletonImage borderBottomRadius={0} />
          : (
              <ThumbnailPhoto src={photo} onError={e => {
                  e.target.onError = null
                  e.target.src = FallbackImage
                }}
              />
            )
        }
        {!showSkeleton && (
          <ThumbnailPriceContainer>
            <LabelWithoutMargin variant="body1">{priceValue}</LabelWithoutMargin>
          </ThumbnailPriceContainer>
        )}
      </ThumbnailPhotoContainer>
      <ThumbnailBodyContainer>
        <ThumbnailBodyReference variant="h3">
          {showSkeleton
            ? <SkeletonText />
            : <>Ref: {reference}</>
          }
        </ThumbnailBodyReference>
        <Row groupedByTemplate={bodyRowTemplate}>
          <ThumbnailBodyItem variant="body1">
            {showSkeleton
              ? <SkeletonText />
              : <>{machineryDictionary.category}:</>
            }
          </ThumbnailBodyItem>
          <ThumbnailBodyItem variant="body2">
            {showSkeleton
              ? <SkeletonText />
              : <>{category}</>
            }
          </ThumbnailBodyItem>
        </Row>
        <Row groupedByTemplate={bodyRowTemplate}>
          <ThumbnailBodyItem variant="body1">
            {showSkeleton
              ? <SkeletonText />
              : <>{machineryDictionary.manufacturer}:</>
            }
          </ThumbnailBodyItem>
          <ThumbnailBodyItem variant="body2">
            {showSkeleton
              ? <SkeletonText />
              : <>{manufacturer}</>
            }
          </ThumbnailBodyItem>
        </Row>
        <Row groupedByTemplate={bodyRowTemplate}>
          <ThumbnailBodyItem variant="body1">
            {showSkeleton
              ? <SkeletonText />
              : <>{machineryDictionary.model}:</>
            }
          </ThumbnailBodyItem>
          <ThumbnailBodyItem variant="body2">
            {showSkeleton
              ? <SkeletonText />
              : <>{model}</>
            }
          </ThumbnailBodyItem>
        </Row>
        <Row groupedByTemplate={bodyRowTemplate}>
          <ThumbnailBodyItem variant="body1">
            {showSkeleton
              ? <SkeletonText />
              : <>{machineryDictionary.year}:</>
            }
          </ThumbnailBodyItem>
          <ThumbnailBodyItem variant="body2">
            {showSkeleton
              ? <SkeletonText />
              : <>{year}</>
            }
          </ThumbnailBodyItem>
        </Row>
        <Row groupedByTemplate={bodyRowTemplate}>
          <ThumbnailBodyItem variant="body1">
            {showSkeleton
              ? <SkeletonText />
              : <>{machineryDictionary.hours}:</>
            }
          </ThumbnailBodyItem>
          <ThumbnailBodyItem variant="body2">
            {showSkeleton
              ? <SkeletonText />
              : <>{hoursValue}</>
            }
          </ThumbnailBodyItem>
        </Row>
        <Row groupedByColumns={1}>
          <ThumbnailBodyItem variant="body1">
            {showSkeleton
              ? <SkeletonText />
              : <>{machineryDictionary.description}:</>
            }
          </ThumbnailBodyItem>
        </Row>
        <Row groupedByColumns={1}>
          <ThumbnailBodyDescription>
            {showSkeleton
              ? <SkeletonImage borderRadius={0} />
              : <LabelWithoutMargin variant="body2">{description}</LabelWithoutMargin>
            }
          </ThumbnailBodyDescription>
        </Row>
        <Grid container spacing={2}>
          <Grid item xs={6} />
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {showSkeleton
              ? <SkeletonText />
              : (
                  <Link href={machineDetailsURL} variant="caption" color="primary">
                    {machineryDictionary.viewDetails}
                  </Link>
                )
            }
          </Grid>
        </Grid>
      </ThumbnailBodyContainer>
      <ActionButtons showSkeleton={showSkeleton} thumbnailMachine={machine} />
    </ThumbnailCard>
  )
}

const mapStateToProps = ({ languageStore, machineryStore }: ReduxStore) => {
  const { dictionary } = languageStore
  const { machinesLoading } = machineryStore

  return {
    dictionary,
    loading: machinesLoading,
  }
}

const Thumbnail = connect(
  mapStateToProps,
)(UnconnectedThumbnail)

export default Thumbnail