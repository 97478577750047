import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Menu(theme: Theme) {
  const { palette } = theme
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: pxToRem(200),
          '&.MuiPaper-root': {
            padding: pxToRem(8),
            backgroundColor: palette.common.white,
            color: palette.common.black,
            '& .MuiList-root.MuiMenu-list': {
              padding: 0,
              '& .MuiListSubheader-root': {
                marginBottom: 0,
                padding: 0,
                lineHeight: 'unset',
                minHeight: pxToRem(24),
                fontSize: pxToRem(16),
                color: palette.common.black,
                display: 'grid',
                gridTemplateColumns: `1fr ${pxToRem(96)}`,
                '&:not(:first-of-type)': {
                  marginTop: pxToRem(16),
                },
                '& .MuiTypography-body1': {
                  marginBottom: 0,
                  '&.additional-value': {
                    textAlign: 'end',
                  },
                },
              },
              '& .MuiMenuItem-root.MuiMenuItem-gutters': {
                marginBottom: 0,
                padding: 0,
                display: 'flex',
                minHeight: pxToRem(24),
                fontWeight: 400,
              },
              '& .MuiTypography-root.MuiLink-root.MuiLink-underlineNone': {
                padding: 0,
                fontWeight: 500,
                color: palette.common.black,
                cursor: 'pointer',
                '&:hover': {
                  fontWeight: 500,
                  color: palette.common.black,
                },
                '&:visited': {
                  fontWeight: 500,
                  color: palette.common.black,
                },
              },
              '& .MuiTypography-root.MuiTypography-body1': {
                padding: 0,
                fontWeight: 500,
                color: palette.common.black,
                cursor: 'pointer',
                '&:hover': {
                  fontWeight: 500,
                  color: palette.common.black,
                },
                '&:visited': {
                  fontWeight: 500,
                  color: palette.common.black,
                },
              },
            },
          },
        },
      },
    },
  }
}