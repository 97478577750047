import { Typography } from '@mui/material'
import WheelLoader from 'assets/wheel_loader.png'
import { ComingSoonContainer, Image } from 'components/styledComponents'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  dictionary: LanguageDictionary
}

const ComingSoon: FC<Props> = ({ dictionary: { comingSoon: comingSoonDictionary } }) => (
  <ComingSoonContainer>
    <Typography variant="body2">
      {comingSoonDictionary.pageDescription}
    </Typography>
    <Image src={WheelLoader} width={{ xs: '90%', md: pxToRem(400)}} />
  </ComingSoonContainer>
)

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export default connect(
  mapStateToProps,
)(ComingSoon)