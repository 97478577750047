import { Fade, Paper, Popper } from '@mui/material'
import { CheckboxInput, PrimaryButton, SecondaryButton, StringInput } from 'components/FormFields'
import { Row } from 'components/styledComponents'
import { FilterOptionsContainer } from 'components/TableStructure/styledComponents'
import { cloneDeep, isArray, isEmpty, isString } from 'lodash'
import { PopperPlacement } from 'pages/utils/enums'
import { Filter, TableColumnFilterProperties } from 'pages/utils/types'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { KeyValueRecord } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  open: boolean
  anchorEl: SVGElement | null
  filterProperties?: TableColumnFilterProperties
  filterPlacement?: PopperPlacement
  filter?: Filter
  onClose: () => void
  onFilterChange: (newFilter: Filter) => void
}

const TableFilter: FC<Props> = ({
  dictionary: { shared: sharedDictionary },
  open,
  anchorEl,
  filterProperties,
  filterPlacement = PopperPlacement.bottomEnd,
  filter,
  onClose,
  onFilterChange,
}) => {
  const [singleFilter, setSingleFilter] = useState<string>('')
  const [multipleFilter, setMultipleFilter] = useState<KeyValueRecord<boolean>>({})

  const allOptionsSelected = (currentFilter: KeyValueRecord<boolean>) => {
    const totalItems = Object.keys(currentFilter).length - 1
    return Object.entries(currentFilter).filter(([key, value]) => key !== 'all' && !!value).length === totalItems
  }

  const handleSingleFilterChange = (newValue: string) => {
    setSingleFilter(newValue)
  }

  const handleMultipleFilterChange = (option: string) => () => {
    const isChecked = !multipleFilter[option]

    // Check all the options when the clicked option is "all".
    if (option === 'all' && isChecked && filterProperties?.options) {
      const newMultipleFilter: KeyValueRecord<boolean> = {}

      Object.keys(filterProperties.options).forEach(key => {
        newMultipleFilter[key] = true
      })

      // Update the multiple filter.
      setMultipleFilter(newMultipleFilter)

      return
    }

    // Unselect all the options (except for the one below "all").
    if (option === 'all' && !isChecked && filterProperties?.options) {
      const newMultipleFilter: KeyValueRecord<boolean> = {}

      Object.keys(filterProperties.options).forEach((key, index) => {
        newMultipleFilter[key] = index === 1
      })

      // Update the multiple filter.
      setMultipleFilter(newMultipleFilter)

      return
    }

    // Ignore unselecting the current option when it is the only one checked.
    if (Object.values(multipleFilter).filter(value => value).length === 1 && !isChecked) {
      return
    }

    // Define the new Miltiple Filter.
    const newMultipleFilter: KeyValueRecord<boolean> = cloneDeep(multipleFilter)
    newMultipleFilter[option] = isChecked

    // Identify the checked status for the "all" option.
    newMultipleFilter.all = allOptionsSelected(newMultipleFilter)

    // Update the multiple filter.
    setMultipleFilter(newMultipleFilter)
  }

  const onFilterClick = () => {
    const newFilter: Filter = !isEmpty(multipleFilter)
      ? Object.keys(multipleFilter).filter(key => key !== 'all' && multipleFilter[key])
      : singleFilter
    onFilterChange(newFilter)
  }

  useEffect(() => {
    if (filterProperties?.options && isArray(filter)) {
      const newMultipleFilter: KeyValueRecord<boolean> = {}

      Object.keys(filterProperties.options).forEach(key => {
        newMultipleFilter[key] = filter.includes(key)
      })

      // Identify the checked status for the "all" option.
      newMultipleFilter.all = allOptionsSelected(newMultipleFilter)

      setSingleFilter('')
      setMultipleFilter(newMultipleFilter)
    } else if (filterProperties?.value && isString(filter)) {
      setSingleFilter(filter)
      setMultipleFilter({})
    }
  }, [filterProperties, filter])

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={filterPlacement}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className="table-filter">
            {!!filterProperties?.options && (
              <FilterOptionsContainer>
                {Object.entries(filterProperties.options).map(([key, label]) => (
                  <CheckboxInput
                    key={key}
                    label={label.toString()}
                    fontSize={{ xs: pxToRem(11), md: pxToRem(12) }}
                    checkboxSize={pxToRem(18)}
                    checked={multipleFilter[key] === true}
                    onChange={handleMultipleFilterChange(key)}
                  />
                ))}
              </FilterOptionsContainer>
            )}
            {!!filterProperties?.value && (
              <StringInput
                label=""
                value={singleFilter}
                fullWidth
                onChange={handleSingleFilterChange}
              />
            )}
            <Row groupedByColumns={2} gridGap={pxToRem(16)} height="100%">
              <SecondaryButton
                label={sharedDictionary.close}
                onClick={onClose}
              />
              <PrimaryButton
                label={sharedDictionary.filter}
                onClick={onFilterClick}
              />
            </Row>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export default connect(
  mapStateToProps,
)(TableFilter)