import TableStructure from 'components/TableStructure'
import AccessControl from 'helpers/AccessControl'
import { isArray, isString } from 'lodash'
import moment from 'moment-timezone'
import PageContainer from 'pages/components/PageContainer'
import { PopperPlacement } from 'pages/utils/enums'
import { Filters, TableColumnProperties } from 'pages/utils/types'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getRequestsList } from 'redux/actions/requests'
import { Language, RequestsFilter, RequestsSortBy, SortDirection } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { GetRequestResponse, GetRequestsListParams } from 'redux/utils/requests.types'
import { ReduxStore } from 'redux/utils/types'
import { BASE_PATH, DATETIME_FORMATS, USERS_REQUESTS_PATH } from 'utils/constants'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  currentPage: number
  itemsPerPage: number
  requests: GetRequestResponse[]
  requestsLoading: boolean
  totalPages: number
  totalRequests: number
  timezone: string
  dispatchGetRequestsList: (jsonParams: GetRequestsListParams) => void
}

const UnconnectedRequests: FC<Props> = ({
  dictionary: { requests: requestsDictionary },
  language,
  currentPage,
  itemsPerPage,
  requests,
  requestsLoading,
  totalPages,
  totalRequests,
  timezone,
  dispatchGetRequestsList,
}) => {
  const navigate = useNavigate()
  const [filters, setFilters] = useState<Filters>({
    requestStatus: [
      RequestsFilter.customer,
      RequestsFilter.pending,
      RequestsFilter.solved,
      RequestsFilter.unsolved
    ],
    requestId: '',
    userCompanyName: '',
    userContactPerson: '',
  })
  const [sortBy, setSortBy] = useState<RequestsSortBy>(RequestsSortBy.requestId)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.desc)

  moment.locale(language)

  const columns: TableColumnProperties[] = [
    {
      id: 'requestId',
      minWidth: 150,
      initialSortDirection: SortDirection.desc,
      filter: {
        value: true,
      },
      filterPosition: PopperPlacement.bottomStart,
    },
    {
      id: 'userCompanyName',
      minWidth: 208,
      initialSortDirection: SortDirection.asc,
      filter: {
        value: true,
      },
      filterPosition: PopperPlacement.bottomEnd,
    },
    {
      id: 'userContactPerson',
      minWidth: 208,
      initialSortDirection: SortDirection.asc,
      filter: {
        value: true,
      },
      filterPosition: PopperPlacement.bottomEnd,
    },
    {
      id: 'requestStatus',
      minWidth: 134,
      initialSortDirection: SortDirection.asc,
      filter: {
        options: {
          [RequestsFilter.all]: requestsDictionary[RequestsFilter.all],
          [RequestsFilter.customer]: requestsDictionary[RequestsFilter.customer],
          [RequestsFilter.pending]: requestsDictionary[RequestsFilter.pending],
          [RequestsFilter.solved]: requestsDictionary[RequestsFilter.solved],
          [RequestsFilter.unsolved]: requestsDictionary[RequestsFilter.unsolved],
        },
      },
      filterPosition: PopperPlacement.bottomEnd,
      format: (value: string) => requestsDictionary[value],
    },
    {
      id: 'requestDate',
      minWidth: 144,
      initialSortDirection: SortDirection.desc,
      format: (value: string) => {
        const requestDateTime = moment.tz(value, timezone)
        return requestDateTime.format(language === Language.en ? DATETIME_FORMATS.usDateShort : DATETIME_FORMATS.mxDateShort)
      }
    },
  ]

  const handleUpdateRequestsList = useCallback((
    newPage: number,
    newFilters: Filters,
    newSortBy: RequestsSortBy,
    newSortDirection: SortDirection
  ): void => {
    const filterStatus: string = isArray(newFilters.requestStatus) ? newFilters.requestStatus.map(item => JSON.stringify(item)).join(',') : ''
    const filterRequestId: number = isString(newFilters.requestId) ? Number(newFilters.requestId) : 0
    const filterCompanyName: string = isString(newFilters.userCompanyName) ? newFilters.userCompanyName : ''
    const filterContactPerson: string = isString(newFilters.userContactPerson) ? newFilters.userContactPerson : ''

    if (newSortBy !== sortBy) {
      setSortBy(newSortBy)
    }

    if (newSortDirection !== sortDirection) {
      setSortDirection(newSortDirection)
    }

    const jsonParams: GetRequestsListParams = {
      language,
      filterStatus,
      filterRequestId,
      filterCompanyName,
      filterContactPerson,
      currentPage: newPage,
      itemsPerPage,
      sortBy: newSortBy,
      isSortAscending: newSortDirection === SortDirection.asc,
    }

    dispatchGetRequestsList(jsonParams)
  }, [sortBy, sortDirection])

  /**
   * Updates the value for the "Current Page" based on the new value provided.
   * @param _event The HTML change event (we don't need this).
   * @param newPage The new page number to be used to fetch the data for the Machines list.
   */
  const handlePaginationChange = (newPage: number): void => {
    handleUpdateRequestsList(newPage, filters, sortBy, sortDirection)
  }

  /**
   * Re-Sorts the table
   * @param newSortBy The selected column for re-sorting the table.
   * @param newSortDirection The new direction for the sort: Asc or Desc.
   */
  const handleSortChange = (newSortBy: RequestsSortBy, newSortDirection: SortDirection) => {
    handleUpdateRequestsList(currentPage, filters, newSortBy, newSortDirection)
  }

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters)
    handleUpdateRequestsList(currentPage, newFilters, sortBy, sortDirection)
  }

  const handleRowClick = (rowId: string | number) => {
    navigate(`${BASE_PATH}${USERS_REQUESTS_PATH[language]}/${rowId}`)
  }

  useEffect(() => {
    handleUpdateRequestsList(currentPage, filters, sortBy, sortDirection)
  }, [])

  return (
    <PageContainer ogDescription={requestsDictionary.pageDescription}>
      <TableStructure
        columns={columns}
        columnDictionary={requestsDictionary}
        rows={requests}
        filters={filters}
        sortBy={sortBy}
        sortDirection={sortDirection}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        isLoading={requestsLoading}
        totalPages={totalPages}
        totalRecords={totalRequests}
        onFiltersChange={handleFilterChange}
        onPaginationChange={handlePaginationChange}
        onRowClick={handleRowClick}
        onSortChange={handleSortChange}
      />
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, requestsStore, timezoneStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { currentPage, itemsPerPage, requests, requestsLoading, totalPages, totalRequests } = requestsStore
  const { timezone } = timezoneStore

  return {
    dictionary,
    language,
    currentPage,
    itemsPerPage,
    requests,
    requestsLoading,
    totalPages,
    totalRequests,
    timezone,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetRequestsList: (jsonParams: GetRequestsListParams) => dispatch(getRequestsList(jsonParams)),
})

const Requests: any = AccessControl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedRequests))

export default Requests