import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Paper(theme: Theme) {
  const { breakpoints, palette } = theme
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          [breakpoints.up('md')]: {
            marginBottom: pxToRem(8),
          },
          [breakpoints.up('xl')]: {
            marginBottom: pxToRem(16),
          },
          '&.mobile.menu': {
            color: palette.common.white,
            backgroundColor: 'transparent',
          },
          '&.table-main-wrapper': {
            marginBottom: 0,
            width: '100%',
            overflow: 'hidden',
            borderRadius: 0,
            boxShadow: 'none',
          },
          '&.table-filter': {
            marginTop: pxToRem(11),
            marginBottom: 0,
            padding: pxToRem(8),
            borderRadius: pxToRem(4),
            borderWidth: pxToRem(1),
            borderStyle: 'solid',
            borderColor: palette.common.black,
            backgroundColor: palette.common.white,
            boxShadow: '2px 2px 1px -1px rgba(0, 0, 0, 0.2), 2px 2px 1px 0px rgba(0, 0, 0, 0.14), 2px 1px 3px 0px rgba(0, 0, 0, 0.12)',
            '& .MuiRadioGroup-root': {
              '& .MuiFormControlLabel-root': {
                '& .MuiButtonBase-root.MuiRadio-root': {
                  padding: pxToRem(8),
                  paddingLeft: 0,
                },
              },
            },
          },
        },
      },
    },
  }
}
