import { Theme, alpha } from '@mui/material/styles'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function Link(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textDecoration: 'none',
          '&.navbar': {
            color: palette.app.navigation.inactiveLink,
            backgroundColor: 'transparent',
            display: 'flex',
            '&:hover': {
              color: alpha(palette.app.navigation.activeLink, 0.7),
              backgroundColor: 'transparent',
            },
            '&.Mui-selected': {
              color: palette.app.navigation.activeLink,
            },
          },
          '&.action-button': {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: `${AlignItems.center}`,
            justifyContent: `${JustifyContent.center}`,
            color: palette.common.white,
          },
          '&.forgot-password': {
            paddingTop: pxToRem(4),
            fontWeight: 700,
            fontSize: pxToRem(12),
            [breakpoints.up('md')]: {
              fontSize: pxToRem(14),
            },
          },
        },
      },
    },
  }
}