import en from 'languages/en'
import es from 'languages/es'
import { Language } from 'redux/utils/enums'
import { LanguageDictionaryList } from 'redux/utils/language.types'
import { KeyValueRecord } from 'utils/types'

export const dictionaryList: LanguageDictionaryList = {
  es,
  en,
}

export const languageLabel: KeyValueRecord<string> = {
  [Language.en]: 'English',
  [Language.es]: 'Español',
}
