import { Facebook as FacebookIcon, Twitter as TwitterIcon, WhatsApp as WhatsAppIcon } from '@mui/icons-material'
import { CustomModal } from 'components/FormFields'
import { LabelWithoutMargin, Row, SocialMediaButton, SocialMediaButtonsContainer } from 'components/styledComponents'
import { SocialMediaType } from 'pages/Machinery/utils/enums'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { FACEBOOK_BASE_URL, TWITTER_BASE_URL } from 'utils/constants'

type Props = {
  dictionary: LanguageDictionary
  isOpen: boolean
  machineInfo: string
  machineURL: string
  whatsAppURL: string
  closable?: boolean
  onClose: () => void
}

const SocialMediaShareModal: FC<Props> = ({
  dictionary: { machinery: machineryDictionary },
  isOpen,
  machineInfo,
  machineURL,
  whatsAppURL,
  closable,
  onClose,
}) => {
  const socialMediaRowTemplate = `${pxToRem(24)} 1fr`

  const handleShareDetails = (socialMediaType: SocialMediaType) => () => {
    let shareURL

    switch (socialMediaType) {
      case SocialMediaType.whatsapp:
        shareURL = `${whatsAppURL}?text=${machineryDictionary.sharingMessage}%0A%0D${machineInfo}`
        break
      case SocialMediaType.facebook:
        shareURL = `${FACEBOOK_BASE_URL}${machineURL}&quote=${machineryDictionary.sharingMessage}`
        break
      case SocialMediaType.twitter:
        shareURL = `${TWITTER_BASE_URL}${machineURL}&text=${machineryDictionary.sharingMessage}%0A%0D%0A%0D`
        break
      default:
    }

    window.open(shareURL)
    onClose()
  }

  return (
    <CustomModal isOpen={isOpen} closable={closable} title={machineryDictionary.thumbnailActionShare} onClose={onClose}>
      <SocialMediaButtonsContainer>
        <SocialMediaButton className="social-media whatsapp" onClick={handleShareDetails(SocialMediaType.whatsapp)}>
          <Row groupedByTemplate={socialMediaRowTemplate}>
            <WhatsAppIcon /> <LabelWithoutMargin variant="overline">WhatsApp</LabelWithoutMargin>
          </Row>
        </SocialMediaButton>
        <SocialMediaButton className="social-media facebook" onClick={handleShareDetails(SocialMediaType.facebook)}>
          <Row groupedByTemplate={socialMediaRowTemplate}>
            <FacebookIcon /> <LabelWithoutMargin variant="overline">Facebook</LabelWithoutMargin>
          </Row>
        </SocialMediaButton>
        <SocialMediaButton className="social-media twitter" onClick={handleShareDetails(SocialMediaType.twitter)}>
          <Row groupedByTemplate={socialMediaRowTemplate}>
            <TwitterIcon /> <LabelWithoutMargin variant="overline">Twitter</LabelWithoutMargin>
          </Row>
        </SocialMediaButton>
      </SocialMediaButtonsContainer>
    </CustomModal>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export default connect(
  mapStateToProps,
)(SocialMediaShareModal)
