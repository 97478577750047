import { AlignItems } from 'components/utils/enums'

export default function Alert() {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: `${AlignItems.center}`,
          '&.notification': {
            marginTop: '-100%',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transition: 'all 1s',
            '&.show': {
              marginTop: 0,
            },
          },
        },
      },
    },
  }
}
