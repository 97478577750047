import { Link, List, ListItem, Typography } from '@mui/material'
import { PageNotFoundLink } from 'components/utils/types'
import PageContainer from 'pages/components/PageContainer'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { ABOUT_US_PATH, BASE_PATH, FAQ_PATH, MACHINERY_CATALOG_PATH } from 'utils/constants'

type Props = {
  dictionary: LanguageDictionary
  language: Language
}

const PageNotFound: FC<Props> = ({
  dictionary,
  language,
}) => {
  const {
    pageNotFound: pageNotFoundDictionary,
  } = dictionary

  const links: PageNotFoundLink[] = [
    {
      label: pageNotFoundDictionary.linkHome,
      url: BASE_PATH,
    },
    {
      label: pageNotFoundDictionary.linkMachinery,
      url: `${BASE_PATH}${MACHINERY_CATALOG_PATH[language]}`,
    },
    {
      label: pageNotFoundDictionary.linkAboutUs,
      url: `${BASE_PATH}${ABOUT_US_PATH[language]}`,
    },
    {
      label: pageNotFoundDictionary.linkFAQ,
      url: `${BASE_PATH}${FAQ_PATH[language]}`,
    },
  ]

  return (
    <PageContainer ogDescription={pageNotFoundDictionary.pageDescription}>
      <Typography variant="body2">{pageNotFoundDictionary.content}</Typography>
      <List className="bullet-list">
        {links.map(link => (
          <ListItem key={link.url}>
            <Link href={link.url}>{link.label}</Link>
          </ListItem>
        ))}
      </List>
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary, language } = languageStore

  return {
    dictionary,
    language,
  }
}

export default connect(
  mapStateToProps,
)(PageNotFound)