import { USERS_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { UsersAction, UsersState } from 'redux/utils/users.types'

const users = (state: UsersState = USERS_INITIAL_STATE, action: UsersAction): UsersState => {
  switch (action.type) {
    case Action.USERS_USERNAME_VERIFICATION:
      return {
        ...state,
        ...action.payload,
      }
    case Action.USERS_USERNAME_VERIFICATION_LOADING:
      return {
        ...state,
        validateEmailLoading: action.payload,
      }
    case Action.USERS_SIGNUP:
      return {
        ...state,
        userSignupResponse: action.payload,
      }
    case Action.USERS_SIGNUP_LOADING:
      return {
        ...state,
        userSignupLoading: action.payload,
      }
    case Action.USERS_VALIDATE_EMAIL: {
      const { isEmailValidated, errorCode } = action.payload

      return {
        ...state,
        isEmailValidated,
        errorCode,
      }
    }
    case Action.USERS_VALIDATE_EMAIL_LOADING:
      return {
        ...state,
        validateEmailLoading: action.payload,
      }
    case Action.USERS_USERNAME_VALIDATION: {
      const { email, name } = action.payload
      return {
        ...state,
        email,
        name,
      }
    }
    case Action.USERS_USERNAME_VALIDATION_LOADING:
      return {
        ...state,
        usernameValidationLoading: action.payload,
      }
    default:
      return state
  }
}

export default users