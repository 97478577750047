import {
  CheckCircle as SuccessIcon,
  HighlightOff as ErrorIcon,
} from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import { CustomButton } from 'components/FormFields/styledComponents'
import { LabelWithoutMargin } from 'components/styledComponents'
import { AdornmentPosition, ButtonVariant } from 'components/utils/enums'
import React, { FC } from 'react'
import { InputColors } from 'theme/utils/enums'
import { MUIIcon } from 'utils/types'

type Props = {
  className?: string
  label: string
  variant?: ButtonVariant
  color?: InputColors
  fontSize?: string | ResponsiveStyleValue<string>
  width?: string | ResponsiveStyleValue<string>
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
  success?: boolean
  error?: boolean
  icon?: MUIIcon
  iconPosition?: AdornmentPosition
  onClick: () => void
}

export const SecondaryButton: FC<Props> = ({
  className,
  label,
  variant = ButtonVariant.outlined,
  color = InputColors.secondary,
  fontSize,
  width = 'auto',
  fullWidth = false,
  loading,
  disabled,
  success,
  error,
  icon,
  iconPosition = AdornmentPosition.start,
  onClick,
}) => {
  const buttonClassName = className
    ? `${className}${loading ? ' loading' : ''}`
    : `${loading ? 'loading' : ''}`

  const hasStartIcon: boolean = iconPosition === AdornmentPosition.start || !!success || !!error
  const hasEndIcon: boolean = iconPosition === AdornmentPosition.end

  const endIcon: MUIIcon | undefined = hasEndIcon ? icon : undefined
  let startIcon: MUIIcon | undefined = hasStartIcon ? icon : undefined

  if (hasStartIcon && success) {
    startIcon = SuccessIcon
  } else if (hasStartIcon && error) {
    startIcon = ErrorIcon
  }

  return (
    <CustomButton
      className={buttonClassName}
      variant={variant}
      color={color}
      fontSize={fontSize}
      width={width}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && (
        <CircularProgress size={16} />
      )}
      <LabelWithoutMargin>{label}</LabelWithoutMargin>
    </CustomButton>
  )
}