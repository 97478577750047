import emailjs from '@emailjs/browser'
import { CircularProgress, Typography } from '@mui/material'
import { PrimaryButton } from 'components/FormFields'
import { Row } from 'components/styledComponents'
import { JustifyContent, NotificationType } from 'components/utils/enums'
import AccessControl from 'helpers/AccessControl'
import { useWindowDimensions } from 'hooks'
import isNull from 'lodash/isNull'
import moment from 'moment'
import PageContainer from 'pages/components/PageContainer'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { showAlert } from 'redux/actions/alert'
import { validateEmail } from 'redux/actions/users'
import { ShowAlertParams } from 'redux/utils/alerts.types'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import {
  APP_URL,
  DATETIME_FORMATS,
  EMAIL_JS_GENERAL_TEMPLATE_ID,
  EMAIL_JS_PUBLIC_KEY,
  EMAIL_JS_SERVICE_ID,
  GUEST_MENU_MAP,
  USERS_VALIDATE_EMAIL_PATH,
} from 'utils/constants'
import { TimeMeasurement } from 'utils/enums'
import { decodeContentUtf8, encodeContentUtf8, linkExpiration } from 'utils/libs'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  errorCode?: string
  isEmailValidated: boolean
  validateEmailLoading?: boolean
  dispatchShowAlert: (alertProps: ShowAlertParams) => void
  dispatchValidateEmail: (emailToValidate: string) => void
}

const UnconnectedEmailValidation: FC<Props> = ({
  dictionary: {
    error: errorDictionary,
    shared: sharedDictionary,
    signIn: signInDictionary,
    signUp: signUpDictionary,
    userValidateEmail: validateEmailDictionary
  },
  language,
  errorCode,
  isEmailValidated,
  validateEmailLoading,
  dispatchShowAlert,
  dispatchValidateEmail,
}) => {
  const navigate = useNavigate()
  const { encodedLink } = useParams()
  const { register, setValue } = useForm()
  const formRef = useRef() as MutableRefObject<HTMLFormElement>
  const [email, setEmail] = useState<string>('')
  const [recipientName, setRecipientName] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [buttonLabel, setButtonLabel] = useState<string>('')
  const [celebrate, setCelebrate] = useState<boolean>(false)
  const { windowWidth, windowHeight } = useWindowDimensions()
  const [isCodeValidationExpired, setIsCodeValidationExpired] = useState<boolean>(false)

  const shouldRedirectToLoginPage = isEmailValidated || errorCode === 'userAlreadyValidated'

  /**
   * Re-sends a Validation Email to the User when the validation code has expired.
   * @returns 
   */
  const handleResendValidationEmail = (): void => {
    if (!email) {
      return
    }

    // Set the params to be sent throug the Email Validation URL.
    const emailValidationParams = {
      email,
      recipientName,
      timestamp: moment(),
    }

    // Encode the link to make it safer.
    const encodedLink = encodeContentUtf8(JSON.stringify(emailValidationParams))

    // Set the URL for the Email Validation.
    const emailValidationUrl = `${APP_URL}/${USERS_VALIDATE_EMAIL_PATH[language]}?${encodedLink}`

    const subject = signUpDictionary.emailJSValidateEmailSubject
    const body = signUpDictionary.emailJSValidateEmailBody

    // Update the emailJS params for the email validation.
    setValue('subject', subject)
    setValue('to_email', email)
    setValue('greeting', `${sharedDictionary.hello} ${recipientName}`)
    setValue('email_body', body)
    setValue('button_label', sharedDictionary.validateEmail)
    setValue('email_validation_url', emailValidationUrl)

    // Send the Password Reset email to the user.
    emailjs.sendForm(EMAIL_JS_SERVICE_ID, EMAIL_JS_GENERAL_TEMPLATE_ID, formRef.current, EMAIL_JS_PUBLIC_KEY)
    .then((_result) => {
      // Display a success message.
      const alertProps: ShowAlertParams = {
        type: NotificationType.success,
        title: sharedDictionary.congratulations,
        message: signUpDictionary.emailJSValidateEmailSuccess,
        buttonLabel: sharedDictionary.ok,
      }
  
      // Trigger the "showAlert" action for displaying the Alert modal.
      dispatchShowAlert(alertProps)
    }, (_error) => {
      // Display an error message.
      const alertProps: ShowAlertParams = {
        type: NotificationType.error,
        title: errorDictionary.somethingWentWrong,
        message: signUpDictionary.emailJSValidateEmailError,
        buttonLabel: sharedDictionary.ok,
      }
  
      // Trigger the "showAlert" action for displaying the Alert modal.
      dispatchShowAlert(alertProps)
    })
  }

  const handleButtonClick = (isExpired: boolean, shouldRedirect: boolean) => (): void => {
    if (isExpired) {
      handleResendValidationEmail()
    } else if (shouldRedirect) {
      // Redirect the user to the Login page.
      navigate(GUEST_MENU_MAP(language).signIn)
    }
  }

  useEffect(() => {
    // Define the different error messages to be used.
    const unexistenCodeMessage = `${validateEmailDictionary.thankYou} ${errorDictionary.unexistentValidationCode}`
    const invalidCodeMessage = `${validateEmailDictionary.thankYou} ${errorDictionary.invalidValidationCode}`
    const expiredCodeMessage = `${validateEmailDictionary.thankYou} ${errorDictionary.expiredValidationCode} ${validateEmailDictionary.resendValidationEmailMessage}`

    // Set an error message when there is no encoded link.
    if (!encodedLink) {
      setContent(unexistenCodeMessage)
    } else {
      try {
        // Decode the Validation Code.
        const decodedLink = JSON.parse(decodeContentUtf8(encodedLink))

        // Set an error message if any of the expected params is missing.
        if (!decodedLink.email || !decodedLink.recipientName || !decodedLink.timestamp) {
          setContent(invalidCodeMessage)
        } else {
          // Get the Validation Code props.
          const { email: emailProp, recipientName: recipientProp, timestamp: timestampProp } = decodedLink

          // Format the timestamp prop and the current date.
          const formattedLinkTimestamp = moment(timestampProp).format(DATETIME_FORMATS.fullDatetime)
          const formattedCurrentTimestamp = moment().format(DATETIME_FORMATS.fullDatetime)

          // Verify if the validation code has expired or not.
          const isLinkExpired = linkExpiration(formattedLinkTimestamp, formattedCurrentTimestamp, TimeMeasurement.days, 1)

          if (isLinkExpired) {
            // Set an error message when the link has expired.
            setContent(expiredCodeMessage)
            setButtonLabel(validateEmailDictionary.resendValidationEmailButton)

            // Store the User's Email and Name into the state to be used for sending the email.
            setEmail(emailProp)
            setRecipientName(recipientProp)
          } else {
            // Set an initial message while validating the email.
            setContent(sharedDictionary.validatingCode)

            // Trigger the API for validating the email.
            dispatchValidateEmail(emailProp)
          }

          setIsCodeValidationExpired(isLinkExpired)
        }
      } catch(error) {
        setContent(invalidCodeMessage)
      }
    }
  }, [])

  useEffect(() => {
    if (isEmailValidated) {
      // Create the structure for the success message.
      const successMessages = [
        validateEmailDictionary.emailValidationSuccess,
        validateEmailDictionary.welcomeEmail,
        validateEmailDictionary.loginAccess,
      ]

      // Set a success message since the email has been validated.
      setContent(successMessages.join(' '))
      setButtonLabel(signInDictionary.signIn)

      // Activate the confetti celebration.
      setCelebrate(true)
    } else if (!isNull(isEmailValidated) && !isEmailValidated && errorCode) {
      // Define the different error messages to be used.
      const notificationMessage = `${validateEmailDictionary.thankYou} ${validateEmailDictionary[errorCode]}`

      setContent(notificationMessage)
      setButtonLabel(signInDictionary.signIn)
    }
  }, [errorCode, isEmailValidated, validateEmailLoading])

  return (
    <PageContainer>
      <Typography variant="body2">{content}</Typography>
      <Row marginTop={pxToRem(16)} justifyContent={JustifyContent.center}>
        {(isCodeValidationExpired || shouldRedirectToLoginPage) && (
          <PrimaryButton
            label={buttonLabel}
            onClick={handleButtonClick(isCodeValidationExpired, shouldRedirectToLoginPage)}
          />
        )}
        {isNull(isEmailValidated) && validateEmailLoading && (
          <CircularProgress />
        )}
      </Row>
      <Confetti
        width={windowWidth}
        height={windowHeight}
        style={{
          width: '100%',
          height: '100%',
        }}
        numberOfPieces={celebrate ? 2000 : 0}
        recycle={false}
        onConfettiComplete={confetti => {
          setCelebrate(false)
          confetti?.reset()
        }}
        tweenDuration={10000}
      />
      <form ref={formRef} style={{ display: 'none' }}>
        <input value="" {...register('subject')} />
        <input value="" {...register('to_email')} />
        <input value="" {...register('greeting')} />
        <input value="" {...register('email_body')} />
        <input value="" {...register('button_label')} />
        <input value={sharedDictionary.emailJSClosing} {...register('email_closing')} />
        <input value={sharedDictionary.emailJSSignature} {...register('email_signature')} />
        <input value="" {...register('email_validation_url')} />
        <input value="" {...register('reply_to')} />
        <input type="submit" />
      </form>
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, usersStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { errorCode, isEmailValidated, validateEmailLoading } = usersStore

  return {
    dictionary,
    language,
    errorCode,
    isEmailValidated,
    validateEmailLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchShowAlert: (alertProps: ShowAlertParams) => dispatch(showAlert(alertProps)),
  dispatchValidateEmail: (emailToValidate: string) => dispatch(validateEmail(emailToValidate)),
})

const EmailValidation: any = AccessControl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedEmailValidation))

export default EmailValidation
