import { Theme, alpha } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Autocomplete(theme: Theme) {
  const { breakpoints, palette } = theme
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            [breakpoints.up('lg')]: {
              fontSize: pxToRem(18),
            },
            '& .MuiInputLabel-asterisk.MuiFormLabel-asterisk': {
              color: palette.error.main,
            },
          },
          '& .MuiOutlinedInput-root.MuiInputBase-root': {
            flexWrap: 'unset',
          },
          '& .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused': {
            flexWrap: 'wrap',
          },
          '& .MuiAutocomplete-input': {
            minWidth: 'unset',
            [breakpoints.up('lg')]: {
              fontSize: pxToRem(18),
            },
          },
          '& .MuiChip-root': {
            color: palette.common.white,
            backgroundColor: palette.app.badge,
            '& .MuiChip-deleteIcon': {
              color: alpha(palette.common.white, 0.75),
              [breakpoints.up('lg')]: {
                color: alpha(palette.common.white, 0.5),
                '&:hover': {
                  color: alpha(palette.common.white, 0.75),
                },
              },
            },
          },
        },
        option: {
          [breakpoints.up('lg')]: {
            fontSize: pxToRem(14),
          },
        },
      },
    },
  }
}