import { STATES_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { StatesAction, StatesState } from 'redux/utils/states.types'

export const states = (state: StatesState = STATES_INITIAL_STATE, action: StatesAction): StatesState => {
  switch (action.type) {
    case Action.STATES_LOADING:
      return {
        ...state,
        statesLoading: action.payload,
      }
    case Action.STATES_READ:
      return {
        ...state,
        states: action.payload,
      }
    default:
      return state
  }
}

export default states