import { RoutingContainer } from 'components/styledComponents'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { routesList } from 'utils/libs'
import { Route as RouteType } from 'utils/types'
// import { Navigate } from 'react-router-dom'
// import { BASE_PATH } from 'utils/constants'

type Props = {
  dictionary: LanguageDictionary,
  language: Language
}

const Routing: FC<Props> = ({ dictionary, language }) => (
  <RoutingContainer>
    <Routes>
      {routesList(language, dictionary).map((route: RouteType) => {
        const { path, Component } = route

        // if (path === BASE_PATH) {
        //   const homePath = roleLevelId <= 4 ? `${BASE_PATH}${OPERATIONS_SNAPSHOTS_PATH}` : `${BASE_PATH}${HOME_PATH}`
        //   return (
        //     <Route
        //       key={path}
        //       path={path}
        //       element={<Navigate to={homePath} replace />}
        //     />
        //   )
        // }

        return (
          <Route
            key={path}
            path={path}
            element={<Component />}
          />
        )
      })}
    </Routes>
  </RoutingContainer>
)

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary, language } = languageStore

  return {
    dictionary,
    language,
  }
}

export default connect(
  mapStateToProps,
)(Routing)