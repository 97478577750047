export default function Button() {
  return {
    MuiBox: {
      styleOverrides: {
        root: {
          '&.ClickableIcon-root': {
            '&.Mui-disabled': {
              opacity: 0.38,
            },
          },
        },
      },
    },
  }
}