import { Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { USERS_MENU_OPTIONS } from 'components/utils/constants'
import { DeviceVisibility, UsersMenuOption } from 'components/utils/enums'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { signOut } from 'redux/actions/session'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { BASE_PATH, USERS_MENU_MAP } from 'utils/constants'
import { getPageFromPath } from 'utils/libs'
import { Route } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  isSignedIn: boolean
  userPrivilege?: number
  device: DeviceVisibility.desktop | DeviceVisibility.mobile
  dispathSignOut: () => void
}

const UsersMenuOptions: FC<Props> = ({
  dictionary,
  dictionary: { navbar: navbarDictionary },
  language,
  isSignedIn,
  userPrivilege,
  device,
  dispathSignOut,
}) => {
  const { pathname } = useLocation() || {}
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState<string>(UsersMenuOption.home)

  const page: Route | undefined = getPageFromPath(language, dictionary, pathname)

  const ignoreDevice = device === DeviceVisibility.desktop
    ? DeviceVisibility.mobile
    : DeviceVisibility.desktop

  const handleMenuChange = (newOption: string) => () => {
    // Store the selected menu into the State.
    setSelectedOption(newOption)

    // Sign-out the User when the selected option is Sign-out.
    if (newOption === UsersMenuOption.signOut) {
      dispathSignOut()
    }

    // Move to the selected page.
    navigate(USERS_MENU_MAP(language)[newOption])
  }

  useEffect(() => {
    const shouldResetNavOption = (!page || pathname === BASE_PATH) && !!selectedOption && selectedOption !== UsersMenuOption.home
    const shouldUpdateNavOption = page && selectedOption !== page.navOption

    if (shouldResetNavOption) {
      setSelectedOption(UsersMenuOption.home)
    } else if (shouldUpdateNavOption) {
      const { navOption: newNavOption } = page
      setSelectedOption(newNavOption)
    }
  }, [page])

  if (!isSignedIn) {
    return null
  }

  return (
    <>
      {USERS_MENU_OPTIONS.filter(option => option.deviceVisibility !== ignoreDevice).map(option => {
        const {
          id,
          icon: IconComponent,
          isSeparator,
          allowedPrivilegeIds,
        } = option

        const shouldDisplayOption = !allowedPrivilegeIds || (!!userPrivilege && allowedPrivilegeIds.includes(userPrivilege))

        if (isSeparator) {
          return <Divider key={id} />
        }

        if (shouldDisplayOption) {
          const isSelected = selectedOption === id

          return (
            <ListItemButton
              key={id}
              className={device}
              selected={isSelected}
              disableGutters
              onClick={handleMenuChange(id)}
              disableRipple
            >
              <ListItemIcon>
                {IconComponent && (
                  <IconComponent />
                )}
              </ListItemIcon>
              <ListItemText primary={navbarDictionary[id]} />
            </ListItemButton>
          )
        }

        return null
      })}
    </>
  )
}

const mapStateToProps = ({ languageStore, sessionStore, timezoneStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { isSignedIn, sessionData } = sessionStore
  const { timezone } = timezoneStore

  const { userPrivilege } = sessionData || {}

  return {
    dictionary,
    language,
    isSignedIn,
    userPrivilege,
    timezone,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispathSignOut: () => dispatch(signOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersMenuOptions)
