import { QUOTATION_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { QuotationAction, QuotationState } from 'redux/utils/quotation.types'

const quotations = (state: QuotationState = QUOTATION_INITIAL_STATE, action: QuotationAction): QuotationState => {
  switch (action.type) {
    case Action.QUOTATION_DATA_READ:
      return {
        ...state,
        quotation: action.payload,
      }
    case Action.QUOTATION_DATA_LOADING:
      return {
        ...state,
        quotationLoading: action.payload,
      }
    case Action.QUOTATION_DESTINATIONS_READ:
      return {
        ...state,
        quotationDestinations: action.payload,
      }
    case Action.QUOTATION_DESTINATIONS_LOADING:
      return {
        ...state,
        quotationDestinationsLoading: action.payload,
      }
    default:
      return state
  }
}

export default quotations
