import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Tabs(theme: Theme) {
  const { breakpoints } = theme

  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.ValidationLabel-root': {
            fontSize: pxToRem(12),
            [breakpoints.up('md')]: {
              fontSize: pxToRem(12),
            },
          },
        },
      },
    },
  }
}