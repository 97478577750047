export enum InputType {
  email = 'email',
  file = 'file',
  number = 'number',
  password = 'password',
  search = 'search',
  text = 'text',
  url = 'url',
}

export enum IconFontSize {
  inherit = 'inherit',
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum InputSize {
  small = 'small',
  medium = 'medium',
}

export enum InputVariant {
  filled = 'filled',
  outlined = 'outlined',
  standard = 'standard',
}

export enum ButtonVariant {
  text = 'text',
  contained = 'contained',
  outlined = 'outlined',
}

export enum AdornmentPosition {
  start = 'start',
  end = 'end',
}

export enum NotificationType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum TextAlign {
  start = 'start',
  center = 'center',
  end = 'end',
  justify = 'justify',
  left = 'left',
  right = 'right',
  inherit = 'inherit',
  initial = 'initial',
  revert = 'revert',
  revertLayer = 'revert-layer',
  unset = 'unset',
}

export enum AlignItems {
  baseline = 'baseline',
  start = 'start',
  center = 'center',
  end = 'end',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  normal = 'normal',
  selfStart = 'self-start',
  selfEnd = 'self-end',
  stretch = 'stretch',
  inherit = 'inherit',
  initial = 'initial',
  revert = 'revert',
  revertLayer = 'revert-layer',
  unset = 'unset',
}

export enum JustifyContent {
  start = 'start',
  center = 'center',
  end = 'end',
  left = 'left',
  right = 'right',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  normal = 'normal',
  spaceAround = 'space-around',
  spaceBetween = 'space-between',
  spaceEvenly = 'space-evenly',
  stretch = 'stretch',
  inherit = 'inherit',
  initial = 'initial',
  revert = 'revert',
  revertLayer = 'revert-layer',
  unset = 'unset',
}

export enum GuestsMenuOption {
  machinery = 'machinery',
  aboutUs = 'aboutUs',
  faq = 'faq',
  publishMachine = 'publishMachine',
  signUp = 'signUp',
  signIn = 'signIn',
}

export enum UsersMenuOption {
  home = 'usersHome',
  machinery = 'machinery',
  publishMachine = 'publishMachine',
  myMachinery = 'myMachinery',
  requests = 'requests',
  messages = 'messages',
  account = 'account',
  users = 'users',
  switchLanguage = 'switchLanguage',
  signOut = 'signOut',
}

export enum DeviceVisibility {
  desktop = 'desktop',
  mobile = 'mobile',
  all = 'all',
}