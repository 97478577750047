import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Avatar(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          marginRight: pxToRem(8),
          color: palette.common.black,
          backgroundColor: palette.grey[400],
          border: `${pxToRem(1)} solid ${palette.common.black}`,
          width: pxToRem(40),
          height: pxToRem(40),
          [breakpoints.up('lg')]: {
            marginRight: pxToRem(16),
          },
        },
      },
    },
  }
}
