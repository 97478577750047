import moment from 'moment-timezone'
import { Language } from 'redux/utils/enums'
import { DATETIME_FORMATS } from 'utils/constants'
import { KeyValueRecord, UserName } from 'utils/types'

export const useGetGreeting = (language: Language, timezone: string, navbarDictionary: KeyValueRecord<string>, userName?: UserName) => {
  moment.locale(language)
  const currentDateTime = moment.tz(new Date, timezone)
  const currentHour = Number(currentDateTime.format('HH'))

  let greetingLabel = navbarDictionary.goodAfternoon

  if (currentHour < 12) {
    greetingLabel = navbarDictionary.goodMorning
  } else if (currentHour >= 19) {
    greetingLabel = navbarDictionary.goodEvening
  }

  let greetingUserName: string | undefined
  let avatarInitial: string | undefined

  if (userName?.firstName || userName?.fullName) {
    greetingUserName = userName.firstName
    ? userName.firstName.split(' ').shift()
    : userName.fullName.split(' ').shift()

    avatarInitial = greetingUserName?.split('').shift()
  }

  const greetingDate = currentDateTime.format(language === Language.en ? DATETIME_FORMATS.usDateLong : DATETIME_FORMATS.mxDateLong)

  return {
    greetingLabel,
    greetingDate,
    greetingUserName,
    avatarInitial,
  }
}