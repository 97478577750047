import emailjs from '@emailjs/browser'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { LabelSeparator, Row } from 'components/styledComponents'
import { NotificationType } from 'components/utils/enums'
import AccessControl from 'helpers/AccessControl'
import GoogleSignUp from 'helpers/GoogleSignUp'
import isNull from 'lodash/isNull'
import moment from 'moment'
import PageContainer from 'pages/components/PageContainer'
import RegistrationForm from 'pages/Users/SignUp/SignUpForm'
import { SIGNUP_FORM_DEFAULT } from 'pages/utils/constants'
import { SignUpFormError, UserType } from 'pages/utils/enums'
import { SignupForm } from 'pages/utils/types'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showAlert } from 'redux/actions/alert'
import { hideConfirm, showConfirm } from 'redux/actions/confirm'
import { googleSignIn, updateGoogleProfile } from 'redux/actions/session'
import { userSignup } from 'redux/actions/users'
import { ShowAlertParams } from 'redux/utils/alerts.types'
import { ShowConfirmParams } from 'redux/utils/confirm.types'
import { Language, UserSignupResponse } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { GoogleProfileData, GoogleSignInParams } from 'redux/utils/session.types'
import { ReduxStore } from 'redux/utils/types'
import { SignupParams } from 'redux/utils/users.types'
import { pxToRem } from 'theme/typography'
import { encodeContentUtf8 } from 'utils/libs'
import {
  APP_URL,
  EMAIL_JS_GENERAL_TEMPLATE_ID,
  EMAIL_JS_PUBLIC_KEY,
  EMAIL_JS_SERVICE_ID,
  LOGIN_PATH,
  GUEST_MENU_MAP,
  USERS_VALIDATE_EMAIL_PATH,
} from 'utils/constants'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  isConfirmOpen: boolean
  confirmType: NotificationType
  confirmTitle: string
  confirmMessage: string
  confirmPrimaryButtonLabel?: string
  confirmSecondaryButtonLabel?: string
  errorCode?: string
  isUsernameAvailable: boolean
  googleProfile: GoogleProfileData
  userSignupResponse: UserSignupResponse
  userSignupLoading?: boolean
  dispatchGoogleSignIn: (email: string, jsonParams: GoogleSignInParams) => void
  dispatchHideConfirm: () => void
  dispatchShowAlert: (alertProps: ShowAlertParams) => void
  dispatchShowConfirm: (confirmProps: ShowConfirmParams) => void
  dispatchUserSignup: (jsonParams: SignupParams) => void
  dispatchUpdateGoogleProfile: (googleProfile: GoogleProfileData) => void
}

const SignUp: FC<Props> = ({
  dictionary: {
    error: errorDictionary,
    shared: sharedDictionary,
    signUp: signUpDictionary,
    signIn: signInDictionary,
  },
  language,
  isConfirmOpen,
  confirmType,
  confirmTitle,
  confirmMessage,
  confirmPrimaryButtonLabel,
  confirmSecondaryButtonLabel,
  errorCode,
  isUsernameAvailable,
  googleProfile,
  userSignupResponse,
  userSignupLoading,
  dispatchGoogleSignIn,
  dispatchHideConfirm,
  dispatchShowAlert,
  dispatchShowConfirm,
  dispatchUserSignup,
  dispatchUpdateGoogleProfile,
}) => {
  const navigate = useNavigate()
  const { register, setValue } = useForm()
  const formRef = useRef() as MutableRefObject<HTMLFormElement>
  const [signupForm, setSignupForm] = useState<SignupForm>(SIGNUP_FORM_DEFAULT)
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false)
  const [formKey, setFormKey] = useState<Date>(new Date)

  /**
   * Resets the Signup form.
   */
  const handleResetForm = () => {
    // Reset the Signup Form to its Default values.
    setSignupForm(SIGNUP_FORM_DEFAULT)

    // Enable the "Submit" button.
    setDisableSubmitButton(false)

    // Reset the Google Profile data.
    dispatchUpdateGoogleProfile(null)

    // Reset the Form.
    setFormKey(new Date)
  }

  /**
   * Triggers a Success Alert modal.
   * @param message The message to be shown on the Alert modal.
   */
  const handleSuccessMessage = (message: string) => {
    const alertProps: ShowAlertParams = {
      type: NotificationType.success,
      title: sharedDictionary.congratulations,
      message,
      buttonLabel: sharedDictionary.ok,
    }

    // Trigger the "showAlert" action for displaying the Alert modal.
    dispatchShowAlert(alertProps)
  }

  /**
   * Triggers ann Error Alert modal.
   * @param title The title for the Alert modal.
   * @param message The message to be shown on the Alert modal.
   */
   const handleShowErrorMessage = (title: string, message: string) => {
    const alertProps: ShowAlertParams = {
      type: NotificationType.error,
      title,
      message,
      buttonLabel: sharedDictionary.ok,
    }

    // Trigger the "showAlert" action for displaying the Alert modal.
    dispatchShowAlert(alertProps)
  }

  /**
   * Closes the Confirm modal.
   */
  const handleCloseConfirmModal = () => {
    // Clear the "username" field when there was an error.
    if (errorCode) {
      setSignupForm(currentForm => ({
        ...currentForm,
        username: {
          ...currentForm.username,
          value: '',
          valid: false,
          touched: true,
        }
      }))
    }

    // Close the Confirm modal.
    dispatchHideConfirm()
  }

  /**
   * Updates the Signup Form State whenever the "RefistrationForm" component has changed.
   * @param newFormValues The new values to be updated for the "signupForm" State.
   */
  const handleSignupFormChange = (newFormValues: SignupForm) => {
    setSignupForm(newFormValues)
  }

  /**
   * Validates the form and, if no error has been detected, creates the user.
   * @param newFormValues The new values to be updated for the "signupForm" State.
   * @param errorType Specifies if there is any error.
   */
  const handleSignupFormSubmit = (newFormValues: SignupForm, errorType: SignUpFormError) => {
    // Throw an Alert message when the form is incomplete.
    if (errorType === SignUpFormError.incomplete) {
      handleShowErrorMessage(signUpDictionary.formIncompleteDialogTitle, signUpDictionary.formIncompleteDialogContent)
      return
    }
    
    // Throw an Alert message when there is a password mismatch.
    if (errorType === SignUpFormError.passwordsMismatch) {
      handleShowErrorMessage(signUpDictionary.formPasswordMismatchTitle, signUpDictionary.formPasswordMismatchContent)
      return
    }

    // Disable the "Submit" button for not allowing the user to click it while processing.
    setDisableSubmitButton(true)

    // Define the params to be sent to the Signup API.
    const apiParams: SignupParams = {
      userType: newFormValues.userType.value,
      username: newFormValues.username.value,
      password: newFormValues.password.value,
      avatarUrl: googleProfile?.picture,
      name: newFormValues.name.value,
      countryId: newFormValues.countryId.value,
      stateId: newFormValues.stateId.value,
      city: newFormValues.city.value,
      mobile: newFormValues.mobile.value,
      phoneMain: newFormValues.phoneMain.value,
      secondaryEmail: newFormValues.secondaryEmail.value,
    }

    // Set the User Type as "customer" when the User has validated his/her email via Google.
    if (googleProfile?.email === signupForm.username.value) {
      apiParams.userType = UserType.customer
    }

    // Trigger the API for Signing up a user.
    dispatchUserSignup(apiParams)
  }

  /**
   * Sends a Validation Email to the User when he/she is signing-up or when his/her email has not been validated yet.
   * @param email The user's email where to send the validation email.
   * @param recipientName The user's name to whom send the email.
   * @param isSignup Specifies if the email belongs to a signup process.
   */
   const handleSendValidationEmail = (email: string, recipientName: string, isSignup: boolean): void => {
    if (!email) {
      return
    }

    // Set the params to be sent throug the Email Validation URL.
    const emailValidationParams = {
      email,
      recipientName: recipientName || sharedDictionary.dearUser,
      timestamp: moment(),
    }

    // Encode the link to make it safer.
    const encodedLink = encodeContentUtf8(JSON.stringify(emailValidationParams))

    // Set the URL for the Email Validation.
    const emailValidationUrl = `${APP_URL}/${USERS_VALIDATE_EMAIL_PATH[language]}/${encodedLink}`

    const subject = isSignup
      ? signUpDictionary.emailJSSignupSubject
      : signUpDictionary.emailJSValidateEmailSubject
    const greeting = recipientName
      ? `${sharedDictionary.hello} ${recipientName}`
      : sharedDictionary.dearUser
    const body = isSignup
      ? signUpDictionary.emailJSSignupBody
      : signUpDictionary.emailJSValidateEmailBody

    // Update the emailJS params for the email validation.
    setValue('subject', subject)
    setValue('to_email', email)
    setValue('greeting', greeting)
    setValue('email_body', body)
    setValue('button_label', sharedDictionary.validateEmail)
    setValue('email_validation_url', emailValidationUrl)

    // Send the Password Reset email to the user.
    emailjs.sendForm(EMAIL_JS_SERVICE_ID, EMAIL_JS_GENERAL_TEMPLATE_ID, formRef.current, EMAIL_JS_PUBLIC_KEY)
    .then((_result) => {
      // Display a success message.
      handleSuccessMessage(signUpDictionary.emailJSValidateEmailSuccess)

      // Reset the form.
      handleResetForm()
    }, (_error) => {
      // Display an error message.
      handleShowErrorMessage(errorDictionary.somethingWentWrong, signUpDictionary.emailJSValidateEmailError)
    })
  }

  /**
   * Sends a Validation Email to the User since his/her email has not been validated yet.
   * @param email The user's email where to send the validation email.
   * @param recipientName The user's name to whom send the email.
   */
   const handleSendWelcomeEmail = (email: string, recipientName: string): void => {
    if (!email) {
      return
    }

    // Set the params to be sent throug the Email Validation URL.
    const emailValidationParams = { email }

    // Encode the link to make it safer.
    const encodedLink = encodeContentUtf8(JSON.stringify(emailValidationParams))

    // Set the URL for the Login.
    const loginUrl = `${APP_URL}/${LOGIN_PATH[language]}/${encodedLink}`

    // Update the emailJS params for the email validation.
    setValue('subject', signUpDictionary.emailJSWelcomeSubject)
    setValue('to_email', email)
    setValue('greeting', `${sharedDictionary.hello} ${recipientName}`)
    setValue('email_body', signUpDictionary.emailJSWelcomeBody)
    setValue('button_label', signInDictionary.signIn)
    setValue('email_validation_url', loginUrl)

    // Send the Password Reset email to the user.
    emailjs.sendForm(EMAIL_JS_SERVICE_ID, EMAIL_JS_GENERAL_TEMPLATE_ID, formRef.current, EMAIL_JS_PUBLIC_KEY)
    .then((_result) => {
      // Display a success message.
      handleSuccessMessage(signUpDictionary.emailJSWelcomeSuccess)

      // Reset the form.
      handleResetForm()
    }, (_error) => {
      // Display an error message.
      handleShowErrorMessage(errorDictionary.somethingWentWrong, signUpDictionary.emailJSWelcomeError)
    })
  }

  /**
   * Specifies the action to follow when the "OK" button of the "Confirm Dialog" has been clicked.
   * @param googleUserProfile The Google Profile fetched when the user clicked on the "Prefill form with Google" button.
   * @param errorType The type of the error triggered after the user validation with the Google Profile.
   */
  const handleConfirmPrimaryButtonClick = (googleUserProfile: GoogleProfileData, errorType?: string) => (): void => {
    // Close the Confirm Dialog.
    dispatchHideConfirm()

    switch (errorType) {
      case 'userExists': {
        handleSignInClick(googleUserProfile)
        break
      }
      case 'userNotAllowed': {
        const email = googleUserProfile ? googleUserProfile.email : signupForm.username.value
        const recipientName = googleUserProfile ? googleUserProfile.name : signupForm.name.value

        handleSendValidationEmail(email, recipientName, false)
        break
      }
      default:
    }
  }

  /**
   * Handles the User Login in case his/her account exists and it is a valid one.
   * @param hasGoogleProfile Specifies if the Signin process belongs to a Google Authentication or not.
   */
  const handleSignInClick = (googleUserProfile: GoogleProfileData): void => {
    // Close the Confirm Dialog.
    dispatchHideConfirm()

    if (googleUserProfile) {
      const jsonParams: GoogleSignInParams = {
        avatarUrl: googleUserProfile.picture,
      }

      dispatchGoogleSignIn(googleUserProfile.email, jsonParams)
    } else {
      // Redirect the user to the Login page.
      navigate(GUEST_MENU_MAP(language).signIn)
    }
  }

  useEffect(() => {
    const isNotAvailable = !isNull(isUsernameAvailable) && !isUsernameAvailable

    if (isNotAvailable && errorCode) {
      const confirmProps: ShowConfirmParams = {
        type: NotificationType.warning,
        title: signUpDictionary.existingAccountDialogTitle,
        message: signUpDictionary[errorCode],
        primaryButtonLabel: signUpDictionary[`${errorCode}ButtonLabel`],
      }

      dispatchShowConfirm(confirmProps)
    }
  }, [isUsernameAvailable, errorCode])

  useEffect(() => {
    const email = signupForm.username.value
    const recipientName = signupForm.name.value

    if (userSignupResponse === UserSignupResponse.registeredButNotValidated) {
      handleSendValidationEmail(email, recipientName, true)
    } else if (userSignupResponse === UserSignupResponse.registeredAndValidated) {
      handleSendWelcomeEmail(email, recipientName)
    }
  }, [userSignupResponse])

  return (
    <PageContainer>
      <Row isColumnDirection>
        <GoogleSignUp />
        <LabelSeparator>{sharedDictionary.or}</LabelSeparator>
      </Row>
      <RegistrationForm
        key={formKey.toString()}
        formValues={signupForm}
        disableSubmitButton={userSignupLoading || disableSubmitButton}
        onChange={handleSignupFormChange}
        onSubmit={handleSignupFormSubmit}
      />
      {isConfirmOpen && (
        <ConfirmDialog
          isOpen={isConfirmOpen}
          title={confirmTitle}
          type={confirmType}
          message={confirmMessage}
          primaryButtonLabel={confirmPrimaryButtonLabel}
          onPrimaryButtonClick={handleConfirmPrimaryButtonClick(googleProfile, errorCode)}
          secondaryButtonLabel={confirmSecondaryButtonLabel}
          onSecondaryButtonClick={handleCloseConfirmModal}
          onClose={handleCloseConfirmModal}
          width={{ xs: pxToRem(240), sm: pxToRem(320), md: pxToRem(500) }}
          useTransition
          closeable
        />
      )}
      <form ref={formRef} style={{ display: 'none' }}>
        <input value="" {...register('subject')} />
        <input value="" {...register('to_email')} />
        <input value="" {...register('greeting')} />
        <input value="" {...register('email_body')} />
        <input value="" {...register('button_label')} />
        <input value={sharedDictionary.emailJSClosing} {...register('email_closing')} />
        <input value={sharedDictionary.emailJSSignature} {...register('email_signature')} />
        <input value="" {...register('email_validation_url')} />
        <input value="" {...register('reply_to')} />
        <input type="submit" />
      </form>
    </PageContainer>
  )
}

const mapStateToProps = ({ confirmStore, languageStore, sessionStore, usersStore }: ReduxStore) => {
  const {
    isOpen: isConfirmOpen,
    type: confirmType,
    title: confirmTitle,
    message: confirmMessage,
    primaryButtonLabel: confirmPrimaryButtonLabel,
    secondaryButtonLabel: confirmSecondaryButtonLabel,
  } = confirmStore
  const { dictionary, language } = languageStore
  const { googleProfile } = sessionStore
  const { isUsernameAvailable, errorCode, userSignupResponse, userSignupLoading } = usersStore

  return {
    dictionary,
    language,
    isConfirmOpen,
    confirmType,
    confirmTitle,
    confirmMessage,
    confirmPrimaryButtonLabel,
    confirmSecondaryButtonLabel,
    errorCode,
    isUsernameAvailable,
    googleProfile,
    userSignupResponse,
    userSignupLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGoogleSignIn: (email: string, jsonParams: GoogleSignInParams) => dispatch(googleSignIn(email, jsonParams)),
  dispatchHideConfirm: () => dispatch(hideConfirm()),
  dispatchShowAlert: (alertProps: ShowAlertParams) => dispatch(showAlert(alertProps)),
  dispatchShowConfirm: (confirmProps: ShowConfirmParams) => dispatch(showConfirm(confirmProps)),
  dispatchUpdateGoogleProfile: (googleProfile: GoogleProfileData) => dispatch(updateGoogleProfile(googleProfile)),
  dispatchUserSignup: (jsonParams: SignupParams) => dispatch(userSignup(jsonParams)),
})

const ConnectedSignUp: any = AccessControl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUp))

export default ConnectedSignUp