import { alpha, Theme } from '@mui/material/styles'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function Grid(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiGrid2: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: AlignItems.center,
          justifyContent: JustifyContent.start,
          minHeight: 'auto',
          [breakpoints.up('md')]: {
            minHeight: pxToRem(40),
          },
          '.v-start': {
            alignItems: AlignItems.start,
          },
          '.v-end': {
            alignItems: AlignItems.end,
          },
          '.h-center': {
            justifyContent: JustifyContent.center,
          },
          '.h-end': {
            justifyContent: JustifyContent.end,
          },
          '&.table-row': {
            marginTop: 0,
            padding: `${pxToRem(4)} ${pxToRem(4)}`,
            width: '100%',
            minHeight: 'auto',
            '& > *': {
              minHeight: 'auto',
            },
            '&:nth-of-type(odd)': {
              backgroundColor: alpha(palette.blue.light as string, 0.3),
            },
            '&:nth-of-type(even)': {
              backgroundColor: alpha(palette.blue.light as string, 0.5),
            },
            '& .money': {
              fontFamily: 'B612 Mono',
              fontSize: pxToRem(12),
              [breakpoints.up('md')]: {
                fontSize: pxToRem(14),
              },
            },
          },
        },
      },
    },
  }
}