import { Drawer } from '@mui/material'
import Logo from 'components/Navigation/Logo'
import {
  GreetingMainContainer,
  GreetingUserDataContainer,
  StyledAvatar,
  UsersMenuLogoContainer,
  UsersMenuOptionsContainer,
} from 'components/Navigation/styledComponents'
import UsersMenuOptions from 'components/Navigation/UsersMenu/UsersMenuOptions'
import { LabelWithoutMargin } from 'components/styledComponents'
import { DeviceVisibility } from 'components/utils/enums'
import { useGetGreeting } from 'hooks'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { stringToColor } from 'utils/libs'
import { UserName } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  isSignedIn: boolean
  userAvatar?: string
  userFirstName: string | null
  userLastName: string | null
  userFullName: string
  timezone: string
}

const UsersMenu: FC<Props> = ({
  dictionary: { navbar: navbarDictionary },
  language,
  isSignedIn,
  userAvatar,
  userFirstName,
  userLastName,
  userFullName,
  timezone,
}) => {
  const userName: UserName = {
    firstName: userFirstName,
    lastName: userLastName,
    fullName: userFullName,
  }
  const { greetingLabel, greetingUserName, avatarInitial } = useGetGreeting(language, timezone, navbarDictionary, userName)

  if (!isSignedIn) {
    return null
  }

  return (
    <>
      <Drawer open variant="persistent" className="users-menu desktop">
        <UsersMenuLogoContainer>
          <Logo />
        </UsersMenuLogoContainer>
        <GreetingMainContainer>
          <StyledAvatar src={userAvatar} backgroundColor={stringToColor(userFullName)}>
            {avatarInitial}
          </StyledAvatar>
          <GreetingUserDataContainer>
            <LabelWithoutMargin variant="subtitle2">
              {greetingLabel}
            </LabelWithoutMargin>
            <LabelWithoutMargin variant="body2">
              {greetingUserName}
            </LabelWithoutMargin>
          </GreetingUserDataContainer>
        </GreetingMainContainer>
        <UsersMenuOptionsContainer>
          <UsersMenuOptions device={DeviceVisibility.desktop} />
        </UsersMenuOptionsContainer>
      </Drawer>
    </>
  )
}

const mapStateToProps = ({ languageStore, sessionStore, timezoneStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { isSignedIn, sessionData } = sessionStore
  const { timezone } = timezoneStore

  const {
    userAvatar,
    userFirstName,
    userLastName,
    userFullName,
  } = sessionData || {}

  return {
    dictionary,
    language,
    isSignedIn,
    userAvatar,
    userFirstName,
    userLastName,
    userFullName,
    timezone,
  }
}

export default connect(
  mapStateToProps
)(UsersMenu)