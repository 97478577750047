import { Row } from 'components/styledComponents'
import { getBreakpointValue } from 'components/utils/libs'
import { RowProps } from 'components/utils/types'
import { useCurrentBreakpoint } from 'hooks'
import React, { FC, ReactNode } from 'react'
import { Breakpoint } from 'utils/enums'

type Props = RowProps & {
  children: ReactNode
}

export const DynamicRow: FC<Props> = (
  {
    marginTop,
    marginBottom,
    width,
    alignItems,
    justifyContent,
    isColumnDirection,
    groupedByColumns,
    groupedByTemplate,
    gridGap,
    gridRowGap,
    gridColumnGap,
    children,
  }
) => {
  const currentBreakPoint: Breakpoint = useCurrentBreakpoint()
  const columns: number | undefined = getBreakpointValue(currentBreakPoint, groupedByColumns)

  return (
    <Row
      marginTop={marginTop}
      marginBottom={marginBottom}
      width={width}
      alignItems={alignItems}
      justifyContent={justifyContent}
      isColumnDirection={isColumnDirection}
      groupedByColumns={columns}
      groupedByTemplate={groupedByTemplate}
      gridGap={gridGap}
      gridRowGap={gridRowGap}
      gridColumnGap={gridColumnGap}
    >
      {children}
    </Row>
  )
}