import { useTheme } from '@mui/material/styles'
import { GlobalStyles as GlobalThemeStyles } from '@mui/material'
import React from 'react'
import { pxToRem } from 'theme/typography'

const GlobalStyles = () => {
  const { palette } = useTheme()

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          marginBottom: pxToRem(16),
          padding: 0,
          fontFamily: ['Open Sans', 'sans-serif'],
          fontSize: pxToRem(14),
          fontWeight: 400,
          textAlign: 'justify',
          overflowY: 'scroll',
          height: '100%',
        },
        b: {
          fontWeight: 500,
        },
        '#react': {
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        a: {
          textDecoration: 'none',
          fontWeight: 500,
          '&:active': {
            textDecoration: 'none',
            fontWeight: 500,
          },
          '&:focus': {
            textDecoration: 'none',
            fontWeight: 500,
          },
          '&:hover': {
            textDecoration: 'none',
            fontWeight: 500,
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        br: {
          display: 'block',
          marginBottom: pxToRem(8),
          content: '""',
        },
        p: {
          marginBottom: pxToRem(8),
          textAlign: 'justify',
        },
        ol: {
          marginLeft: pxToRem(32),
          marginBottom: pxToRem(8),
        },
        ul: {
          marginLeft: pxToRem(32),
          marginBottom: pxToRem(16),
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
        li: {
          marginBottom: pxToRem(4),
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: palette.text.disabled,
          },
          '&::-moz-placeolder': {
            opacity: 1,
            color: palette.text.disabled,
          },
          '&::-ms-input-placeholder': {
            color: palette.text.disabled,
          },
          '&::placeholder': {
            color: palette.text.disabled,
          },
        },
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms',
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)',
        },
      }}
    />
  )
}

export default GlobalStyles