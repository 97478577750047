import { Row } from 'components/styledComponents'
import { useCurrentDevice } from 'hooks'
import Thumbnail from 'pages/Machinery/Thumbnail'
import { getMachineSocialMediaData } from 'pages/Machinery/utils/libs'
import { MachineThumbnail } from 'pages/Machinery/utils/types'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { MachinesResponse } from 'redux/utils/machinery.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  loading?: boolean
  machinesList: MachinesResponse[]
}

const UnconnectedThumbailList: FC<Props> = ({
  dictionary: { machinery: machineryDictionary },
  language,
  loading,
  machinesList,
}) => {
  const { isDesktop, isMobile, isTablet } = useCurrentDevice()
  const areMachinesAvailable: boolean = !loading && !!machinesList.length
  const thumbnailListArray: MachinesResponse[] | any[] = areMachinesAvailable
    ? machinesList
    : new Array(12).fill('')

  let columns = 1

  if (isTablet) {
    columns = 2
  } else if (isDesktop) {
    columns = 3
  }

  return (
    <>
      <Row marginTop={pxToRem(16)} groupedByColumns={columns} gridGap={24}>
        {thumbnailListArray.map((machine, index) => {
          const {
            category = '',
            manufacturer = '',
            model = '',
            reference = '',
            year = 0,
            hours = 0,
            price = 0,
            country = '',
            state = '',
            machineContact = '',
            machineContactPhone1 = '',
            machineContactPhone2 = '',
            contactMobile = '',
            machineContactEmail = '',
            description = '',
            photo = '',
            photos = [],
          } = machine || {}

          const {
            machineDetailsURL,
            contactInfoMessage,
          } = getMachineSocialMediaData(language, machineryDictionary, isMobile, category, manufacturer, model, reference)

          const machineThumbnail: MachineThumbnail | undefined = areMachinesAvailable 
            ? {
                category,
                manufacturer,
                model,
                reference,
                year,
                hours,
                price,
                country,
                state,
                machineContact,
                machineContactPhone1,
                machineContactPhone2,
                contactMobile,
                machineContactEmail,
                description,
                photo,
                photos,
                machineDetailsURL,
                contactInfoMessage,
              }
            : undefined

          return (
            <Row key={reference || `machine-${index}`}>
              <Thumbnail machine={machineThumbnail} />
            </Row>
          )
        })}
      </Row>
    </>
  )
}

const mapStateToProps = ({ languageStore, machineryStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { machines, machinesLoading } = machineryStore
  const { machinesList = [] } = machines || {}

  return {
    dictionary,
    language,
    loading: machinesLoading,
    machinesList,
  }
}

const ThumbnailList = connect(
  mapStateToProps,
)(UnconnectedThumbailList)

export default ThumbnailList
