import { Theme } from '@mui/material/styles'
import { merge } from 'lodash'
import Accordion from 'theme/overrides/Accordion'
import Alert from 'theme/overrides/Alert'
import AppBar from 'theme/overrides/AppBar'
import Autocomplete from 'theme/overrides/Autocomplete'
import Avatar from 'theme/overrides/Avatar'
import BottomNavigation from 'theme/overrides/BottomNavigation'
import Box from 'theme/overrides/Box'
import Breadcrumbs from 'theme/overrides/Breadcrumbs'
import Button from 'theme/overrides/Button'
import Card from 'theme/overrides/Card'
import CircularProgress from 'theme/overrides/CircularProgress'
import Dialog from 'theme/overrides/Dialog'
import Drawer from 'theme/overrides/Drawer'
import FormControl from 'theme/overrides/FormControl'
import FormControlLabel from 'theme/overrides/FormControlLabel'
import Grid from 'theme/overrides/Grid'
import Link from 'theme/overrides/Link'
import List from 'theme/overrides/List'
import ListItem from 'theme/overrides/ListItem'
import Menu from 'theme/overrides/Menu'
import MobileStepper from 'theme/overrides/MobileStepper'
import Pagination from 'theme/overrides/Pagination'
import Paper from 'theme/overrides/Paper'
import Popper from 'theme/overrides/Popper'
import Select from 'theme/overrides/Select'
import Switch from 'theme/overrides/Switch'
import TableContainer from 'theme/overrides/TableContainer'
import Tabs from 'theme/overrides/Tabs'
import TextField from 'theme/overrides/TextField'
import Typography from 'theme/overrides/Typography'

export default function ComponentOverrides(theme: Theme) {
  return merge(
    Accordion(theme),
    Alert(),
    AppBar(theme),
    Autocomplete(theme),
    Avatar(theme),
    BottomNavigation(theme),
    Box(),
    Breadcrumbs(theme),
    Button(theme),
    Card(theme),
    CircularProgress(theme),
    Dialog(theme),
    Drawer(theme),
    FormControl(theme),
    FormControlLabel(theme),
    Grid(theme),
    Link(theme),
    List(),
    ListItem(theme),
    Menu(theme),
    MobileStepper(),
    Pagination(theme),
    Paper(theme),
    Popper(theme),
    Select(theme),
    Switch(theme),
    TableContainer(theme),
    Tabs(theme),
    TextField(theme),
    Typography(theme),
  )
}