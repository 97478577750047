import { NotificationType } from 'components/utils/enums'
import { dictionaryList, languageLabel } from 'languages'
import { AlertState } from 'redux/utils/alerts.types'
import { ConfirmState } from 'redux/utils/confirm.types'
import { CountriesState } from 'redux/utils/countries.types'
import { Language, UserSignupResponse } from 'redux/utils/enums'
import { LanguageState } from 'redux/utils/language.types'
import { LocationState } from 'redux/utils/location.types'
import { MachineryState } from 'redux/utils/machinery.types'
import { QuotationState } from 'redux/utils/quotation.types'
import { RequestsState } from 'redux/utils/requests.types'
import { SessionState, StoredSession } from 'redux/utils/session.types'
import { StatesState } from 'redux/utils/states.types'
import { TimezoneState } from 'redux/utils/timezone.types'
import { UsersState } from 'redux/utils/users.types'
import { TABLE_ITEMS_PER_PAGE } from 'utils/constants'

export const ALERT_INITIAL_STATE: AlertState = {
  isOpen: false,
  type: NotificationType.info,
  title: '',
  message: '',
}

export const CONFIRM_INITIAL_STATE: ConfirmState = {
  isOpen: false,
  type: NotificationType.info,
  title: '',
  message: '',
}

export const LANGUAGE_INITIAL_STATE: LanguageState = {
  language: Language.es,
  dictionary: dictionaryList[Language.es],
  label: languageLabel[Language.es],
}

export const LOCATION_INITIAL_STATE: LocationState = {} as LocationState

export const COUNTRIES_INITIAL_STATE: CountriesState = {}

export const STATES_INITIAL_STATE: StatesState = {}

export const MACHINERY_INITIAL_STATE: MachineryState = {}

export const QUOTATION_INITIAL_STATE: QuotationState = {}

export const REQUESTS_INITIAL_STATE: RequestsState = {
  currentPage: 1,
  itemsPerPage: TABLE_ITEMS_PER_PAGE,
  totalRequests: 0,
  totalPages: 0,
  requests: [],
}

export const TIMEZONE_INITIAL_STATE: TimezoneState = {
  timezone: 'America/Cancun',
}

export const SESSION_INITIAL_STATE: SessionState = {
  isSignedIn: false,
  token: null,
  sessionData: null,
  googleProfile: null,
  isPasswordResetSuccessful: null,
}

export const STORED_SESSION_INITIAL_STATE: StoredSession = {
  isSignedIn: false,
  sessionData: null,
  token: null,
}

export const USERS_INITIAL_STATE: UsersState = {
  isUsernameAvailable: null,
  userSignupResponse: UserSignupResponse.unset,
  isEmailValidated: false,
}