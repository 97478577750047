import { LanguageDictionary } from 'redux/utils/language.types'
import { DATETIME_FORMATS } from 'utils/constants'

const es: LanguageDictionary = {
  shared: {
    add: 'Agregar',
    ascending: 'Ascendente',
    back: 'Atrás',
    cancel: 'Cancelar',
    close: 'Cerrar',
    confirm: 'Confirmar',
    congratulations: '¡Felicidades!',
    create: 'Crear',
    createMyAccount: 'Crear mi Cuenta',
    date: 'Fecha',
    dateRangePicker: 'Seleccione rango de fecha',
    dateRangeSeparatorLabel: '-',
    day: 'día',
    days: 'días',
    dearUser: 'Estimado Usuario',
    delete: 'Borrar',
    descending: 'Descendente',
    download: 'Descargar',
    downloadData: 'Descargar Info',
    edit: 'Editar',
    emailJSSignature: 'Atentamente',
    emailJSClosing: 'Agradecemos de antemano su interés y estamos a sus órdenes.',
    endDate: 'Fecha Final',
    filter: 'Filtrar',
    forgotPassword: 'Olvidé Contraseña',
    generatePassword: 'Generar Contraseña',
    goToHomePage: 'Ir a la página de Inicio',
    goToSignInPage: 'Ir a página de Iniciar Sesión',
    hello: 'Hola',
    hidePassword: 'Ocultar Contraseña',
    hour: 'hora',
    hours: 'horas',
    loading: 'Cargando',
    minute: 'minuto',
    minutes: 'minutos',
    next: 'Siguiente',
    no: 'No',
    notDefined: 'No Definido',
    ok: 'OK',
    or: 'o',
    paginationBlockData: 'Mostrando {XX}-{YY} de {ZZ} registros',
    paginationRowsPerPage: 'Filas por página',
    prev: 'Anterior',
    resetPassword: 'Reestablecer Contraseña',
    save: 'Guardar',
    saveChanges: 'Guardar Cambios',
    second: 'segundo',
    seconds: 'segundos',
    seeDetails: 'Ver Detalles',
    sendEmail: 'Enviar Email',
    showAll: 'Ver Todos',
    showPassword: 'Mostrar Contraseña',
    startDate: 'Fecha Inicial',
    submit: 'Enviar',
    tableSummary: 'Registro: {XX} al {YY} de {ZZ}',
    validateEmail: 'Validar Correo Electrónico',
    validatingCode: 'Estamos validando su código. Por favor, espere un momento.',
    view: 'Ver',
    week: 'semana',
    weeks: 'semanas',
    year: 'año',
    yes: 'Sí',
  },
  error: {
    actionDenied: 'Lo sentimos, pero no tienes la facultad para realizar esta acción.',
    crendentialsMismatch: '¡Sus credenciales no coinciden! Por favor intente nuevamente.',
    expiredToken: 'Lo sentimos, pero su sesión ha expirado. Por favor re-inicie su sesión.',
    expiredValidationCode: 'Sin embargo, el código de validación ha expirado.',
    futureDateError: 'no puede ser posterior al día actual',
    generalError: 'Se presentó un error al realizar esta acción. Por favor intente nuevamente.',
    inactiveUser: 'Lo sentimos, pero su cuenta ha sido desactivada.',
    invalidCategory: 'no es un Tipo válido.',
    invalidDateError: 'no es una fecha válida',
    invalidManufacturer: 'no es una Marca válida.',
    invalidModel: 'no es un Modelo válido.',
    invalidPhoneNumber: '¡Este formato de número telefónico es inválido!',
    invalidToken: 'Lo sentimos, pero no está autorizado para acceder a esta página.',
    invalidValidationCode: 'Sin embargo, el código de validación es inválido. Por favor, verifique el enlace en su correo electrónico e inténtelo nuevamente.',
    maxDateError: 'no puede ser después del {DATE}',
    minDateError: 'no puede ser antes del {DATE}',
    missingCredentials: '¡Necesita proporcionar su nombre de usuario y contraseña!',
    missingEmail: '¡Necesita proporcionar un Email!',
    multipleEmailFormat: '¡Uno o más correos electrónicos no tienen un formato válido!',
    noData: 'No se encontraron registros',
    noDataForFilters: 'No existen registros que coincidan con los filtros proporcionados',
    onlyEmail: 'El campo debe ser un Email',
    onlyLettersAndSpaces: 'Sólo se permiten letras y números',
    pageDenied: 'Lo sentimos, pero no tiene permiso para acceder a esta página.',
    pastDateError: 'no puede ser anterior al día actual',
    requiredValue: '¡Este campo es obligatorio!',
    singleEmailFormat: '¡Este formato de correo electrónico es inválido!',
    somethingWentWrong: 'Algo salió mal',
    unexistentValidationCode: 'Sin embargo, necesita proporcionar un código de validación para poder continuar.',
    urlFormat: '¡Este formato de Sitio Web es inválido!',
    userLogout: '¡Ocurrión un error al intentar cerrar su sesión!',
    userNotFound: 'Lo sentimos, pero el nombre de usuario proporcionado no existe. Favor de revisar e intente nuevamente.',
  },
  navbar: {
    aboutUs: 'Nosotros',
    account: 'Cuenta',
    advertiseHere: 'Anúnciate Aquí',
    catalog: 'Catálogo',
    faq: 'Preguntas Frecuentes',
    goodAfternoon: 'Buenas tardes',
    goodEvening: 'Buenas noches',
    goodMorning: 'Buenos días',
    help: 'Ayuda',
    home: 'Inicio',
    machinery: 'Maquinaria',
    messages: 'Mensajes',
    myMachinery: 'Mi Maquinaria',
    publishMachine: 'Publicar Máquina',
    request: 'Solicitud',
    requests: 'Solicitudes',
    search: 'Búsqueda',
    signIn: 'Iniciar Sesión',
    signOut: 'Cerrar Sesión',
    signUp: 'Registrarse',
    switchLanguage: 'English',
    users: 'Usuarios',
    usersHome: 'Inicio',
  },
  footer: {
    label: 'IMCMEXICO es una marca registrada',
  },
  bottomNavigator: {
    usersHome: 'Inicio',
    requests: 'Solicitudes',
    messages: 'Mensajes',
    account: 'Cuenta',
  },
  comingSoon: {
    headerTitle: 'Próximamente',
    pageTitle: 'Proximamente',
    pageDescription: 'Estamos trabajando muy duro para ofrecerle lo mejor. Próximamente tendremos algo sorprendente para usted.',
  },
  home: {
    content: `<p>Para consultar y cotizar rápidamente cualquier oportunidad de maquinaria usada de los Estados Unidos de América. Equipe su obra siguiedo estos tres sencillos pasos:</p>
      <ol>
        <li>
          Encuentre y cotice instantáneamente la máquina que desee desde nuestro portal, a través de nuestro módulo <b>Maquinaria</b>.
        </li>
        <li>
          Lo esperamos en USA para inspeccionar la máquina de su interés. O si lo prefiere, la inspeccionamos por usted.
        </li>
        <li>
          Pague su máquina y recíbala en su puerta u obra en menos de 5 días. Totalmente legalizada y facturada.
        </li>
      </ol>`,
    headerTitle: 'Venta de máquinas para construcción usadas',
    pageTitle: 'Bienvenido(a) a IMCMEXICO',
    pageDescription: 'Somos líderes en importación y venta de maquinaria usada para la construcción. retroexcavadoras, compactadoras, excavadoras, motoconformadoras.',
  },
  machinery: {
    category: 'Tipo',
    categoryPlaceholder: 'Seleccione un Tipo',
    contactEmail: 'Email',
    contactName: 'Contacto',
    contactPhoneCell: 'Celular',
    contactPhoneMain: 'Tel. Principal',
    contactPhoneSecondary: 'Tel. Secundario',
    description: 'Descripción',
    errorWhileFetchingMachinesList: 'Error al obtener el listado de Máquinas',
    errorWhileFetchingMachineDetails: 'Error al obtener Detalles de la Máquina',
    filter: 'Filtrar',
    headerTitle: 'Catálogo de Maquinaria para Construcción',
    hours: 'Horas',
    includeRecentlySold: 'Incluir Máquinas Vendidas Recientemete',
    invalidCategory: 'El Tipo es inválido',
    invalidManufacturer: 'La Marca es inválida',
    invalidModel: 'El Modelo es inválido',
    invalidReference: 'La Referencia es inválida',
    machineAvailable: 'Máquina Disponible',
    machineLocation: 'Ubicación',
    machineNotFound: 'No se encontró ninguna coincidencia',
    machineValidate: 'Verificar Disponibilidad',
    manufacturer: 'Marca',
    manufacturerPlaceholder: 'Seleccione una Marca',
    model: 'Modelo',
    modelPlaceholder: 'Seleccione un Modelo',
    na: 'No Disponible',
    nomenclature: 'Nomenclatura',
    otherCategories: 'Otros Tipos',
    otherManufacturers: 'Otras Marcas',
    otherModels: 'Otros Modelos',
    pageTitle: 'Catálogo de Maquinaria para Construcción',
    pageTitleDetails: 'Ficha Técnica',
    pageDescriptionMain: 'Catálogo actualizado de Maquinaria para Construcción disponible. Cotización instantanea de maquinaria usada clasificada por tipo, marca y modelo.',
    pageDescriptionFilters: 'Ponemos a su disposición {FILTERS}',
    pageDescriptionDetails: 'Detalles de la máquina {MACHINE} con numero de referencia {REFERENCE}',
    quotation: 'Cotización',
    quotationDescription: 'Seleccione el destino y plataforma deseada para trasladar su equipo y obtenga en instantes una cotización preliminar.',
    quotationDestination: 'Destino',
    quotationDestinationToMEX: 'Envíos a México',
    quotationDestinationToUSA: 'Envíos a América del Sur',
    quotationFee: 'Honorarios',
    quotationFreightMEX: 'Flete en México',
    quotationFreightUSA: 'Flete en USA',
    quotationHandling: 'Maniobras',
    quotationNote: 'Cotización aproximada y válida principalmente para máquinas no sobredimensionadas y de una sola pieza. Precio o beneficios aún negociables.',
    quotationOrigin: 'Origen',
    quotationPlatformFlat: 'Flat (hasta 10 ton.)',
    quotationPlatformLowboy: 'LowBoy (hasta 40 ton.)',
    quotationPlatformSemi: 'Semi (hasta 20 ton.)',
    quotationPlatformType: 'Plataforma',
    quotationPrice: 'Precio de la Máquina',
    quotationShippingInsurance: 'Seguro de Traslado',
    quotationTitle: 'Cotice esta máquina hasta su destino',
    quotationTotal: 'Total',
    reference: 'Referencia',
    referencePlaceholder: 'Proporcione una Referencia',
    requestMessage: 'Hola, ¿podría darme información sobre la siguiente máquina?',
    sharingMessage: 'Hola, me gustaría compartirte información sobre esta máquina:',
    showAllMachines: 'Global',
    showOnlyMexico: 'Sólo en México',
    sortByPrice: 'Ordernar por Precio Ascendente',
    thumbnailActionPDF: 'Descargar PDF',
    thumbnailActionRequest: 'Solicitar información',
    thumbnailActionShare: 'Compartir Ficha',
    thumbnailActionViewDetails: 'Ver Ficha',
    topCategories: 'Tipos Destacados',
    topManufacturers: 'Marcas Destacadas',
    topModels: 'Modelos Destacados',
    viewDetails: 'Ver Ficha',
    year: 'Año',
  },
  machineryPDF: {
    availability: 'Disponibilidad',
    dateFormat: `${DATETIME_FORMATS.mxDateFull} [a las] ${DATETIME_FORMATS.time12Hours}`,
    preavailability: 'Predisponibilidad',
    purchaseProject: 'Proyecto de Compra',
    subtitle: 'Ficha Técnica generada el ',
    title: 'Relación de Gestión y Cotización de Maquinaria',
    technicalBrocure: 'Ficha Técnica',
  },
  aboutUs: {
    advantagesTitle: 'Ventajas',
    advantagesContent: `<p>IMCMEXICO cuenta con más de 200 convenios suscritos con proveedores, principalmente de USA y Canadá que promocionan sus inventarios en nuestro sistema informático con lo que logramos cubrir la mayoría de los requerimientos de maquinaria usada principalmente para empresas de la construcción mexicana, y restos de países de Latinoamérica.</p>
      <p>Usted puede navegar libremente en el sistema sin necesidad de teclear su nombre de usuario y clave, con lo cual encontrará cotizaciones preliminares de toda la maquinaria usada para la construcción, que proveedores nacionales y del extranjero ponen a disposición para renta o venta, así como información técnica, localización e imágenes que se publican.</p>
      <p>Formar parte de los usuarios registrados de IMCMEXICO no tiene ningún costo, es completamente gratis, y sólo tiene que llenar el Formulario de Registro de Usuarios.</p>
      <p>Al formar parte de los usuarios registrados de IMCMEXICO usted tendrá acceso a los servicios siguientes:</p>
      <ul>
        <li>
          Adquisición de Maquinaria para la Construcción de la mejor calidad localizada en Estados Unidos, Canadá y resto del Mundo.
        </li>
        <li>
          Compra de Maquinaria localizada en México.
        </li>
        <li>
          Renta de Maquinaria localizada en México.
        </li>
        <li>
          Compra de Refacciones y Accesorios en México.
        </li>
        <li>
          Compra de Refacciones y Accesorios localizados en Estados Unidos, Canadá y resto del Mundo.
        </li>
      </ul>
      <p>Además, y como valor agregado para su beneficio, usted podrá realizar diversas operaciones en los módulos de Ingeniería, Maquinaria y Construcción, desde el Panel de Control, entre ellas las siguientes:</p>
      <h4>MAQUINARIA</h4>
      <ul>
        <li>
          Venta y Renta de Maquinaria pudiendo utilizar su propia imagen corporativa.
        </li>
        <li>
          Puesta a disposición de los productos y servicios proporcionados por su empresa pudiendo emplear su propia imagen corporativa.
        </li>
        <li>
          Control total sobre toda la información de su maquinaria, productos y servicios, pudiendo en todo momento decidir si dichos activos son publicados, o si los desea tener en su inventario particular.
        </li>
        <li>
          Gestión directa de las transacciones por concepto de Venta, Renta y prestación de servicios.
        </li>
        <li>
          Delegación de gestión sobre sus productos a IMCMEXICO, con lo cual no tiene que preocuparse en establecer ningún contacto.
        </li>
      </ul>
      <h4>INGENIERÍA</h4>
      <ul>
        <li>
          Publicación de su Semblanza Técnica.
        </li>
        <li>
          Consulta y contacto directo de profesionales y técnicos inscritos en el directorio de semblanzas técnicas publicadas.
        </li>
        <li>
          Publicación de sus Productos de Ingeniería.
        </li>
        <li>
          Publicación de sus Servicios de Ingeniería.
        </li>
        <li>
          Adquirir Servicios de Ingeniería.
        </li>
      </ul>
      <h4>CONSTRUCCIÓN</h4>
      <ul>
        <li>
          Publicación de sus Productos de Construcción.
        </li>
        <li>
          Publicación de sus Servicios de Construcción.
        </li>
        <li>
          Publicación de la Imagen Corporativa de su Empresa.
        </li>
        <li>
          Búsqueda personalizada de Productos y Servicios para la Construcción.
        </li>
      </ul>`,
    content: 'Contamos también con un rubro de preguntas frecuentes para cada módulo, el cual está diseñado para aclarar cualquier duda que usted pueda tener y, si usted no encuentra la respuesta a sus preguntas, no dude en contactarnos vía correo electrónico o telefónicamente, IMCMEXICO lo asesorará en cualquier momento que usted lo requiera.',
    headerTitle: 'Nosotros',
    missionTitle: 'Misión',
    missionContent: `<p>Realizamos servicios de asesoría, inspección, evaluación de máquinas y equipos para la construcción y rehabilitación de obras geohidrotécnicas, hidráulicas, terrestres, hidráulica ambiental, forestal, trituración y de perforación.</p>
      <p>Gestionamos actividades de compra y venta, traslado y trámites aduaneros de maquinaria de acuerdo a las necesidades de despacho que solicite el cliente, así como la gestión completa de importación y exportación de maquinaria con entrega en obra, o en puertos fronterizos de Estados Unidos de Norteamérica.</p>
      <p>Le apoyamos en el rastreo de la mejor oportunidad. La máquina que no encuentre en nuestro portal de IMCMEXICO se la conseguimos al precio más bajo posible del mercado internacional.</p>
      <ul>
        <li>
          Elija opciones entre casi 35,000 máquinas en este portal.
        </li>
        <li>
          En promedio nuestros precios resultan hasta 20% más baratos, porque no pagamos intermediarios y lotes.
        </li>
        <li>
          Todas las máquinas de USA que requieran ser entregadas en puertos americanos se entregan en menos de 72 horas.
        </li>
        <li>
          Todas las máquinas tienen refacciones originales y son inspeccionadas antes de adquirirse.
        </li>
        <li>
          Si requiere solo el servicio de importación, le llevamos su máquina hasta su obra al mejor precio.
        </li>
        <li>
          Si vio alguna máquina en otro lugar, nosotros nos encargamos de toda la gestión de traslado al lugar que nos indique.
        </li>
        <li>
          Somos su mejor opción porque en menos de diez años hemos vendido más de 1,900 máquinas a más de 600 constructores de Latinoamérica
        </li>
      </ul>`,
    pageTitle: 'Acerca de IMCMEXICO',
    pageDescription: 'IMCMEXICO es una empresa líder en la industria de la venta de maquinaria usada para construcción. Conózcanos, nuestra reputación y trayectoria nos avalan.',
  },
  faq: {
    answer01: 'IMCMEXICO abarca toda la industria de la construcción para empresas y especialistas en México, Estados Unidos, Canadá y el resto del mundo',
    answer02: 'Nuestro único compromiso con las empresas de Estados Unidos, Canadá y el resto del mundo es ofrecer sus productos y servicios a todo el mercado del sector de la construcción en México a través de nuestro portal.',
    answer03: 'Las empresas de Estados Unidos, Canadá y resto del mundo que se anuncian en IMCMEXICO, son empresas de talla internacional, que están comprometidas a brindar sus productos y servicios a todo el sector de la construcción.',
    answer04: 'La estabilidad económica actual en Latinoamérica, permite que se realicen más construcciones, especialmente en caminos y grandes construcciones y porque en Latinoamérica casi no existen fábricas de maquinaria pesada.',
    answer05: 'En IMCMEXICO día con día mejoramos nuestro sistema informático e invertimos cada vez más recursos para la promoción de maquinaria pesada de nuestros proveedores a través de nuestra página Web y de esta forma cubrir la mayoría de las empresas de la construcción.',
    answer06: 'Sí, los precios que manejamos en nuestro portal, son precios de lista, que pueden variar para su beneficio dependiendo del acuerdo al que se llegue con el vendedor, en el momento que usted decida adquirir el equipo.',
    answer07: 'Las promociones estarán vigentes durante todo el año.',
    answer08: 'Si usted necesita una máquina que no se encuentre en nuestro portal, sólo tiene que dar clic en Solicitudes, que aparece en el panel izquierdo, proceder a llenarla y nosotros nos encargaremos de conseguirla.',
    answer09: 'Porque nuestros clientes cada vez requieren más máquinas y necesitan medios eficientes para ver nuestros catálogos de maquinaria en la página Web profesional a nivel internacional por Internet.',
    answer10: 'Porque es la mejor manera de cubrir nuestro gran mercado Internacional. Nuestros clientes requieren de productos de calidad para la industria de la construcción.',
    answer11: 'Sólo tiene que registrarse en nuestro sitio Web y automáticamente contará con los productos que ofrece IMCMEXICO.',
    answer12: 'Son empresas y personas que ofrecen maquinaria pesada, del sector de la construcción y, desean comercializarla a través de nuestra página Web, la cual está abierta tanto a grandes empresas como a las personas que quieran anunciarse en un sistema amigable y de uso sencillo.',
    answer13: 'Sólo necesita registrarse en nuestro sitio Web e introducir la información y fotografías de sus equipos.',
    answer14: 'Sólo tiene que irse al link REGISTRO y dar clic a la opción que más le convenga de acuerdo a sus propósitos, posteriormente llenar el formulario que aparece en pantalla y listo, usted obtendrá un nombre de usuario y clave.',
    answer15: 'El registro solo le llevará dos minutos y, para insertar la información y fotografías de cada equipo tan solo 3 minutos.',
    answer16: 'Toda la información que usted proporciona, es enviada automáticamente a la cuenta de correo insertada en la forma de registro y así, usted mismo, con su nombre de usuario y clave podrá manejar su propia información en el sistema en el momento que lo desee.',
    answer17: 'Una vez que obtenga su nombre de usuario y clave deberá ingresarlas en www.imcmexico.com.mx cada vez que use los servicios de IMCMEXICO.',
    answer18: 'Ninguno. El registro en nuestro sitio Web es totalmente gratis.',
    answer19: 'Sí, los precios que manejamos en nuestro portal, son precios de lista, que pueden variar para su beneficio dependiendo del acuerdo al que se llegue con el vendedor, en el momento que usted decida adquirir el equipo.',
    answer20: 'Una de las grandes ventajas de ser usuario registrado de IMCMEXICO, es que usted podrá generar sus propios Proyectos de Compra de maquinaria usada en forma automatizada, confiable y obtener cotizaciones más precisas cuando desee adquirir maquinaria. En el caso de publicación de productos y servicios, usted podrá manejar su información cada vez que accese a nuestro portal.',
    answer21: 'Usted puede consultar nuestro sitio Web sin ningún costo, y si desea publicar sus productos y servicios, el primer mes es totalmente gratis.',
    answer22: 'Cuando tenemos un cliente que está interesado en comprar un equipo de su inventario, nuestro personal le preguntará si ese equipo está disponible y cuál es su última oferta. Si hay acuerdo con el cliente, entonces podremos negociar.',
    answer23: 'Sí, en el caso que usted desee usar su propio logo e información del contacto. En este caso cobramos una comisión de $50.00 USD por cada equipo durante el primer mes, a partir del segundo mes cobramos $100. Usted podrá obtener 50% de descuento en toda su publicidad durante 6 meses, si invita a otra empresa a formar parte de los usuarios registrados de IMCMEXICO. Si elige esta alternativa nosotros no sabemos quién será el comprador porque ellos se pondrán en contacto directamente con usted, pero si necesita nuestra asistencia, IMCMEXICO estará a sus órdenes para ayudarlo con sus clientes del extranjero en el momento que lo solicite.',
    answer24: 'No, si usted quiere usar los servicios de IMCMEXICO, automáticamente la información del contacto que se muestra es la de nuestras oficinas, por su propia seguridad. En este caso, no tiene que pagar ninguna comisión por el uso de nuestro sistema y, no existe ningún límite para poner toda la información de los equipos que usted desee publicar.',
    answer25: 'Sí, solo cuando la máquina se vende a través de los servicios de IMCMEXICO, en este caso nosotros cobramos al proveedor el 1% del valor factura del equipo, en compensación a la asistencia que IMCMEXICO brinda a usted y a el comprador hasta el destino final del equipo.',
    answer26: 'Usted podrá realizar el pago correspondiente a través de nuestro sistema electrónico PayPal, pago bancario o a través de un depósito bancario.',
    answer27: 'Los depósitos por el pago de los servicios a IMCMEXICO deberán efectuarse a nombre de GRUPO SEG PRE S.A. DE C.V., ó a IMCMEXICO INC, cuyos datos bancarios completos se encuentran en el link denominado: DATOS BANCARIOS DE IMCMEXICO.',
    answer28: 'IMCMEXICO también ofrece la venta directa de maquinaria de construcción por pedido. Para ello basta con depositar un anticipo, el cual será determinado en el momento de concretarse la solicitud.',
    answer29: 'Ninguno. No existe ningún otro costo, comisión o gastos más que los expuestos anteriormente.',
    answer30: 'Entre las actividades que realiza IMCMEXICO se encuentra a nivel nacional la gestión de compra venta del equipo, que incluye la inspección, preparación del Certificado Nafta o de Orígen, facturas, servicio de transporte, servicio de aduana, impuestos y entrega.',
    answer31: 'IMCMEXICO es una gran ventana para la comercialización de maquinaria, en donde usted podrá publicar gratuitamente sus equipos para venta o renta y, siempre tendrá el control total para cambiar la información de cada uno de los equipos para venta o renta, o si desea podrá tenerlas en stand bye en el sistema. Si usted prefiere pagar por anunciarse, usted podrá insertar su propio logo, misión y, toda la información de contacto para mostrarse por su propia cuenta con sus clientes.',
    answer32: 'Usted puede navegar plenamente en el sistema sin necesidad de teclear su nombre de usuario y clave, con lo cual usted puede ver estimaciones preliminares de los costos de todas las máquinas pesadas que proveedores ponen a disposición para renta o venta, así como otros datos e imágenes que se publican gratuitamente en el portal.',
    answer33: 'Si usted anuncia su propia maquinaria para venderla o rentarla, nosotros nos encargamos de toda la gestión, como: servicios de transporte, servicios de aduana, entrega del equipo a su destino final, trato con el comprador y el vendedor.',
    answer34: 'Deberá obtener su membresía gratuita registrando su propio correo electrónico, nombre y teléfono en el formato de registro del portal de IMCMEXICO, en donde en forma rápida usted mismo generará su nombre de usuario y clave con solo llenar de un breve formulario de REGISTRO.',
    answer35: 'Sí, usted puede importar maquinaria del extranjero mediante los servicios de gestión de IMCMEXICO, nosotros le facilitaremos datos de contactos de gestores aduaneros para cada país.',
    answer36: 'Para producir una lista, el cliente puede usar el buscador de maquinaria implementado en el sistema y puede también navegar en el catálogo de IMCMEXICO para seleccionar las máquinas que le interesan activando el botón "Generar mi lista de máquinas"',
    description: 'Haga clic sobre una pregunta para poder visualizar su respuesta.',
    headerTitle: 'Preguntas Frecuentes',
    question01: '¿Qué mercado cubre IMCMEXICO?',
    question02: '¿Tiene algún convenio IMCMEXICO con las empresas de Estados Unidos, Canadá y el resto del mundo que se anuncian en el portal?',
    question03: '¿Qué tan importantes son las empresas de Estados Unidos, Canadá y resto del mundo que se anuncian en IMCMEXICO?',
    question04: '¿Porqué se ha incrementado la adquisición de maquinaria pesada del extranjero?',
    question05: '¿Cómo puede apoyar IMCMEXICO a sus clientes que requieren adquirir maquinaria?',
    question06: 'Las cotizaciones de la maquinaria que aparece en el portal de IMCMEXICO, ¿son reales?',
    question07: '¿Cuánto tiempo estarán vigentes las promociones que aparecen en la tabla de servicios y costos?',
    question08: 'Si requiero de alguna máquina que no esté en el portal de IMCMEXICO, ¿me pueden asesorar para conseguirla?',
    question09: '¿Por qué IMCMEXICO está creciendo tan rápido?',
    question10: '¿Por qué me conviene anunciarme en IMCMEXICO?',
    question11: '¿Cómo puedo accesar a los servicios que ofrece IMCMEXICO?',
    question12: '¿Quiénes son los usuarios que se anuncian dentro del sistema de IMCMEXICO?',
    question13: '¿Qué necesito para publicar mi inventario de maquinaria?',
    question14: '¿Cómo me registro en IMCMEXICO?',
    question15: '¿Cuánto tiempo me toma registrarme e insertar la información de cada máquina?',
    question16: '¿Por qué es importante registrarme e insertar la información en IMCMEXICO?',
    question17: 'Después de haberme registrado, ¿qué debo hacer?',
    question18: '¿Qué costo tiene registrarme en IMCMEXICO?',
    question19: 'Las cotizaciones de la maquinaria que aparece en el portal de IMCMEXICO, ¿son reales?',
    question20: '¿Qué ventajas obtengo como usuario registrado de IMCMEXICO?',
    question21: '¿Existe alguna condición o pago por el uso del sitio Web de IMCMEXICO?',
    question22: '¿Qué sucede si un cliente se interesa en una máquina de mi inventario?',
    question23: '¿El sitio Web muestra quién es el dueño del equipo?',
    question24: '¿Existe algún costo por publicar mis máquinas sin mostrar mis datos?',
    question25: 'Si la máquina se vende, ¿IMCMEXICO cobra alguna comisión?',
    question26: '¿Cómo realizo el pago por los servicios a IMCMEXICO?',
    question27: '¿Cuáles son los datos bancarios de IMCMEXICO?',
    question28: 'Además del servicio de gestión, ¿qué otro servicio me ofrece IMCMEXICO para la adquisición de equipos?',
    question29: '¿Existe algún costo, comisión u otros gastos ocultos relacionados la publicación de la maquinaria en sitio Web de IMCMEXICO?',
    question30: '¿Cuál es el rol de IMCMEXICO en la venta de nuestra maquinaria, quién factura, quién lleva a cabo la entrega, etc?',
    question31: '¿Porqué es una buena decisión anunciar mis equipos en IMCMEXICO?',
    question32: '¿Cómo puedo conocer los servicios de venta y renta de maquinaria que ofrece IMCMEXICO?',
    question33: '¿En qué consiste la gestión de la compra o renta de los equipos en IMCMEXICO?',
    question34: '¿Cómo obtengo acceso a la compra o renta de maquinaria a través del sistema IMCMEXICO?',
    question35: '¿Puedo importar maquinaria del extranjero a través de IMCMEXICO?',
    question36: '¿Cómo genero una lista de los equipos que me interesan?',
    pageTitle: 'Preguntas Frecuentes',
    pageDescription: 'Encuentra aqui las respuestas a las preguntas mas comunes que se han realizado a IMCMEXICO.',
  },
  publishMachine: {
    headerTitle: 'Publicar Máquina',
    pageTitle: 'Publicar Máquina',
  },
  help: {
    content: '',
    description: '',
    headerTitle: 'Ayuda',
    pageTitle: 'Ayuda',
  },
  signUp: {
    headerTitle: 'Registro de Usuario',
    pageTitle: 'Registro de Usuario',
    existingAccountDialogTitle: 'Cuenta existente',
    emailJSSignupSubject: 'IMCMEXICO - Confirmación de Registro de Usuario',
    emailJSSignupBody: 'En IMCMEXICO nos complace que usted tenga el interés de registrarse con nosotros. Por favor haga clic en el siguiente enlace para validar su correo electrónico. Este enlace estará vigente únicamente por 24 hrs.',
    emailJSSignupError: 'Lo sentimos, pero se presentó un error al enviar el correo de confirmación de registro. Por favor intente nuevamente.',
    emailJSSignupSuccess: '¡El correo de confirmación de registro ha sido enviado exitosamente! Por favor, revise su bandeja de entrada.',
    emailJSValidateEmailSubject: 'IMCMEXICO - Validación de Correo Electrónico',
    emailJSValidateEmailBody: 'Este correo le ha sido enviado debido a que en IMCMEXICO hemos detectado que usted no pudo completar su registro anterior al no validar su correo electrónico. Por favor haga clic en el siguiente enlace para validar su correo electrónico. Este enlace estará vigente únicamente por 24 hrs.',
    emailJSValidateEmailError: 'Lo sentimos, pero se presentó un error al enviar su correo de validación. Por favor intente nuevamente.',
    emailJSValidateEmailSuccess: '¡El correo de validación ha sido enviado exitosamente! Por favor, revise su bandeja de entrada.',
    emailJSWelcomeSubject: 'IMCMEXICO - Bienvenido a nuestro sistema',
    emailJSWelcomeBody: 'IMCMEXICO le da la más cordial bienvenida y agradecemos que nos brinde la oportunidad de servirle. Por favor haga clic en el siguiente enlace para iniciar su sesión.',
    emailJSWelcomeError: 'Lo sentimos, pero se presentó un error al enviar el correo de bienvenida. Por favor intente nuevamente.',
    emailJSWelcomeSuccess: '¡El correo de bienvenida ha sido enviado exitosamente! Por favor, revise su bandeja de entrada.',
    googleSignUp: 'Pre-llenar Formulario con Google',
    fieldCity: 'Ciudad',
    fieldConfirmPassword: 'Confirmar Contraseña',
    fieldCountry: 'País',
    fieldMobile: 'Celular',
    fieldName: 'Nombre',
    fieldState: 'Estado',
    fieldUsername: 'Nombre de usuario',
    fieldPassword: 'Contraseña',
    fieldPhoneMain: 'Teléfono',
    fieldSecondaryEmail: 'Email secundario',
    formErrorDialogTitle: 'Formulario con Errores',
    formErrorDialogContent: 'Lo sentimos, pero algunos campos presentan errores. Por favor, corríjalos e intente nuevamente.',
    formIncompleteDialogTitle: 'Formulario Incompleto',
    formIncompleteDialogContent: 'Lo sentimos, pero algunos campos obligatorios no han sido proporcionados. Por favor, llene todos los campos marcados con un asterisco e intente nuevamente.',
    formPasswordMismatchTitle: 'Formulario con Errores',
    formPasswordMismatchContent: 'Lo sentimos, pero sus contraseñas no coinciden. Por favor, corríjalas e intente nuevamente.',
    mustBe10Chars: 'Debe tener al menos 10 caracteres',
    mustContainNumber: 'Debe tener al menos 1 número',
    mustContainLowercase: 'Debe tener al menos 1 letra minúscula',
    mustContainUppercase: 'Debe tener al menos 1 letra mayúscula',
    mustContainSpecial: 'Debe tener al menos 1 caracter especial',
    passwordsMismatch: 'Asegúrese que sus contraseñas coincidan.',
    placeholderCity: 'Ciudad en donde radica',
    placeholderConfirmPassword: 'Confirmar Contraseña',
    placeholderCountry: 'Seleccione un País',
    placeholderMobile: 'Número Celular',
    placeholderName: 'Nombre y apellidos',
    placeholderState: 'Seleccione un Estado',
    placeholderUsername: 'Correo electrónico (nombre de usuario)',
    placeholderPassword: 'Contraseña',
    placeholderPhoneMain: 'Número Telefónico',
    placeholderSecondaryEmail: 'Email secundario',
    userExists: 'Usted ya tiene una cuenta con nosotros. ¿Desea iniciar su sesión?',
    userExistsButtonLabel: 'Iniciar Sesión',
    userNotAllowed: 'Su cuenta ya existe pero su correo electrónico aún no ha sido validado. ¿Desea que le enviemos un correo de validación?',
    userNotAllowedButtonLabel: 'Enviar correo',
  },
  signIn: {
    headerTitle: 'Iniciar Sesión',
    pageTitle: 'Iniciar Sesión',
    content: '¡Bienvenido Nuevamente!',
    subContent: 'Inicie sesión para poder explorar su cuenta',
    forgotPassword: '¿Olvidó su contraseña?',
    googleSignIn: 'Iniciar Sesión con Google',
    password: 'Contraseña',
    signIn: 'Iniciar Sesión',
    signOut: 'Cerrar Sesión',
    username: 'Nombre de Usuario/Email',
    orLoginVia: 'O inicie sesión mediante'
  },
  forgotPassword: {
    headerTitle: 'Olvidó Contraseña',
    pageTitle: 'Olvidó Contraseña',
    pageDescription: 'IMCMEXICO le ofrece la posibilidad de reestablecer su contraseña en caso que usted la haya olvidado.',
    content: 'Por favor proporcione su nombre de usuario (email) y haga clic en el botón Enviar para que le podamos enviar un email para reestablecer su contraseña.',
    emailJSPasswordResetRequestSubject: 'IMCMEXICO - Solicitud de Reestablecimiento de Contraseña',
    emailJSPasswordResetRequestBody: 'Recibimos una solicitud de reestablecimiento de contraseña a su nombre. Si usted no realizó esta solicitud, por favor haga caso omiso. En caso contrario, haga clic en el siguiente enlace para poder reestablecer su contraseña. Este enlace estará vigente únicamente por 24 hrs.',
    emailJSPasswordResetRequestError: 'Lo sentimos, pero se presentó un error al enviar el correo de reestablecimiento de contraseña. Por favor intente nuevamente.',
    emailJSPasswordResetRequestSuccess: '¡El correo de reestablecimiento de contraseña ha sido enviado exitosamente! Por favor, revise su bandeja de entrada.',
  },
  passwordReset: {
    headerTitle: 'Restablecer Contraseña',
    pageTitle: 'Restablecer Contraseña',
    congratulations: '¡Felicidades, su contraseña ha sido reestablecida! Pueda hacer click en el botón Iniciar Sesión Ahora para acceder a nuestro sistema o puede explorar otras opciones a través de nuestro menú.',
    content: 'Por favor, proporcione su nueva contraseña y confirme la misma para validar que la haya escrito correctamente.',
    emailJSPasswordResetSubmitSubject: 'IMCMEXICO - Contraseña Reestablecida',
    emailJSPasswordResetSubmitBody: '¡Felicidades, su contraseña se ha reestablecido exitosamente! Por favor haga clic en el siguiente enlace para iniciar su sesión.',
    emailJSPasswordResetSubmitError: 'Lo sentimos, pero se presentó un error al enviar el correo de confirmación de reestablecimiento de contraseña. Por favor intente nuevamente.',
    emailJSPasswordResetSubmitSuccess: '¡El correo de confirmación de reestablecimiento de contraseña ha sido enviado exitosamente! Por favor, revise su bandeja de entrada.',
    notification: 'Bienvenido(a) a la página de reestablecimiento de contraseña.',
    resendPasswordResetButton: 'Reenviar Email de Solicitud',
    signInNow: 'Iniciar Sesión Ahora',
  },
  usersHome: {
    headerTitle: 'Bienvenido(a) a IMCMEXICO',
    pageTitle: 'Bienvenido(a) a IMCMEXICO',
    content: '<p>Importación, comercialializacion y rastreo de oportunidades de máquinas para la construccion.</p><p>La máquina que no encuentre en nuestro portal de IMCMEXICO se la conseguimos del mercado nacional o internacional, con servicio puerta a puerta a precio altamente competivo.</p><p>Las máquinas que no ocupes, las podemos promocionar para su venta o las podemor tomar a cuenta de otra.</p>',
  },
  myMachinery: {
    headerTitle: 'Mi Maquinaria',
    pageTitle: 'Mi Maquinaria',
  },
  requests: {
    headerTitle: 'Solicitudes',
    pageTitle: 'Solicitudes',
    requestId: 'Solicitud No.',
    userCompanyName: 'Empresa',
    userContactPerson: 'Persona de Contacto',
    requestStatus: 'Estado',
    requestDate: 'Fecha de Emisión',
    all: 'Todas',
    pending: 'Pendiente',
    solved: 'Resuelta',
    customer: 'Cliente',
    unsolved: 'Sin Resolver',
    sale: 'Venta',
    rent: 'Renta',
    spareParts: 'Refacciones',
  },
  messages: {
    headerTitle: 'Mensajes',
    pageTitle: 'Mensajes',
  },
  account: {
    headerTitle: 'Cuenta',
    pageTitle: 'Cuenta',
  },
  users: {
    headerTitle: 'Usuarios',
    pageTitle: 'Usuarios',
    fieldAddress: 'Domicilio',
    fieldCity: 'Ciudad',
    fieldCompany: 'Compañía o Persona Física',
    fieldConfirmPassword: 'Confirmar Contraseña',
    fieldContactPerson: 'Persona de contacto',
    fieldContactSchedule: 'Horario de Contacto',
    fieldCountry: 'País',
    fieldFax: 'Fax',
    fieldManagingDirector: 'Director General',
    fieldMobile: 'Celular',
    fieldState: 'Estado',
    fieldUsername: 'Nombre de usuario',
    fieldPassword: 'Contraseña',
    fieldPhoneMain: 'Teléfono',
    fieldPhoneSecondary: 'Teléfono secundario',
    fieldSecondaryEmail: 'Email secundario',
    fieldWebsite: 'Sitio Web',
    placeholderAddress: 'Domicilio fiscal',
    placeholderCity: 'Ciudad en donde radica',
    placeholderCompany: 'Nombre de la compañía o de la persona física',
    placeholderConfirmPassword: 'Confirmar Contraseña',
    placeholderContactPerson: 'Nombre de la persona de contacto',
    placeholderContactSchedule: 'Especifique el horario en el que lo podemos contactar',
    placeholderCountry: 'Seleccione un País',
    placeholderFax: 'Número de Fax',
    placeholderManagingDirector: 'Nombre del Director General',
    placeholderMobile: 'Número Celular',
    placeholderState: 'Seleccione un Estado',
    placeholderUsername: 'Correo electrónico (nombre de usuario)',
    placeholderPassword: 'Contraseña',
    placeholderPhoneMain: 'Número Telefónico',
    placeholderPhoneSecondary: 'Número Telefónico Secondario',
    placeholderSecondaryEmail: 'Email secundario',
    placeholderWebsite: 'Sitio Web empezando con http o https',
  },
  userValidateEmail: {
    headerTitle: 'Validación de Cuenta',
    pageTitle: 'Validación de Cuenta',
    emailValidationSuccess: '¡Felicidades! Su cuenta ha sido validada correctamente en nuestro sistema.',
    loginAccess: 'O si lo desea, haga clic en el botón Iniciar Sesión para empezar a disfrutar de nuestros servicios.',
    resendValidationEmailButton: 'Reenviar Email de Validación',
    resendValidationEmailMessage: 'Por favor, haga click en el botón Reenviar Email de Validación para que le enviemos un nuevo código.',
    thankYou: 'Gracias por su interés en registrarse con nosotros.',
    userAlreadyValidated: 'Sin embargo su cuenta ya ha sido validada anteriormente. Por favor, haga clic en el botón Iniciar Sesión para disfrutar de nuestros servicios.',
    userNotFound: 'Sin embargo, el código de validación proporcionado no existe en nuestros registros. Verifique su código de validación e inténtelo nuevamente.',
    welcomeEmail: 'Así mismo, le hemos enviado un correo de bienvenida donde podrá encontrar un enlace para poder iniciar su sesión.',
  },
  privacyPolicies: {
    headerTitle: 'Políticas de Privacidad, Términos y Condiciones',
    pageTitle: 'Políticas de Privacidad, Términos y Condiciones',
    policy: 'IMCMEXICO funciona con ciertos datos de carácter personal (Datos Personales), los cuales son proporcionados previamente y de forma voluntaria por lo Usuarios de IMCMEXICO, por lo que esta última los usa de manera Privada y para Uso Exclusivo del Sitio IMCMEXICO. IMCMEXICO utiliza la tecnología llamada cookie. Nuestras cookies se asocian únicamente con un Usuario anónimo y su computadora y no proporcionan referencias que permitan deducir el nombre y apellidos del Usuario. Las cookies de IMCMEXICO no pueden leer datos de su disco duro ni leer archivos cookie creados por otros proveedores. IMCMEXICO cifra los datos del Usuario para mayor seguridad, que permite reconocer a los Usuarios previamente registrados, solo si el usuario así lo desea y lo permite su explorador. Dado sue solo se aceptan las cookies, simplemente, será necesario que el Usuario se registre para navegar en algunos módulos del sitio. IMCMEXICO solicita información personal adicional para la Publicación de Anuncios, los cuales quedan resguardados de manera confidencial, no siendo estos vendidos ni compartidos con terceras partes. IMCMEXICO no utilizará esta información con ningún otro fin más que el de hacer funcional el sitio, así como para informar a sus clientes y usuarios de novedades y promociones propias de IMCMEXICO o de sus miembros registrados.',
  },
  pageNotFound: {
    content: 'Por favor, navegue a través del menú superior o seleccione alguna de las siguientes opciones:',
    headerTitle: 'Página No Encontrada',
    linkAboutUs: 'Acerca de IMCMEXICO',
    linkFAQ: 'Preguntas Frecuentes',
    LinkHelp: 'Ayuda',
    linkHome: 'Página Principal',
    linkMachinery: 'Catálogo de Maquinaria',
    pageTitle: '¿Podemos ayudarle a encontrar lo que buscaba?',
    pageDescription: '¿Podemos ayudarle a encontrar lo que buscaba?',
  },
}

export default es