import { UserType } from 'pages/utils/enums'
import { SignupForm, SignupFormProps, UserFormProps } from 'pages/utils/types'

export const ADORNMENT_BG_COLOR: string = '#dfe3e8'

export const SIGNUP_FORM_DEFAULT: SignupForm = {
  userType: {
    value: UserType.notValidated,
    required: true,
  },
  username: {
    value: '',
    required: true,
  },
  password: {
    value: '',
    required: true,
  },
  confirmPassword: {
    value: '',
    required: true,
  },
  name: {
    value: '',
    required: true,
  },
  countryId: {
    value: -1,
    required: true,
  },
  stateId: {
    value: -1,
    required: true,
  },
  city: {
    value: '',
    required: true,
  },
  mobile: {
    value: '',
    required: true,
  },
  phoneMain: {
    value: '',
    required: false,
  },
  secondaryEmail: {
    value: '',
    required: false,
  },
}

export const SIGNUP_FORM_PROPS_DEFAULT: SignupFormProps = {
  userType: UserType.notValidated,
  username: '',
  password: '',
  confirmPassword: '',
  name: '',
  countryId: -1,
  stateId: -1,
  city: '',
  mobile: '',
  phoneMain: '',
  secondaryEmail: '',
}

export const USER_FORM_DEFAULT: UserFormProps = {
  userType: UserType.notValidated,
  username: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  contactPerson: '',
  countryId: -1,
  stateId: -1,
  city: '',
  address: '',
  managingDirector: '',
  phoneMain: '',
  phoneSecondary: '',
  fax: '',
  mobile: '',
  contactSchedule: '',
  secondaryEmail: '',
  website: '',
}