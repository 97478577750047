import { LOCATION_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { LocationAction, LocationState } from 'redux/utils/location.types'

export const location = (state: LocationState = LOCATION_INITIAL_STATE, action: LocationAction): LocationState => {
  switch (action.type) {
    case Action.LOCATION_UPDATE:
      return action.payload
    default:
      return state
  }
}

export default location