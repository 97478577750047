import { ConfirmActions, ConfirmState } from 'redux/utils/confirm.types'
import { CONFIRM_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'

export const confirm = (state: ConfirmState = CONFIRM_INITIAL_STATE, action: ConfirmActions): ConfirmState => {
  switch (action.type) {
    case Action.CONFIRM_SHOW:
      return {
        ...action.payload,
        isOpen: true,
      }
    case Action.CONFIRM_HIDE:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state
  }
}