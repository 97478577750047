import { Checkbox } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import { CustomCheckboxContainer } from 'components/FormFields/styledComponents'
import React, { FC } from 'react'

type Props = {
  label: string
  color?: string
  fontSize?: string | ResponsiveStyleValue<string>
  checkboxSize?: string | ResponsiveStyleValue<string>
  checked?: boolean
  disabled?: boolean
  onChange: () => void
}

export const CheckboxInput: FC<Props> = ({
  label,
  color,
  fontSize,
  checkboxSize,
  checked,
  disabled,
  onChange,
}) => (
  <CustomCheckboxContainer
    label={label}
    labelColor={color}
    labelFontSize={fontSize}
    checkboxFontSize={checkboxSize}
    control={
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    }
  />
)