import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Popper(theme: Theme) {
  const { palette } = theme
  return {
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 10,
          '& MuiPaper-root.table-filter': {
            marginTop: pxToRem(11),
            marginBottom: 0,
            padding: pxToRem(8),
            borderRadius: pxToRem(4),
            borderWidth: pxToRem(1),
            borderStyle: 'solid',
            borderColor: palette.common.black,
            backgroundColor: palette.common.white,
            boxShadow: '2px 2px 1px -1px rgba(0, 0, 0, 0.2), 2px 2px 1px 0px rgba(0, 0, 0, 0.14), 2px 1px 3px 0px rgba(0, 0, 0, 0.12)',
            '& .MuiRadioGroup-root': {
              '& .MuiFormControlLabel-root': {
                '& .MuiButtonBase-root.MuiRadio-root': {
                  padding: pxToRem(8),
                  paddingLeft: 0,
                },
              },
            },
          },
        },
      },
    },
  }
}
