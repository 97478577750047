import { Theme, alpha } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'
import { BorderStyle } from 'theme/utils/enums'

export default function Select(theme: Theme) {
  const { palette } = theme

  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: theme.palette.common.black,
            transform: 'translate(14px, 9px) scale(1)',
            '&.required': {
              '&::after': {
                content: '" *"',
                color: palette.error.main,
              },
              '&.Mui-error': {
                color: palette.error.main,
              },
            },
            '&.Mui-disabled': {
              color: alpha(theme.palette.common.black, 0.38),
              WebkitTextFillColor: alpha(theme.palette.common.black, 0.38),
            },
            '&.MuiInputLabel-shrink': {
              marginBottom: 0,
              paddingLeft: pxToRem(8),
              paddingRight: pxToRem(8),
              transform: 'translate(8px, -7px) scale(0.85)',
              color: theme.palette.blue.light,
              backgroundColor: palette.common.white,
            },
          },
          '[class$="MuiSelect-root"]': {
            '&.MuiInputBase-root': {
              color: theme.palette.common.black,
              fontWeight: 400,
              '&.MuiFilledInput-root': {
                backgroundColor: palette.common.white,
              },
              '& .MuiSelect-select.MuiSelect-outlined': {
                backgroundColor: 'transparent',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: pxToRem(1),
                  borderStyle: BorderStyle.solid,
                  borderColor: theme.palette.app.border,
                },
              },
              '&.MuiInput-underline': {
                backgroundColor: 'transparent',
                '&:before': {
                  borderBottomWidth: pxToRem(1),
                  borderBottomStyle: BorderStyle.solid,
                  borderBottomColor: theme.palette.app.border,
                },
              },
              '&.Mui-disabled': {
                color: alpha(theme.palette.common.black, 0.38),
                WebkitTextFillColor: alpha(theme.palette.common.black, 0.38),
                '&.MuiFilledInput-root': {
                  backgroundColor: theme.palette.grey[100],
                  borderColor: alpha(theme.palette.app.border, 0.23),
                },
                '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: alpha(theme.palette.app.border, 0.23),
                },
                '&.MuiInput-underline:before': {
                  borderBottomColor: alpha(theme.palette.app.border, 0.23),
                },
              },
            },
          },
          '& .MuiSelectHelperText-root.Mui-error': {
            margin: `${pxToRem(4)} ${pxToRem(14)} 0`,
            color: palette.error.main,
            fontWeight: 500,
          },
        },
      },
    },
  }
}