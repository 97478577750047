import {
  KeyboardArrowLeft as PrevIcon,
  KeyboardArrowRight as NextIcon,
} from '@mui/icons-material'
import { Box, Button, Grid, Skeleton, } from '@mui/material'
import MobileStepper from '@mui/material/MobileStepper'
import { Image } from 'components/styledComponents'
import { JustifyContent } from 'components/utils/enums'
import CarouselFooter from 'pages/components/Carousel/Home/CarouselFooter'
import CarouselHeader from 'pages/components/Carousel/Home/CarouselHeader'
import ViewsContainer from 'pages/components/Carousel/Home/ViewsContainer'
import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { CarouselItem } from 'utils/types'

type Props = {
  auto?: boolean
  dictionary: LanguageDictionary
  height: number
  items: CarouselItem[]
  loading?: boolean
  width: number
}

const UnconnectedHomeCarousel: FC<Props> = ({
  auto,
  dictionary,
  height,
  items,
  loading,
  width,
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = items.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1 === maxSteps ? 0 : prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: `${JustifyContent.center}`, width: '100%' }}>
      <Box sx={{ maxWidth: width, flexGrow: 1 }}>
        {loading || !items.length
          ? (
              <>
                <Skeleton
                  variant="rounded"
                  width={pxToRem(width)}
                  height={pxToRem(height + 64)}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Skeleton
                      variant="rectangular"
                      width={pxToRem(104)}
                      height={pxToRem(32)}
                      sx={{ my: pxToRem(8) }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', justifyContent: `${JustifyContent.end}` }}>
                    <Skeleton
                      variant="rectangular"
                      width={pxToRem(104)}
                      height={pxToRem(32)}
                      sx={{ my: pxToRem(8) }}
                    />
                  </Grid>
                </Grid>
              </>
            )
          : (
              <>
                <CarouselHeader message={items[activeStep].title} />
                <ViewsContainer
                  auto={auto}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                >
                  {items.map((item: CarouselItem, index: number) => (
                    <Box key={item.id}>
                      {Math.abs(activeStep - index) <= 2
                        ? (
                            <Image
                              src={item.imagePath}
                              alt={item.title}
                              width={pxToRem(width)}
                              height={pxToRem(height)}
                            />
                          )
                        : null}
                    </Box>
                  ))}
                </ViewsContainer>
                <CarouselFooter
                  message={items[activeStep].footer}
                  linkLabel={items[activeStep].linkLabel}
                  linkURL={items[activeStep].linkURL}
                />
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  backButton={
                    <Button size="small" onClick={handleBack} color="primary" variant="text">
                      <PrevIcon />
                      {dictionary.shared.prev}
                    </Button>
                  }
                  nextButton={
                    <Button size="small" onClick={handleNext} color="primary" variant="text">
                      {dictionary.shared.next}
                      <NextIcon />
                    </Button>
                  }
                />
              </>
            )
        }
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const HomeCarousel = connect(
  mapStateToProps
)(UnconnectedHomeCarousel)
