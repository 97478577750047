import { api } from 'api'
import { throwError } from 'redux/actions/alert'
import { Action, ActionResponseType } from 'redux/utils/enums'
import { formatApiParams, getApiHeaders, getToken } from 'redux/utils/libs'
import { GetRequestDetailsParams, GetRequestResponse, GetRequestsListParams, GetRequestsListResponse } from 'redux/utils/requests.types'
import { ServerResponse } from 'redux/utils/types'
import { ErrorProps } from 'utils/types'

/**
 * Fetches the List of the existing Requests for a User or the List of all the existing Requests when the user is from IMCMEXICO.
 * @param jsonParams The params to be sent to the API.
 */
export const getRequestsList = (jsonParams: GetRequestsListParams) =>
  async(dispatch, getState) => {
    const {
      languageStore: {
        dictionary: { error: errorDictionary },
      },
    } = getState()

    try {
      dispatch({
        type: Action.REQUESTS_READ_LOADING,
        payload: true,
      })

      // Get the User's token.
      const token = getToken()

      // Get the headers for the API call.
      const apiHeaders = getApiHeaders(true, token)

      // Format the API Params.
      const params = formatApiParams(jsonParams)

      // Trigger the Get Requests List API.
      const serverResponse: ServerResponse<ActionResponseType.requestsList> = (await api.get('api/requests.php', {
        ...apiHeaders,
        params,
      })).data

      // Get the API Response.
      const requestsResponse: GetRequestsListResponse = serverResponse.response

      dispatch({
        type: Action.REQUESTS_READ,
        payload: requestsResponse,
      })
    } catch(error) {
      const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
      const errorMessage = errorDictionary[errorCode] || error.message

      const errorAlertProps: ErrorProps = {
        error,
        errorConsole,
        errorStatus,
        errorMessage,
      }

      dispatch(throwError(errorAlertProps))
    } finally {
      dispatch({
        type: Action.REQUESTS_READ_LOADING,
        payload: false,
      })
    }
  }

/**
 * Fetches an existing Request by the provided Request ID.
 * @param requestId The ID for the request to be fetched.
 * @param jsonParams The params to be sent to the API.
 */
export const getRequestDetails = (requestId: number, jsonParams: GetRequestDetailsParams) =>
async(dispatch, getState) => {
  const {
    languageStore: {
      dictionary: { error: errorDictionary },
    },
  } = getState()

  try {
    dispatch({
      type: Action.REQUEST_DETAILS_READ_LOADING,
      payload: true,
    })

    // Get the User's token.
    const token = getToken()

    // Get the headers for the API call.
    const apiHeaders = getApiHeaders(true, token)

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    // Trigger the Get Requests List API.
    const serverResponse: ServerResponse<ActionResponseType.requestDetails> = (await api.get(`api/requests.php/${requestId}`, {
      ...apiHeaders,
      params,
    })).data

    // Get the API Response.
    const requestsResponse: GetRequestResponse = serverResponse.response

    dispatch({
      type: Action.REQUEST_DETAILS_READ,
      payload: requestsResponse,
    })
  } catch(error) {
    const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
    const errorMessage = errorDictionary[errorCode] || error.message

    const errorAlertProps: ErrorProps = {
      error,
      errorConsole,
      errorStatus,
      errorMessage,
    }

    dispatch(throwError(errorAlertProps))
  } finally {
    dispatch({
      type: Action.REQUEST_DETAILS_READ_LOADING,
      payload: false,
    })
  }
}