import { REQUESTS_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { RequestsAction, RequestsState } from 'redux/utils/requests.types'

const requests = (state: RequestsState = REQUESTS_INITIAL_STATE, action: RequestsAction): RequestsState => {
  switch (action.type) {
    case Action.REQUESTS_READ: {
      const {
        currentPage,
        itemsPerPage,
        totalRequests,
        totalPages,
        list,
      } = action.payload

      return {
        ...state,
        currentPage,
        itemsPerPage,
        totalRequests,
        totalPages,
        requests: list,
      }
    }
    case Action.REQUESTS_READ_LOADING:
      return {
        ...state,
        requestsLoading: action.payload,
      }
    case Action.REQUEST_DETAILS_READ:
      return {
        ...state,
        requestDetails: action.payload,
      }
    case Action.REQUEST_DETAILS_READ_LOADING:
      return {
        ...state,
        requestDetailsLoading: action.payload,
      }
    default:
      return state
  }
}

export default requests
