import { Link } from '@mui/material'
import LogoImage from 'assets/imc_logo.png'
import { Image } from 'components/styledComponents'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  isSignedIn: boolean
  linkToHome?: boolean
}

const Logo: FC<Props> = ({ isSignedIn, linkToHome }) => {
  const homePageUrl = isSignedIn ? '/' : '/'

  if (linkToHome) {
    return (
      <Link href={homePageUrl}>
        <Image src={LogoImage} size={{ xs: pxToRem(40), lg: pxToRem(56)}} />
      </Link>
    )
  }

  return <Image src={LogoImage} size={{ xs: pxToRem(40), lg: pxToRem(56)}} />
}

const mapStateToProps = ({ sessionStore }: ReduxStore) => {
  const { isSignedIn } = sessionStore

  return {
    isSignedIn,
  }
}

export default connect(
  mapStateToProps
)(Logo)
