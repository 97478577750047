export enum SocialMediaType {
  whatsapp = 'whatsapp',
  facebook = 'facebook',
  twitter = 'twitter',
}

export enum PlatformType {
  flat = 'flat',
  semi = 'semi',
  lowboy = 'lowboy',
}
