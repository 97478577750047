import { Theme, alpha } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function ListItem(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Navbar': {
            color: palette.app.navigation.inactiveLink,
            backgroundColor: 'transparent',
            [breakpoints.down('lg')]: {
              color: palette.common.black,
            },
          },
          '& .MuiListItemButton-root': {
            padding: pxToRem(8),
            borderRadius: pxToRem(8),
          },
          '&:hover': {
            '&.MuiListItemButton-root': {
              color: alpha(palette.app.navigation.activeLink, 0.7),
              backgroundColor: alpha(palette.common.black, 0.15),
            },
            '&.Navbar .MuiListItemButton-root': {
              color: alpha(palette.app.navigation.activeLink, 0.7),
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-selected': {
            fontWeight: 700,
            backgroundColor: 'transparent',
            '&.Navbar .MuiListItemButton-root': {
              color: palette.app.navigation.activeLink,
              [breakpoints.down('lg')]: {
                color: palette.common.black,
                backgroundColor: alpha(palette.orange.main as string, 0.6),
                borderRadius: 0,
              },
            },
          },
          '& .MuiListItemText-root .MuiListItemText-primary': {
            fontSize: pxToRem(14),
          },
          '&.Navbar .MuiListItemText-root .MuiListItemText-primary': {
            fontWeight: 500,
          },
          '& .MuiTypography-root': {
            marginBottom: 0,
          }
        },
      },
    },
  }
}