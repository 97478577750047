import AboutUs from 'pages/AboutUs'
import FAQ from 'pages/FAQ'
import Home from 'pages/Home'
import Machinery from 'pages/Machinery'
import MachineDetails from 'pages/Machinery/MachineDetails'
import PageNotFound from 'pages/PageNotFound'
import PublishMachine from 'pages/PublishMachine'
import Login from 'pages/SignIn'
import UsersCatalog from 'pages/Users/Catalog'
import ForgotPassword from 'pages/Users/ForgotPassword'
import UserHome from 'pages/Users/Home'
import Messages from 'pages/Users/Messages'
import MyMachinery from 'pages/Users/MyMachinery'
import PasswordReset from 'pages/Users/PasswordReset'
import Profile from 'pages/Users/Profile'
import Requests from 'pages/Users/Requests'
import RequestDetails from 'pages/Users/Requests/RequestDetails'
import SignUp from 'pages/Users/SignUp'
import EmailValidation from 'pages/Users/SignUp/EmailValidation'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import {
  ABOUT_US_PATH,
  BASE_PATH,
  CATEGORY_CATALOG_PATH,
  CODE_PATH,
  FAQ_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  MACHINERY_CATALOG_PATH,
  MACHINE_DETAILS_PATH,
  MANUFACTURER_CATALOG_PATH,
  GUEST_MENU_MAP,
  MODEL_CATALOG_PATH,
  PASSWORD_RESET_PATH,
  PRIVACY_POLICY_PATH,
  PUBLISH_MACHINE_PATH,
  SIGNUP_PATH,
  USERS_PATH,
  USERS_VALIDATE_EMAIL_PATH,
  USERS_HOME_PATH,
  USERS_MY_MACHINERY_PATH,
  USERS_REQUESTS_PATH,
  USERS_ACCOUNT_PATH,
  USERS_MESSAGES_PATH,
  USERS_REQUEST_DETAILS_PATH,
  USERS_MENU_MAP,
} from 'utils/constants'
import { NestedRoutes } from 'utils/types'

export const AppRoutes = (language: Language, dictionary: LanguageDictionary): NestedRoutes => ({
  headerTitle: dictionary.home.headerTitle,
  pageTitle: dictionary.home.pageTitle,
  navOption: 'home',
  dictionaryGroup: 'home',
  path: BASE_PATH,
  breadcrumbs: [],
  Component: Home,
  children: [
    {
      headerTitle: dictionary.machinery.headerTitle,
      pageTitle: dictionary.machinery.pageTitle,
      navOption: 'machinery',
      dictionaryGroup: 'machinery',
      path: MACHINERY_CATALOG_PATH[language],
      breadcrumbs: [
        {
          label: dictionary.navbar.home,
          path: BASE_PATH,
        },
        {
          label: dictionary.navbar.machinery,
        },
      ],
      Component: Machinery,
      children: [
        {
          headerTitle: dictionary.machinery.headerTitle,
          pageTitle: dictionary.machinery.pageTitle,
          navOption: 'machinery',
          dictionaryGroup: 'categoryCatalog',
          path: CATEGORY_CATALOG_PATH,
          breadcrumbs: [
            {
              label: dictionary.navbar.home,
              path: BASE_PATH,
            },
            {
              label: dictionary.navbar.machinery,
              path: GUEST_MENU_MAP(language).machinery,
            },
            {
              label: '',
            },
          ],
          Component: Machinery,
          children: [
            {
              headerTitle: dictionary.machinery.headerTitle,
              pageTitle: dictionary.machinery.pageTitle,
              navOption: 'machinery',
              dictionaryGroup: 'manufacturerCatalog',
              path: MANUFACTURER_CATALOG_PATH,
              breadcrumbs: [
                {
                  label: dictionary.navbar.home,
                  path: BASE_PATH,
                },
                {
                  label: dictionary.navbar.machinery,
                  path: GUEST_MENU_MAP(language).machinery,
                },
                {
                  label: '',
                  path: `${GUEST_MENU_MAP(language).machinery}${CATEGORY_CATALOG_PATH}`,
                },
                {
                  label: '',
                },
              ],
              Component: Machinery,
              children: [
                {
                  headerTitle: dictionary.machinery.headerTitle,
                  pageTitle: dictionary.machinery.pageTitle,
                  navOption: 'machinery',
                  dictionaryGroup: 'modelCatalog',
                  path: MODEL_CATALOG_PATH,
                  breadcrumbs: [
                    {
                      label: dictionary.navbar.home,
                      path: BASE_PATH,
                    },
                    {
                      label: dictionary.navbar.machinery,
                      path: GUEST_MENU_MAP(language).machinery,
                    },
                    {
                      label: '',
                      path: `${GUEST_MENU_MAP(language).machinery}${CATEGORY_CATALOG_PATH}`,
                    },
                    {
                      label: '',
                      path: `${GUEST_MENU_MAP(language).machinery}${CATEGORY_CATALOG_PATH}${MANUFACTURER_CATALOG_PATH}`,
                    },
                    {
                      label: '',
                    },
                  ],
                  Component: Machinery,
                  children: [
                    {
                      headerTitle: dictionary.machinery.headerTitle,
                      pageTitle: dictionary.machinery.pageTitleDetails,
                      navOption: 'machinery',
                      dictionaryGroup: 'machineDetails',
                      path: MACHINE_DETAILS_PATH,
                      breadcrumbs: [
                        {
                          label: dictionary.navbar.home,
                          path: BASE_PATH,
                        },
                        {
                          label: dictionary.navbar.machinery,
                          path: GUEST_MENU_MAP(language).machinery,
                        },
                        {
                          label: '',
                          path: `${GUEST_MENU_MAP(language).machinery}${CATEGORY_CATALOG_PATH}`,
                        },
                        {
                          label: '',
                          path: `${GUEST_MENU_MAP(language).machinery}${CATEGORY_CATALOG_PATH}${MANUFACTURER_CATALOG_PATH}`,
                        },
                        {
                          label: '',
                          path: `${GUEST_MENU_MAP(language).machinery}${CATEGORY_CATALOG_PATH}${MANUFACTURER_CATALOG_PATH}${MODEL_CATALOG_PATH}`,
                        },
                        {
                          label: '',
                        },
                      ],
                      Component: MachineDetails,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      headerTitle: dictionary.aboutUs.headerTitle,
      pageTitle: dictionary.aboutUs.pageTitle,
      navOption: 'aboutUs',
      dictionaryGroup: 'aboutUs',
      path: ABOUT_US_PATH[language],
      breadcrumbs: [
        {
          label: dictionary.navbar.home,
          path: BASE_PATH,
        },
        {
          label: dictionary.navbar.aboutUs,
        },
      ],
      Component: AboutUs,
    },
    {
      headerTitle: dictionary.faq.headerTitle,
      pageTitle: dictionary.faq.pageTitle,
      navOption: 'faq',
      dictionaryGroup: 'faq',
      path: FAQ_PATH[language],
      breadcrumbs: [
        {
          label: dictionary.navbar.home,
          path: BASE_PATH,
        },
        {
          label: dictionary.navbar.faq,
        },
      ],
      Component: FAQ,
    },
    {
      headerTitle: dictionary.publishMachine.headerTitle,
      pageTitle: dictionary.publishMachine.pageTitle,
      navOption: 'publishMachine',
      dictionaryGroup: 'publishMachine',
      path: PUBLISH_MACHINE_PATH[language],
      breadcrumbs: [
        {
          label: dictionary.navbar.home,
          path: BASE_PATH,
        },
        {
          label: dictionary.navbar.publishMachine,
        },
      ],
      Component: PublishMachine,
    },
    {
      headerTitle: dictionary.signUp.headerTitle,
      pageTitle: dictionary.signUp.pageTitle,
      navOption: 'signUp',
      dictionaryGroup: 'signUp',
      path: SIGNUP_PATH[language],
      breadcrumbs: [
        {
          label: dictionary.navbar.home,
          path: BASE_PATH,
        },
        {
          label: dictionary.navbar.signUp,
        },
      ],
      Component: SignUp,
    },
    {
      headerTitle: dictionary.userValidateEmail.headerTitle,
      pageTitle: dictionary.userValidateEmail.pageTitle,
      navOption: 'userValidateEmail',
      dictionaryGroup: 'userValidateEmail',
      path: USERS_VALIDATE_EMAIL_PATH[language],
      breadcrumbs: [],
      Component: EmailValidation,
      children: [
        {
          headerTitle: dictionary.userValidateEmail.headerTitle,
          pageTitle: dictionary.userValidateEmail.pageTitle,
          navOption: 'userValidateEmail',
          dictionaryGroup: 'userValidateEmail',
          path: CODE_PATH,
          breadcrumbs: [
            {
              label: dictionary.navbar.home,
              path: BASE_PATH,
            },
            {
              label: dictionary.navbar.signUp,
            },
          ],
          Component: EmailValidation,
        }
      ],
    },
    {
      headerTitle: dictionary.signIn.headerTitle,
      pageTitle: dictionary.signIn.pageTitle,
      navOption: 'signIn',
      dictionaryGroup: 'signIn',
      path: LOGIN_PATH[language],
      breadcrumbs: [
        {
          label: dictionary.navbar.home,
          path: BASE_PATH,
        },
        {
          label: dictionary.navbar.signIn,
        },
      ],
      Component: Login,
    },
    {
      headerTitle: dictionary.forgotPassword.headerTitle,
      pageTitle: dictionary.forgotPassword.pageTitle,
      navOption: 'forgotPassword',
      dictionaryGroup: 'forgotPassword',
      path: FORGOT_PASSWORD_PATH[language],
      breadcrumbs: [],
      Component: ForgotPassword,
    },
    {
      headerTitle: dictionary.passwordReset.headerTitle,
      pageTitle: dictionary.passwordReset.pageTitle,
      navOption: 'passwordReset',
      dictionaryGroup: 'passwordReset',
      path: PASSWORD_RESET_PATH[language],
      breadcrumbs: [],
      Component: PasswordReset,
      children: [
        {
          headerTitle: dictionary.passwordReset.headerTitle,
          pageTitle: dictionary.passwordReset.pageTitle,
          navOption: 'passwordReset',
          dictionaryGroup: 'passwordReset',
          path: CODE_PATH,
          breadcrumbs: [],
          Component: PasswordReset,
        }
      ],
    },
    {
      headerTitle: dictionary.usersHome.headerTitle,
      pageTitle: dictionary.usersHome.pageTitle,
      navOption: 'usersHome',
      dictionaryGroup: 'usersHome',
      path: USERS_HOME_PATH[language],
      breadcrumbs: [],
      Component: UserHome,
    },
    {
      headerTitle: dictionary.myMachinery.headerTitle,
      pageTitle: dictionary.myMachinery.pageTitle,
      navOption: 'myMachinery',
      dictionaryGroup: 'myMachinery',
      path: USERS_MY_MACHINERY_PATH[language],
      breadcrumbs: [],
      Component: MyMachinery,
    },
    {
      headerTitle: dictionary.requests.headerTitle,
      pageTitle: dictionary.requests.pageTitle,
      navOption: 'requests',
      dictionaryGroup: 'requests',
      path: USERS_REQUESTS_PATH[language],
      breadcrumbs: [
        {
          label: dictionary.navbar.home,
          path: BASE_PATH,
        },
        {
          label: dictionary.navbar.requests,
        },
      ],
      Component: Requests,
      children: [
        {
          headerTitle: dictionary.requests.headerTitle,
          pageTitle: dictionary.requests.pageTitle,
          navOption: 'requests',
          dictionaryGroup: 'requests',
          path: USERS_REQUEST_DETAILS_PATH,
          breadcrumbs: [
            {
              label: dictionary.navbar.home,
              path: BASE_PATH,
            },
            {
              label: dictionary.navbar.requests,
              path: USERS_MENU_MAP(language).requests,
            },
            {
              label: '',
            },
          ],
          Component: RequestDetails,
        }
      ],
    },
    {
      headerTitle: dictionary.messages.headerTitle,
      pageTitle: dictionary.messages.pageTitle,
      navOption: 'messages',
      dictionaryGroup: 'messages',
      path: USERS_MESSAGES_PATH[language],
      breadcrumbs: [],
      Component: Messages,
    },
    {
      headerTitle: dictionary.account.headerTitle,
      pageTitle: dictionary.account.pageTitle,
      navOption: 'account',
      dictionaryGroup: 'account',
      path: USERS_ACCOUNT_PATH[language],
      breadcrumbs: [],
      Component: Profile,
    },
    {
      headerTitle: dictionary.users.headerTitle,
      pageTitle: dictionary.users.pageTitle,
      navOption: 'users',
      dictionaryGroup: 'users',
      path: USERS_PATH[language],
      breadcrumbs: [],
      Component: UsersCatalog,
    },
    {
      headerTitle: dictionary.privacyPolicies.headerTitle,
      pageTitle: dictionary.privacyPolicies.pageTitle,
      navOption: 'privacyPolicies',
      dictionaryGroup: 'privacyPolicies',
      path: PRIVACY_POLICY_PATH[language],
      breadcrumbs: [],
      Component: Home,
    },
    {
      headerTitle: dictionary.pageNotFound.headerTitle,
      pageTitle: dictionary.pageNotFound.pageTitle,
      navOption: 'pageNotFound',
      dictionaryGroup: 'pageNotFound',
      breadcrumbs: [],
      path: '*',
      Component: PageNotFound,
    },
  ],
})