export enum BorderStyle {
  dashed = 'dashed',
  dotted = 'dotted',
  double = 'double',
  none = 'none',
  solid = 'solid',
}

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  text = 'text',
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
  custom = 'custom',
}

export enum InputColors {
  primary = 'primary',
  secondary = 'secondary',
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
  blue = 'blue',
  orange = 'orange',
  yellow = 'yellow',
}