import { api } from 'api'
import { Action, ActionResponseType } from 'redux/utils/enums'
import { CountriesResponse } from 'redux/utils/countries.types'
import { ServerResponse } from 'redux/utils/types'

export const getCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: Action.COUNTRIES_LOADING,
      payload: true,
    })

    // Trigger the Get Countries List API.
    const serverResponse: ServerResponse<ActionResponseType.countriesList> = (await api.get('api/countries.php', {})).data

    const countriesResponse: CountriesResponse[] = serverResponse.response

    dispatch({
      type: Action.COUNTRIES_READ,
      payload: countriesResponse,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.COUNTRIES_LOADING,
      payload: false,
    })
  }
}
