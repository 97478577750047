import { alpha, Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function TableContainer(theme: Theme) {
  const { breakpoints, palette } = theme

  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          maxHeight: `calc(100% - ${pxToRem(96)})`,
          [breakpoints.up('lg')]: {
            maxHeight: `calc(100% - ${pxToRem(90)})`,
          },
          '& .MuiTable-root': {
            border: `1px solid ${palette.app.border}`,
            borderRadius: pxToRem(4),
            '& .MuiTableHead-root': {
              '& .MuiTableRow-root': {
                backgroundColor: palette.app.table.header,
                '& #checkbox': {
                  padding: `${pxToRem(8)} ${pxToRem(4)}`,
                  width: pxToRem(40),
                },
                '& .MuiTableCell-root': {
                  padding: `${pxToRem(8)} ${pxToRem(16)}`,
                  color: palette.common.white,
                  fontSize: pxToRem(14),
                  backgroundColor: 'inherit',
                  borderBottom: `1px solid ${palette.app.border}`,
                  '& .MuiTableSortLabel-root': {
                    color: palette.common.white,
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: palette.common.white,
                  },
                  '& .MuiBadge-root': {
                    '& .MuiBadge-badge': {
                      backgroundColor: palette.error.main,
                    },
                  },
                },
              },
            },
            '& .MuiTableBody-root': {
              '& .MuiTableRow-root': {
                '&.clickable': {
                  cursor: 'pointer',
                },
                '&:nth-of-type(odd)': {
                  backgroundColor: palette.common.white,
                },
                '&:nth-of-type(even)': {
                  backgroundColor: alpha(palette.blue.light as string, 0.3),
                },
                '&:hover': {
                  backgroundColor: alpha(palette.orange.light as string, 0.7),
                },
                '& #checkbox': {
                  padding: `${pxToRem(8)} ${pxToRem(4)}`,
                  width: pxToRem(40),
                },
                '&:last-child': {
                  '& .MuiTableCell-root': {
                    border: 'none',
                  },
                },
                '& .MuiTableCell-root': {
                  padding: `${pxToRem(8)} ${pxToRem(16)}`,
                  display: 'table-cell',
                  fontSize: pxToRem(14),
                  borderBottom: `1px solid ${palette.app.border}`,
                  '& svg': {
                    marginRight: pxToRem(4),
                    position: 'relative',
                    top: pxToRem(2),
                    fontSize: pxToRem(14),
                  }
                },
              },
            },
          },
        },
      },
    },
  }
}