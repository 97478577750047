import { useGoogleLogin } from '@react-oauth/google'
import { gapi } from 'api'
import { GoogleButton, GoogleIconStyle } from 'components/styledComponents'
import { ButtonVariant } from 'components/utils/enums'
import React from 'react'
import { connect } from 'react-redux'
import { googleSignIn, updateGoogleProfile } from 'redux/actions/session'
import { formatResponseParams } from 'redux/utils/libs'
import { GoogleProfileData, GoogleSignInParams } from 'redux/utils/session.types'
import { ReduxStore } from 'redux/utils/types'

type Props = {
  isSignedIn: boolean
  dispatchGoogleSignIn: (email: string, jsonParams: GoogleSignInParams) => void
  dispatchUpdateGoogleProfile: (googleProfile: GoogleProfileData) => void
}

const GoogleSignIn = (props: Props) => {
  const {
    isSignedIn,
    dispatchGoogleSignIn,
    dispatchUpdateGoogleProfile,
  } = props
  /**
   * Performs a Sign-In action when the user clicks on the Google button for authentication.
   */
  const handleGoogleButtonClick = () => useGoogleLogin({
    onSuccess: async(codeResponse) => {
      const googleResponse: GoogleProfileData = (await gapi.get(`userinfo?access_token=${codeResponse?.access_token}`, {
        headers: {
          authorization: `Bearer ${codeResponse?.access_token}`,
          accept: 'application/json'
        },
      })).data

      const formattedResponse = formatResponseParams<GoogleProfileData>(googleResponse)

      if (formattedResponse) {
        const apiParams: GoogleSignInParams =  {
          avatarUrl: formattedResponse.picture,
        }

        dispatchGoogleSignIn(formattedResponse.email, apiParams)
        dispatchUpdateGoogleProfile(formattedResponse)
      }
    },
    onError: (error) => console.error('Google Authentication Failed', error),
    flow: 'implicit',
  })

  if (isSignedIn) {
    return null
  }

  return (
    <GoogleButton
      variant={ButtonVariant.text}
      onClick={handleGoogleButtonClick()}
    >
      <GoogleIconStyle size={16} viewBox="0 0 48 48" />
    </GoogleButton>
  )
}

const mapStateToProps = ({ sessionStore }: ReduxStore) => {
  const { isSignedIn } = sessionStore

  return {
    isSignedIn,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGoogleSignIn: (email: string, jsonParams: GoogleSignInParams) => dispatch(googleSignIn(email, jsonParams)),
  dispatchUpdateGoogleProfile: (googleProfile: GoogleProfileData) => dispatch(updateGoogleProfile(googleProfile)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleSignIn)