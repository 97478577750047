import React, { ReactNode } from 'react'
import { pxToRem } from 'theme/typography'

type Props = {
  className?: string
  size?: number
  width?: number
  height?: number
  children: ReactNode
  viewBox?: string
}

const CustomIcon = (props: Props) => {
  const {
    className,
    size = pxToRem(24),
    width,
    height,
    children,
    viewBox = '0 0 24 24',
  } = props
  const iconWidth = width || size
  const iconHeight = height || size

  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      viewBox={viewBox}
    >
      {children}
    </svg>
  )
}

export default CustomIcon