import { Badge, BottomNavigationAction } from '@mui/material'
import { FooterMobileBottomNavigation, FooterMobileContainer } from 'components/Navigation/styledComponents'
import { MOBILE_USERS_FOOTER_OPTIONS } from 'components/utils/constants'
import { UsersMenuOption } from 'components/utils/enums'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { BASE_PATH, USERS_MENU_MAP } from 'utils/constants'
import { getPageFromPath } from 'utils/libs'
import { Route } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  isSignedIn: boolean
  userPrivilege?: number
}

const FooterUsers: FC<Props> = ({
  dictionary,
  dictionary: { bottomNavigator: bottomNavigatorDictionary },
  language,
  isSignedIn,
  userPrivilege,
}) => {
  const { pathname } = useLocation() || {}
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState<string>(UsersMenuOption.home)

  const page: Route | undefined = getPageFromPath(language, dictionary, pathname)

  const handleFooterChange = (_event, newOption: string) => {
    setSelectedOption(newOption)

    // Move to the selected page.
    navigate(USERS_MENU_MAP(language)[newOption])
  }

  useEffect(() => {
    const shouldResetNavOption = (!page || pathname === BASE_PATH) && !!selectedOption && selectedOption !== UsersMenuOption.home
    const shouldUpdateNavOption = page && selectedOption !== page.navOption

    if (shouldResetNavOption) {
      setSelectedOption(UsersMenuOption.home)
    } else if (shouldUpdateNavOption) {
      const { navOption: newNavOption } = page
      setSelectedOption(newNavOption)
    }
  }, [page])

  if (!isSignedIn) {
    return null
  }

  return (
    <FooterMobileContainer>
      <FooterMobileBottomNavigation
        showLabels
        value={selectedOption}
        onChange={handleFooterChange}
      >
        {MOBILE_USERS_FOOTER_OPTIONS.map(option => {
          const {
            id,
            icon: IconComponent,
            allowedPrivilegeIds,
          } = option

          if (!allowedPrivilegeIds || (!!userPrivilege && allowedPrivilegeIds.includes(userPrivilege))) {
            let icon: ReactNode | undefined

            if (IconComponent) {
              icon = option.useBadge
                ? <Badge badgeContent={0}><IconComponent /></Badge>
                : <IconComponent />
            }

            return (
              <BottomNavigationAction
                key={id}
                label={bottomNavigatorDictionary[id]}
                icon={icon}
                value={id}
                disableRipple
              />
            )
          }

          return null
        })}
      </FooterMobileBottomNavigation>
    </FooterMobileContainer>
  )
}

const mapStateToProps = ({ languageStore, sessionStore, timezoneStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { isSignedIn, sessionData } = sessionStore
  const { timezone } = timezoneStore

  const { userPrivilege } = sessionData || {}

  return {
    dictionary,
    language,
    isSignedIn,
    timezone,
    userPrivilege,
  }
}

export default connect(
  mapStateToProps
)(FooterUsers)
