import { Badge, Pagination, PaginationItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import { Row, SkeletonText } from 'components/styledComponents'
import TableFilter from 'components/TableStructure/TableFilter'
import { JustifyContent } from 'components/utils/enums'
import { useGetTableBoundaries } from 'hooks'
import { isEqual } from 'lodash'
import { PopperPlacement } from 'pages/utils/enums'
import { Filter, Filters, TableColumnFilterProperties, TableColumnProperties } from 'pages/utils/types'
import React, { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { FaFilter as FilterIcon } from 'react-icons/fa'
import { connect } from 'react-redux'
import { SortDirection } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { KeyValueRecord } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  columns: TableColumnProperties[]
  columnDictionary: KeyValueRecord<string>
  rows: any[]
  filters: Filters
  sortBy: string
  sortDirection: SortDirection
  currentPage: number
  itemsPerPage: number
  totalPages: number
  totalRecords: number
  isLoading: boolean
  onFiltersChange: (newFilters: Filters) => void
  onPaginationChange: (newPage: number) => void
  onRowClick?: (rowId: string | number) => void
  onSortChange: (newSortBy: string, newSortDirection: SortDirection) => void
}

const TableStructure: FC<Props> = ({
  dictionary: { shared: sharedDictionary },
  columns,
  columnDictionary,
  rows,
  filters,
  sortBy,
  sortDirection,
  currentPage,
  itemsPerPage,
  totalPages,
  totalRecords,
  isLoading,
  onFiltersChange,
  onPaginationChange,
  onRowClick,
  onSortChange,
}) => {
  const { siblingCount, boundaryCount } = useGetTableBoundaries()
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null)
  const [filterProperties, setFilterProperties] = useState<TableColumnFilterProperties>({})
  const [filterPlacement, setFilterPlacement] = useState<PopperPlacement | undefined>()
  const [columnFilter, setColumnFilter] = useState<Filter | undefined>()
  const [selectedColumn, setSelectedColumn] = useState<string>('')

  const skeletonRows = Array.from(Array(5).keys())
  const tableRows = isLoading ? skeletonRows : rows
  const recordStart = (currentPage - 1) * itemsPerPage + 1
  const recordEnd = isLoading ? skeletonRows.length : currentPage + rows.length - 1
  const tableSummary = sharedDictionary.tableSummary
    .replace('{XX}', recordStart.toLocaleString().toString())
    .replace('{YY}', recordEnd.toLocaleString().toString())
    .replace('{ZZ}', totalRecords.toLocaleString().toString())

  /**
   * Triggers the action for clicking a Table Row.
   * @param rowId The ID for the row that has been clicked.
   */
  const handleRowClick = (rowId: string | number) => (): void => {
    if (onRowClick) {
      onRowClick(rowId)
    }
  }

  /**
   * Updates the value for the "Current Page" based on the new value provided.
   * @param _event The HTML change event (we don't need this).
   * @param newPage The new page number to be used to fetch the data for the Machines list.
   */
   const handlePaginationChange = (_event: ChangeEvent<unknown>, newPage: number): void => {
    onPaginationChange(newPage)
  }

  /**
   * Re-Sorts the table
   * @param newSortBy The selected column for re-sorting the table.
   * @param currentSortDirection The direction for the sort: Asc or Desc.
   */
  const handleSortChange = (newSortBy: string, currentSortDirection: SortDirection) => (): void => {
    const columnChanged = newSortBy !== sortBy
    const swappedSortDirection = currentSortDirection === SortDirection.asc ? SortDirection.desc : SortDirection.asc
    const newSortDirection = columnChanged ? currentSortDirection : swappedSortDirection

    onSortChange(newSortBy, newSortDirection)
  }

  /**
   * Open/Closes the filter popper for the clicked column.
   * @param columnFilter The data for the column that has been clicked.
   * @param isFilterOpened The current status for the filter popper: Open or Close.
   */
  const handleColumnFilterClick = (columnFilter: TableColumnProperties, isFilterOpened: boolean) => (event: MouseEvent<SVGElement>): void => {
    // Exit when there aren't any filters.
    if (!columnFilter.filter) {
      return
    }

    // Get the values for the provided column.
    const { id: columnId, filter, filterPosition } = columnFilter

    // Set the initial status for the Filter Popper (opposite of the current status).
    let newOpenFilter = !isFilterOpened

    // Set the Filter Popper status as open when the clicked column is different from the previous one.
    if (columnId !== selectedColumn) {
      setSelectedColumn(columnId)
      newOpenFilter = true
    }

    // Update the clicked column when it is different from the previous one.
    if (!isEqual(filter, filters[columnId])) {
      setColumnFilter(filters[columnId])
    }

    // Define the Filter Placement and its Properties.
    setFilterPlacement(filterPosition)
    setFilterProperties(filter)

    // Open/Close the Filter Popper based on the new status.
    setOpenFilter(newOpenFilter)
    setAnchorEl(event.currentTarget)
  }

  /**
   * Updates the Filter for a provided column.
   * @param columnId The column that has been selected for applying a filter.
   * @param newFilter The filter that has been applied to the provided column.
   */
  const handleFilterChange = (columnId: string) => (newFilter: Filter): void => {
    // Set the new filters to be sent to the parent component.
    const newFilters: Filters = {
      ...filters,
      [columnId]: newFilter,
    }

    // Send the new filters to the parent component.
    onFiltersChange(newFilters)

    // Close the Filter Popper.
    setOpenFilter(false)
  }

  /**
   * Closes the Filter Popper.
   */
  const handleFilterClose = (): void => {
    setOpenFilter(false)
  }

  return (
    <Paper className="table-main-wrapper">
      <TableFilter
        open={openFilter}
        anchorEl={anchorEl}
        filterProperties={filterProperties}
        filter={columnFilter}
        filterPlacement={filterPlacement}
        onClose={handleFilterClose}
        onFilterChange={handleFilterChange(selectedColumn)}
      />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => {
                const columnSortDirection = sortBy === column.id ? sortDirection : column.initialSortDirection
                const isValueProvided = column.filter?.value && filters[column.id] !== ''
                const isMultipleValueAll = column.filter?.options && filters[column.id]
                  ? Object.keys(column.filter.options).length - 1 === filters[column.id].length
                  : true
                const showBadge = isValueProvided || !isMultipleValueAll ? 1 : 0
                return (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    <Row>
                      <TableSortLabel
                        active={sortBy === column.id}
                        direction={columnSortDirection}
                        onClick={handleSortChange(column.id, columnSortDirection)}
                      >
                        {columnDictionary[column.id]}
                      </TableSortLabel>
                      {!!column.filter && (
                        <Badge badgeContent={showBadge} variant="dot">
                          <FilterIcon cursor="pointer" onClick={handleColumnFilterClick(column, openFilter)} />
                        </Badge>
                      )}
                    </Row>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map(row => (
              <TableRow key={row.id} hover role="checkbox" tabIndex={-1} className={onRowClick ? 'clickable' : ''}>
                {columns.map(column => {
                  const value = row[column.id] || ''

                  return (
                    <TableCell key={column.id} onClick={handleRowClick(row.id)}>
                      {isLoading
                        ? <SkeletonText />
                        : <>{column.format ? column.format(value) : value}</>
                      }
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Row marginTop={pxToRem(8)} justifyContent={JustifyContent.flexEnd}>
        <Typography variant="body2">
          {tableSummary}
        </Typography>
      </Row>
      <Pagination
        variant="outlined"
        color="secondary"
        count={totalPages}
        page={currentPage}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        renderItem={({ page, ...item }) => (
          <PaginationItem
            page={page?.toLocaleString()}
            {...item}
          />
        )}
        onChange={handlePaginationChange}
      />
    </Paper>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export default connect(
  mapStateToProps,
)(TableStructure)
