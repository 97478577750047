import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DeviceType } from 'pages/Machinery/utils/types'

export const useCurrentDevice = (): DeviceType => {
  const { breakpoints } = useTheme()
  const breakpointXs = useMediaQuery(breakpoints.down('sm'))
  const breakpointSm = useMediaQuery(breakpoints.between('sm', 'md'))
  const breakpointMd = useMediaQuery(breakpoints.between('md', 'lg'))
  const breakpointLg = useMediaQuery(breakpoints.between('lg', 'xl'))
  const breakpointXl = useMediaQuery(breakpoints.up('lg'))
  const isMobile = !breakpointLg && !breakpointXl
  const isSmartphone = breakpointXs || breakpointSm
  const isSmartphoneXs = breakpointXs
  const isTablet = breakpointMd
  const isDesktop = breakpointLg || breakpointXl
  const isDesktopXl = breakpointXl

  return {
    isMobile,
    isSmartphone,
    isSmartphoneXs,
    isTablet,
    isDesktop,
    isDesktopXl,
  }
}