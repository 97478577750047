import { StyledComponent } from '@emotion/styled'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { AlignItems, JustifyContent } from 'components/utils/enums'

export const FilterOptionsContainer: StyledComponent<any> = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: AlignItems.flexStart,
    justifyContent: JustifyContent.center,
  })
)