import { createTheme, SimplePaletteColorOptions, Theme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'
import React, { ReactNode, useMemo } from 'react'
import componentsOverride from 'theme/overrides'
import palette from 'theme/palette'
import shape from 'theme/shape'
import typography from 'theme/typography'
import {
  CustomShape,
  PaletteAppColors,
  PaletteSocialMediaColors,
} from 'theme/utils/types'

declare module '@mui/material/styles' {
  interface Palette {
    app: PaletteAppColors
    blue: SimplePaletteColorOptions
    orange: SimplePaletteColorOptions
    socialMedia: PaletteSocialMediaColors
    yellow: string
  }
  interface PaletteOptions {
    app: PaletteAppColors
    blue: SimplePaletteColorOptions
    orange: SimplePaletteColorOptions
    socialMedia: PaletteSocialMediaColors
    yellow: string
  }
  interface Theme {
    shape: CustomShape
  }
  interface ThemeOptions {
    shape: CustomShape
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    app: true
    primary: true
    secondary: true
    error: true
    info: true
    success: true
    warning: true
    blue: true
    orange: true
    yellow: true
    socialMedia: true
  }
}

type Props = {
  children: ReactNode
}

const ThemeConfig = ({ children }: Props) => {
  const themeOptions = useMemo(() => ({
    breakpoints: {
      values: {
        xs: 0,
        sm: 425,
        md: 728,
        lg: 1024,
        xl: 1440,
      },
    },
    palette,
    shape,
    typography,
  }), [])
  const theme: Theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)
  
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeConfig
