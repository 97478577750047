import { LanguageDictionary } from 'redux/utils/language.types'
import { DATETIME_FORMATS } from 'utils/constants'

const en: LanguageDictionary = {
  shared: {
    add: 'Add',
    ascending: 'Ascending',
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    congratulations: 'Congratulations!',
    create: 'Create',
    createMyAccount: 'Create my Account',
    date: 'Date',
    dateRangePicker: 'Select Date Range',
    dateRangeSeparatorLabel: 'to',
    day: 'day',
    days: 'days',
    dearUser: 'Dear User',
    delete: 'Delete',
    descending: 'Descending',
    download: 'Download',
    downloadData: 'Download Data',
    edit: 'Edit',
    emailJSSignature: 'Best regards',
    emailJSClosing: 'We thank you in advance for your interest and we are at your service.',
    endDate: 'End Date',
    filter: 'Filter',
    forgotPassword: 'Forgot Password',
    generatePassword: 'Generate Password',
    goToHomePage: 'Go to Home page',
    goToSignInPage: 'Go to Sign In page',
    hello: 'Hello',
    hidePassword: 'Hide Password',
    hour: 'hour',
    hours: 'hours',
    loading: 'Loading',
    minute: 'minute',
    minutes: 'minutes',
    next: 'Next',
    no: 'No',
    notDefined: 'Not Defined',
    ok: 'OK',
    or: 'or',
    paginationBlockData: 'Showing {XX}-{YY} from {ZZ} records',
    paginationRowsPerPage: 'Rows per page',
    prev: 'Prev',
    resetPassword: 'Reset Password',
    save: 'Save',
    saveChanges: 'Save Changes',
    second: 'second',
    seconds: 'seconds',
    seeDetails: 'View Details',
    sendEmail: 'Send Email',
    showAll: 'Show All',
    showPassword: 'Show Password',
    startDate: 'Start Date',
    submit: 'Submit',
    tableSummary: 'Record: {XX} to {YY} of {ZZ}',
    validateEmail: 'Validate Email',
    validatingCode: 'We are validating your code. Please wait.',
    view: 'View',
    week: 'week',
    weeks: 'weeks',
    year: 'year',
    yes: 'Yes',
  },
  error: {
    actionDenied: 'Sorry, but you are not allowed to perform this action.',
    crendentialsMismatch: 'Your credentials do not match! Please try again.',
    expiredToken: 'Sorry, but your session has expired. Please re-login.',
    expiredValidationCode: 'However, the validation code has expired.',
    futureDateError: 'cannot be set after today',
    generalError: 'There was an error while performing this action. Please try again.',
    inactiveUser: 'Sorry, but your account has been deactivated.',
    invalidCategory: 'is not a valie Category.',
    invalidDateError: 'is not a valid date',
    invalidManufacturer: 'is not a valid Manufacturer.',
    invalidModel: 'is not a valid Model.',
    invalidPhoneNumber: 'This is an invalid phone number format!',
    invalidToken: 'Sorry, but you are not allowed to access this page.',
    invalidValidationCode: 'However, the validation code is invalid! Please verify the link on your email and try again.',
    maxDateError: 'cannot be set after {DATE}',
    minDateError: 'cannot be set before {DATE}',
    missingCredentials: 'You need to provide a Username and Password!',
    missingEmail: 'You need to provide an Email!',
    multipleEmailFormat: 'One or more emails don\'t have a valid format!',
    noData: 'There is no existing data',
    noDataForFilters: 'There are no records matching the provided filters',
    onlyEmail: 'Field must be an email',
    onlyLettersAndSpaces: 'Only letters and spaces are allowed',
    pageDenied: 'Sorry, but you are not allowed to access this page.',
    pastDateError: 'cannot be set before today',
    requiredValue: 'This field is required!',
    singleEmailFormat: 'This is an invalid email format!',
    somethingWentWrong: 'Oops, something went wrong',
    unexistentValidationCode: 'However, you need to provide a valid code to continue.',
    urlFormat: 'This is an invalid website format!',
    userLogout: 'There was an error while trying to sign you out!',
    userNotFound: 'Sorry, but the provided username does not exist. Please verify it and try again.',
  },
  navbar: {
    aboutUs: 'About Us',
    account: 'Account',
    advertiseHere: 'Advertise Here',
    catalog: 'Catalog',
    faq: 'FAQ',
    goodAfternoon: 'Good Afternoon',
    goodEvening: 'Good Evening',
    goodMorning: 'Good Morning',
    help: 'Help',
    home: 'Home',
    machinery: 'Machinery',
    messages: 'Messages',
    myMachinery: 'My Machinery',
    publishMachine: 'Publish Machine',
    request: 'Request',
    requests: 'Requests',
    search: 'Search',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    signUp: 'Sign Up',
    switchLanguage: 'Español',
    users: 'Users',
    usersHome: 'Inicio',
  },
  footer: {
    label: 'IMCMEXICO is a trademark',
  },
  bottomNavigator: {
    usersHome: 'Home',
    requests: 'Requests',
    messages: 'Messages',
    account: 'Account',
  },
  comingSoon: {
    headerTitle: 'Coming Soon',
    pageTitle: 'Coming Soon',
    pageDescription: 'We are working very hard to offer you the best. Soon we will have something amazing for you.',
  },
  home: {
    content: `<p>To quickly consult and quote any opportunity of used machinery from the United States of America. Equip your work by following these three easy steps:</p>
      <ol>
        <li>
        Find and instantly quote the machine you want from our portal, through our <b>Machinery</b> module.
        </li>
        <li>
          We wait for you in the USA to inspect the machine of your interest. Or if you prefer, we will inspect it for you.
        </li>
        <li>
          Pay for your machine and receive it at your door or work site in less than 5 days. Fully legalized and invoiced.
        </li>
      </ol>`,
    headerTitle: 'Sale of used construction machines',
    pageTitle: 'Welcome to IMCMEXICO',
    pageDescription: 'We are leaders in importing and selling used construction machinery. backhoes, compactors, excavators, motoformers.',
  },
  machinery: {
    category: 'Category',
    categoryPlaceholder: 'Select a Category',
    contactEmail: 'Email',
    contactName: 'Contact',
    contactPhoneCell: 'Cell',
    contactPhoneMain: 'Main Phone',
    contactPhoneSecondary: 'Secondary Phone',
    description: 'Description',
    errorWhileFetchingMachinesList: 'Error while fetching Machines List',
    errorWhileFetchingMachineDetails: 'Error while fetching Machine Details',
    filter: 'Filter',
    headerTitle: 'Construction Equipment Catalog',
    hours: 'Hours',
    includeRecentlySold: 'Include Recently Sold Machines',
    invalidCategory: 'Invalid Category',
    invalidManufacturer: 'Invalid Manufacturer',
    invalidModel: 'Invalid Model',
    invalidReference: 'Invalid Reference',
    machineAvailable: 'Machine Available',
    machineLocation: 'Location',
    machineNotFound: 'No match found',
    machineValidate: 'Check Availability',
    manufacturer: 'Manufacturer',
    manufacturerPlaceholder: 'Select a Manufacturer',
    model: 'Model',
    modelPlaceholder: 'Select a Model',
    na: 'Not Available',
    nomenclature: 'Nomenclature',
    otherCategories: 'Other Categories',
    otherManufacturers: 'Other Manufacturers',
    otherModels: 'Other Models',
    pageTitle: 'Construction Equipment Catalog',
    pageTitleDetails: 'Machine Details',
    pageDescriptionMain: 'Updated Catalog of available Construction Equipment. Instant Quote of Used Construction Equipment classified by Type, Manufacturer and Model.',
    pageDescriptionFilters: 'We put {FILTERS} at your disposal.',
    pageDescriptionDetails: 'Details of the machine {MACHINE} with reference number {REFERENCE}',
    quotation: 'Quotation',
    quotationDescription: 'Choose the destination and desired platform to move your machine and instantly get a preliminary quote.',
    quotationDestination: 'Destination',
    quotationDestinationToMEX: 'Shipment to Mexico',
    quotationDestinationToUSA: 'Shipment to South America',
    quotationFee: 'Fee',
    quotationFreightMEX: 'Freight in Mexico',
    quotationFreightUSA: 'Freight in USA',
    quotationHandling: 'Handling',
    quotationNote: 'Approximate quotation and valid mainly for non-oversized and one-piece machines.',
    quotationOrigin: 'Origin',
    quotationPlatformFlat: 'Flat (up to 10 ton.)',
    quotationPlatformLowboy: 'LowBoy (up to 40 ton.)',
    quotationPlatformSemi: 'Semi (up to 20 ton.)',
    quotationPlatformType: 'Platform',
    quotationPrice: 'Machine Price',
    quotationShippingInsurance: 'Shipping Insurance',
    quotationTitle: 'Quote this machine to your detination',
    quotationTotal: 'Total',
    reference: 'Reference',
    referencePlaceholder: 'Place a Reference',
    requestMessage: 'Hi, could you give me more information about the following machine?',
    sharingMessage: 'Hi, I\'d like to share with you the information about the following machine:',
    showAllMachines: 'Global',
    showOnlyMexico: 'Only in México',
    sortByPrice: 'Sort by Price Ascending',
    thumbnailActionPDF: 'Download PDF',
    thumbnailActionRequest: 'Request Info',
    thumbnailActionShare: 'Share',
    thumbnailActionViewDetails: 'View Details',
    topCategories: 'Top Categories',
    topManufacturers: 'Top Manufacturers',
    topModels: 'Top Models',
    viewDetails: 'View Details',
    year: 'Year',
  },
  machineryPDF: {
    availability: 'Availability',
    dateFormat: `${DATETIME_FORMATS.usDateFull} [at] ${DATETIME_FORMATS.time12Hours}`,
    header: 'Machinery Management and Quotation List',
    preavailability: 'Preavailability',
    purchaseProject: 'Purchase Project',
    subtitle: 'Detailed Sheet generated on ',
    technicalBrocure: 'Technical Brocure',
  },
  aboutUs: {
    advantagesTitle: 'Advantages',
    advantagesContent: `<p>Every day more users visit IMCMEXICO, it is the preferred web page for the Mexican constructors and now we are expanding our free services, because our growing number of customers are requiring good quality used heavy machines.</p>
      <p>Once you a registered, you will be able to publish through our <b>Machinery Section</b>, for rent or sale your own equipment using your corporate image and company contact information (upon request). Therefore, you can also promote directly to more customers your products and services using your corporate image.</p>
      <p>It is very easy to register, it takes just 5 minutes without any charge. When you become a user of IMCMEXICO, you will have access to the following services:</p>
      <h4>MACHINERY</h4>
      <ul>
        <li>
          Sell or trade your heavy used machinery to more customers from Mexico, United States and Canada and rest of the world.
        </li>
        <li>
          Import or export heavy used machinery located in Mexico or others countries.
        </li>
        <li>
          Rent your own heavy machinery to main construction companies located in Mexico, but no limited to USA and rest of the world.
        </li>
        <li>
          Sell new spare parts or attachments for heavy machines.
        </li>
        <li>
          You can also publish your technical resume for free.
        </li>
      </ul>
      <h4>ENGINEERING</h4>
      <ul>
        <li>
          Customized search of products and services related to the engineering industry.
        </li>
        <li>
          Trade or acquisition Engineering Services and Products from Mexico, United States, Canada and rest of the World.
        </li>
        <li>
          Publication of your Technical Profile or search of other Specialists.
        </li>
        <li>
          Publication of your Engineering Services and Products.
        </li>
        <li>
          Publication of your Company's Corporative Image.
        </li>
      </ul>
      <h4>CONSTRUCTION</h4>
      <ul>
        <li>
          Customized search of products and services related to the construction industry.
        </li>
        <li>
          Trade or acquisition of Construction Services and Products from Mexico, United States, Canada and the rest of the World
        </li>
        <li>
          Publication of your Company's Corporative Image.
        </li>
        <li>
          Publication of your Construction Products and Services.
        </li>
      </ul>`,
    content: 'Trading with IMCMEXICO heavy used machines or publishing any product or services related with the growing construction market never been that easy.',
    headerTitle: 'About Us',
    missionTitle: 'Mission',
    missionContent: `<p>To consolidate us, as leader company of strategic support for constructors, for the commercialization of used equipment through Internet, with collaboration of personal assistance with quality and professional ethics.</p>
      <p>Our Mission during 2013 is join efforts and partnership with more constructors who are looking to purchase heavy machines.</p>
      <p>Is well known that many countries, but in special Mexico, is an important final destination of used heavy machinery for construction sold from USA and others developed countries.</p>
      <p>That's why from almost 10 years ago, we have purchased and moved into Mexico and others countries, with care and safety machines to our final customers mostly inside of Mexico. Our deliveries include satellite tracking and insurance.</p>
      <p>Some of your customers require our transfer services and import into Mexico for less money and time. We give a hand when needed at no extra charge to the seller.</p>
      <p>We offer free advertisement, to announce any machine in IMCMEXICO, it is free, easy and fast. When a machine is published in <a href="https://imcmexico.mx" target="_blank">imcmexico.mx</a>, our provider don't have to lost time to answer phone calls, because we will take all the phone calls when the provider needed, and when we contact the owner only when have a serious customer with strong intentions to purchase.
      <p><a href="https://imcmexico.mx" target="_blank">imcmexico.mx</a>, is an open source for everyone for free posting. Our web site is the most seen in Mexico. Many US machinery providers and independent brokers are publishing their machines for free, and also it is free for everyone who want to sell heavy machines for construction in Mexico and other important Spanish countries.</p>
      <p>We are strong in logistics to move machines and delivery port to port with satellite and insurance coverage.</p>`,
    pageTitle: 'About IMCMEXICO',
    pageDescription: 'IMCMEXICO is an industry leader in the sale of used machinery for construction, Get to know us our reputation and experience are guarantee.',
  },
  faq: {
    answer01: 'IMCMEXICO reaches a wide range of companies in Mexico, USA, Canada and Europe.',
    answer02: 'Yes, we have agreements for several construction Mexican companies to renew their heavy equipments.',
    answer03: 'The companies that are part of our database are international companies based in the US, Canada, Mexico and Europe, which have shown a serious commitment to provide high quality equipment and services for the construction industry.',
    answer04: 'Due to the current Mexico\'s economical stability, more constructions are talking place, especially as far as roads and big construction is concerned. Besides, there are no big heavy machinery factories based in Mexico.',
    answer05: 'Every day IMCMEXICO is improving its system and investing in promoting the heavy machineries posted by our providers in our Web site in order to reach basically all the Mexican construction companies.',
    answer06: 'Yes, the prices that we handled in our portal are list prices. They can vary, depending on the agreement we reach with the providers at the moment to acquire the equipments.',
    answer07: 'The promotion will be effective throughout the year.',
    answer08: 'If you need a machine that is not listed in our Web Site, click on "Requests" (left side), fill the form and we will find it for you.',
    answer09: 'Because our Mexican customers are requiring more machines and they need efficient media to see the catalogue of diverse machines posted into our professional and well-developed Web Site.',
    answer10: 'Because is the best way to cover our growing market in Mexico. Our customers require photos and technical data of the machines posted, along with prices, location and serial numbers to quickly make comparisons and decisions.',
    answer11: 'You just need to be registered in our Web Site and you will automatically have access to all the services we offer.',
    answer12: 'They are companies or people that own heavy machineries and that are willing to trade them through our user friendly website, which is open to everybody who wants to use it.',
    answer13: 'You just need to be registered in our Web Site, and to have included the data and pictures for each machine posted.',
    answer14: 'You have to click on the REGISTRY link and chose the option that is more according to your intentions. Then, fill out the form that appears on the screen and, you will get access by yourself.',
    answer15: 'Filling the registration form takes only two minutes, and inserting data and pictures will take you just 3 minutes per machine.',
    answer16: 'Because all the information you post into IMCMEXICO system is automatically sent to the email specified in your register form. This way, you get the confirmation of your username and password that allow you to manage your own data at any time.',
    answer17: 'Once you get your user name and password, you\'ll have to enter them at www.imcmexico.com.mx every time you use IMCMEXICO services.',
    answer18: 'Nothing. Registering in our Web site is totally free.',
    answer19: 'One of the great advantages of being a registered user of IMCMEXICO is that you will be able to generate your own Projects of Purchase of used machinery in an automated and reliable way. Most importantly, you will be able to receive more precise quotations of your possible purchases of machinery, since you will be able to easily get, in real time, a customized follow up of the heavy machinery you are interested in. If you are advertising products and services, you will be able to change, update and delete that information at any time, once logged on the system.',
    answer20: 'You can enter our Web Site and conduct searches anytime, free of charge. Should you want to promote your products and services too, you\'ll have to pay later on, but the first month is free of charge.',
    answer21: 'When we have a client interested in buying equipment from your inventory, our personnel will ask you if that equipment is still available and which would be the lowest price you would accept for it. If there is agreement with the client\'s expectations, then we will be able to negotiate.',
    answer22: 'Yes, but only if you wish to use your own logo and contact information. In this case, we charge a commission of $5 USD for each equipment during the first month and from the second month, onwards we charge $10 USD. You will be able to obtain 50% of discount in all your publicity during 6 months, if you invite another company to register as a user of our system. You can insert up to four pictures for each machine, no matter how many machines you want to advertise. We set no limits for that. If you choose this alternative, we won\'t know who the buyer is, since he will get in touch with you. However, if you need our attendance, IMCMEXICO will be ready to help you with your foreigner clients whenever you ask.',
    answer23: 'No, if you want to use the services of IMCMEXICO, the information that appears on the systems is the one of our offices, for your own security and privacy. In this case, you don\'t have to pay any fee for using our system, and there is not any limit to put data of the machines you want to promote.',
    answer24: 'Yes, only when the machine is sold by IMCMEXICO broker services you will have to pay a fee. In this case, we ask for 1% commission of the total value of the machine, paid by the seller, in exchange for our assistance to you and the buyer in everything until the delivery of the equipment is completed.',
    answer25: 'None. There are not any hidden costs, fees, or other expenses apart from what is described above.',
    answer26: 'Among the services provided by IMCMEXICO figure the management of buying and selling of equipment and, in an international level, we assist with the inspections, preparation of the Nafta Certificate, invoices, transportation, customs, taxes and delivery.',
    answer27: 'IMCMEXICO provides you with a new big window for machinery trading in Mexico, through which you will be able to display for free any equipment you have for selling or renting. You always have the total control to change the scheme for each machine, defining when or which ones are for sell, for rent, or just to keep in your inventory. If you prefer to pay for advertising and you actually pay for it, you can insert your own logo, mission, and all the contact information that you want to display to be contacted by your customers.',
    answer28: 'You have to become a member, which is free, by registering your email, name and telephone number on IMCMEXICO portal. Here, you can generate your username and password by filling the form of registry',
    answer29: 'Yes, you can buy machinery from abroad by using the management services of IMCMEXICO.',
    answer30: 'In order to generate a list, the client can use the machinery search engine implemented on the system and he can also browse our catalogue to select the machines that interest him, by activating the button "Generate my machinery lists". In order to know more about this topic, please read the FAQ\'s MACHINERY.',
    description: 'Click on a question to display its corresponding answer.',
    headerTitle: 'Frequently Asked Questions',
    question01: 'What is IMCMEXICO market?',
    question02: 'Does IMCMEXICO have agreements with construction Mexican companies?',
    question03: 'How big are the companies that advertise in IMCMEXICO Web site?',
    question04: 'Why has MEXICO increased the acquisition of heavy machinery from overseas?',
    question05: 'How can IMCMEXICO support their customers in their machinery acquisition?',
    question06: 'Are the quotations that appear on IMCMEXICO portal for real?',
    question07: 'How long will the promotions that appear in the table of services and costs be effective?',
    question08: 'If I require a machine that is not in the IMCMEXICO Web Site, can I request your services to help me find it?',
    question09: 'Why is IMCMEXICO growing so fast?',
    question10: 'Why is it important for my company to post machines into the IMCMEXICO system?',
    question11: 'How I can access the services offered by IMCMEXICO?',
    question12: 'Who are the users who will see the machines posted into IMCMEXICO data system?',
    question13: 'What do I need to publish my inventory?',
    question14: 'How do I register in IMCMEXICO database?',
    question15: 'How long does it take to register and to insert data for each machine?',
    question16: 'Why is it important to be registered and to post data into IMCMEXICO data system?',
    question17: 'What do I have to do upon registration?',
    question18: 'How much does it cost to be registered in your Web site?',
    question19: 'What kind of advantages do I have as an IMCMEXICO registered user?',
    question20: 'Is there any condition or fee for the use of the IMCMEXICO\'s Web Site system?',
    question21: 'What happens if a client is interested in a machine from my inventory?',
    question22: 'Does the owner of the equipment\'s information appear on IMCMEXICO Web Site?',
    question23: 'Is there any cost for publishing my machines without showing my data?',
    question24: 'If the machine is sold, does IMCMEXICO charge a fee?',
    question25: 'Are there any cost, commission or other expense associated to the advertising of our machines in IMCMEXICO Web Site?',
    question26: 'What is IMCMEXICO\'s involvement in the sale of our equipment, who does the invoicing, delivery arrangements and others?',
    question27: 'Why is a good decision to advertise my equipments in IMCMEXICO web site?',
    question28: 'How do I obtain access to the purchase or rent of machinery through the IMCMEXICO?',
    question29: 'Can I import machinery from abroad through IMCMEXICO?',
    question30: 'How do I generate a list with the machinery I am interested in?',
    pageTitle: 'Frequently Asked Questions',
    pageDescription: 'Find here the answers to the most common questions that have been made to IMCMEXICO.',
  },
  publishMachine: {
    headerTitle: 'Publish Machine',
    pageTitle: 'Publish Machine',
  },
  help: {
    content: '',
    description: '',
    headerTitle: 'Help',
    pageTitle: 'Help',
  },
  signUp: {
    headerTitle: 'User Signup',
    pageTitle: 'User Signup',
    existingAccountDialogTitle: 'Account already exists',
    emailJSSignupSubject: 'IMCMEXICO - User Signup Confirmation',
    emailJSSignupBody: 'At IMCMEXICO we are pleased that you are interested in signing up with us. Please click on the following link to validate your email. This link will be valid only for 24 hours.',
    emailJSSignupError: 'We are sorry, but an error occurred while sending you the signup confirmation email. Please try again.',
    emailJSSignupSuccess: 'The signup confirmation email has been sent successfully! Please check your inbox.',
    emailJSValidateEmailSubject: 'IMCMEXICO - Email Validation',
    emailJSValidateEmailBody: 'This email has been sent to you because at IMCMEXICO we have detected that you could not complete your previous signup process because you did not validate your email. Please click on the following link to validate your email. This link will be valid only for 24 hours.',
    emailJSValidateEmailError: 'We are sorry, but an error occurred while sending you the validation email. Please try again.',
    emailJSValidateEmailSuccess: 'The validation email has been sent successfully! Please check your inbox.',
    emailJSWelcomeSubject: 'IMCMEXICO - Welcome to our system',
    emailJSWelcomeBody: 'IMCMEXICO warmly welcomes you and appreciates the opportunity to serve you. Please click on the following link to login in our system.',
    emailJSWelcomeError: 'We are sorry, but an error occurred while sending your welcome email. Please try again.',
    emailJSWelcomeSuccess: 'The welcome email has been sent successfully! Please check your inbox.',
    googleSignUp: 'Prefill form with Google',
    fieldCity: 'City',
    fieldConfirmPassword: 'Confirm Password',
    fieldCountry: 'Country',
    fieldMobile: 'Mobile Phone',
    fieldName: 'Name',
    fieldState: 'State',
    fieldUsername: 'Username',
    fieldPassword: 'Password',
    fieldPhoneMain: 'Phone Number',
    fieldSecondaryEmail: 'Secondary Email',
    formErrorDialogTitle: 'Form with Errors',
    formErrorDialogContent: 'We are sorry, but some fields contain errors. Please fix them and try again.',
    formIncompleteDialogTitle: 'Form Incomplete',
    formIncompleteDialogContent: 'Some required fields have not been provided. Please fill out all the fields marked with an asterisk and try again.',
    formPasswordMismatchTitle: 'Form with Errors',
    formPasswordMismatchContent: 'We are sorry, but your passwords mismatch. Please fix them and try again.',
    mustBe10Chars: 'Must be at least 10 characters',
    mustContainNumber: 'Must contain at least 1 numeric character',
    mustContainLowercase: 'Must contain at least 1 lowercase character',
    mustContainUppercase: 'Must contain at least 1 uppercase character',
    mustContainSpecial: 'Must contain at least 1 special character',
    passwordsMismatch: 'Make sure your passwords match.',
    placeholderCity: 'City where you are located',
    placeholderConfirmPassword: 'Confirm Password',
    placeholderCountry: 'Choose a Country',
    placeholderMobile: 'Mobile Phone Number',
    placeholderName: 'Full name',
    placeholderState: 'Choose a State',
    placeholderUsername: 'Email (username)',
    placeholderPassword: 'Password',
    placeholderPhoneMain: 'Phone Number',
    placeholderSecondaryEmail: 'Secondary Email',
    userExists: 'You already have an account with us. Do you want to Login?',
    userExistsButtonLabel: 'Login',
    userNotAllowed: 'Your account already exists but your email has not been validated. Do you want us to send you a validation email?',
    userNotAllowedButtonLabel: 'Send Email',
  },
  signIn: {
    headerTitle: 'User Login',
    pageTitle: 'User Login',
    content: 'Welcome back!',
    subContent: 'Sign in now to explore your account',
    forgotPassword: 'Forgot Password?',
    googleSignIn: 'Login with Google',
    password: 'Password',
    signIn: 'Login',
    signOut: 'Sign Out',
    username: 'Username/Email',
    orLoginVia: 'Or login via'
  },
  forgotPassword: {
    headerTitle: 'Forgot Password',
    pageTitle: 'Forgot Password',
    pageDescription: 'IMCMEXICO offers you the chance to reset your password in case you forgot it.',
    content: 'Please, provide your username (email) and click on the Submit button, so we can send you an email for resetting your password.',
    emailJSPasswordResetRequestSubject: 'IMCMEXICO - Password Reset Request',
    emailJSPasswordResetRequestBody: 'We received a Password Reset request on your behalf. If you did not make this request, please ignore it. Otherwise, click on the link below to reset your password. This link will only be valid for 24 hours.',
    emailJSPasswordResetRequestError: 'We are sorry, but an error occurred while sending you the password reset request email. Please try again.',
    emailJSPasswordResetRequestSuccess: 'The password reset request email has been sent successfully! Please check your inbox.',
  },
  passwordReset: {
    headerTitle: 'Password Reset',
    pageTitle: 'Password Reset',
    congratulations: 'Congratulations, your password has been reset successfully! You can click on the Sign In Now button to access our system or you can explore other options through our menu.',
    content: 'Please, type your new password and its confirmation to make sure you typed it correctly.',
    emailJSPasswordResetSubmitSubject: 'IMCMEXICO - Password Updated',
    emailJSPasswordResetSubmitBody: 'Congratulations, your password has been reset successfully! Please click on the following link to login in our system.',
    emailJSPasswordResetSubmitError: 'We are sorry, but an error occurred while sending you the password reset request email. Please try again.',
    emailJSPasswordResetSubmitSuccess: 'The password reset request email has been sent successfully! Please check your inbox.',
    notification: 'Welcome to the Password Reset page.',
    resendPasswordResetButton: 'Resend Request Email',
    signInNow: 'Sign In Now',
  },
  usersHome: {
    headerTitle: 'Welcome to IMCMEXICO',
    pageTitle: 'Welcome to IMCMEXICO',
    content: '<p>Importation, marketing and tracking of construction machine opportunities.</p><p>If you cannot find a machine on our portal, we will obtain it from the national or international market, with door-to-door service at a highly competitive price.</p><p>If you have a machine you do not need anymore, we will promote it for sale, or we can take it as a downpayment for another one.</p>',
  },
  myMachinery: {
    headerTitle: 'My Machinery',
    pageTitle: 'My Machinery',
  },
  requests: {
    headerTitle: 'Requests',
    pageTitle: 'Requests',
    requestId: 'Request ID',
    userCompanyName: 'Company Name',
    userContactPerson: 'Contact Person',
    requestStatus: 'Status',
    requestDate: 'Issued Date',
    all: 'All',
    pending: 'Pending',
    solved: 'Solved',
    customer: 'Customer',
    unsolved: 'Unsolved',
    sale: 'Sale',
    rent: 'Rent',
    spareParts: 'Spare Parts',
  },
  messages: {
    headerTitle: 'Messages',
    pageTitle: 'Messages',
  },
  account: {
    headerTitle: 'Account',
    pageTitle: 'Account',
  },
  users: {
    headerTitle: 'Users',
    pageTitle: 'Users',
    fieldAddress: 'Address',
    fieldCity: 'City',
    fieldCompany: 'Company/Individual Name',
    fieldConfirmPassword: 'Confirm Password',
    fieldContactPerson: 'Contact Person',
    fieldContactSchedule: 'Contact Schedule',
    fieldCountry: 'Country',
    fieldFax: 'Fax',
    fieldManagingDirector: 'Managing Director',
    fieldMobile: 'Mobile Phone',
    fieldState: 'State',
    fieldUsername: 'Username',
    fieldPassword: 'Password',
    fieldPhoneMain: 'Phone Number',
    fieldPhoneSecondary: 'Secondary Phone Number',
    fieldSecondaryEmail: 'Secondary Email',
    fieldWebsite: 'Website',
    placeholderAddress: 'Address',
    placeholderCity: 'City where your company is located',
    placeholderCompany: 'Company/Individual Name',
    placeholderConfirmPassword: 'Confirm Password',
    placeholderContactPerson: 'Name of the contact person',
    placeholderContactSchedule: 'Specify the schedule where we can contact you',
    placeholderCountry: 'Choose a Country',
    placeholderFax: 'Fax Number',
    placeholderManagingDirector: 'Managing Director\'s name',
    placeholderMobile: 'Mobile Phone Number',
    placeholderState: 'Choose a State',
    placeholderUsername: 'Email (username)',
    placeholderPassword: 'Password',
    placeholderPhoneMain: 'Phone Number',
    placeholderPhoneSecondary: 'Secondary Phone Number',
    placeholderSecondaryEmail: 'Secondary Email',
    placeholderWebsite: 'Website starting with http or https',
  },
  userValidateEmail: {
    headerTitle: 'Account Validation',
    pageTitle: 'Account Validation',
    emailValidationSuccess: 'Congratulations! Your account has been successfully validated in our system.',
    loginAccess: 'Or if you wish, click on the Login button to start enjoying our services.',
    resendValidationEmailButton: 'Re-send Validation Email',
    resendValidationEmailMessage: 'Please click on the Re-send Validation Email button se we can email you a new code.',
    thankYou: 'Thank you for your interest signing-up with us!',
    userAlreadyValidated: 'However, your account has already been validated previously. Please click on the Login button to start enjoying our services.',
    userNotFound: 'However, the provided validation code does not exist in our records. Please check your validation code and try again.',
    welcomeEmail: 'Similarly, we have sent you a welcome email with a link for logging in.',
  },
  privacyPolicies: {
    headerTitle: 'Privacy Policies, Terms & Conditions',
    pageTitle: 'Privacy Policies, Terms & Conditions',
    policy: 'IMCMEXICO works with certain Personal Data, which are provided previously and voluntarily by the Users of IMCMEXICO, therefore, this latter uses those data Privately and for the Exclusive Use of the IMCMEXICO Website. IMCMEXICO uses the technology called cookie. Our cookies are only associated with an anonymous user and your computer and do not provide references that allow to deduce the name of the user. IMCMEXICO cookies cannot read data from your hard disk or read cookie files created by other providers. IMCMEXICO encrypts the data of the user for greater security, which allows you to recognize users previously registered, only if the user wants and what allows your browser. By what os you accept cookies, simply, the users will be required to register to browse in some modules of the website. Given that we are only accepting cookies, simply, the Users will be required to register to browse in some modules of the website. IMCMEXICO requests additional personal information for the Publication of Advertisements, which are preserved in a confidential manner, not being sold or shared with third parties. IMCMEXICO does not use this information for any other purpose than to make the site functional, as well as to inform your customers and users of new features and promotions of own IMCMEXICO or its registered members.',
  },
  pageNotFound: {
    content: 'Please, navigate through the top menu or select any of the following options:',
    headerTitle: 'Page Not Found',
    linkAboutUs: 'About IMCMEXICO',
    linkFAQ: 'FAQ',
    LinkHelp: 'Help',
    linkHome: 'Home Page',
    linkMachinery: 'Machinery Catalog',
    pageTitle: 'Can we help you find what you are looking for?',
    pageDescription: 'Can we help you find what you are looking for?',
  },
}

export default en