import { Theme } from '@mui/material/styles'
import { pxToRem } from 'theme/typography'

export default function Link(theme: Theme) {
  const { breakpoints, palette, shape } = theme

  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          [breakpoints.up('md')]: {
            marginBottom: pxToRem(1),
          },
          [breakpoints.up('xl')]: {
            marginBottom: pxToRem(1),
          },
          marginBottom: pxToRem(1),
          width: '100%',
          color: palette.common.white,
          backgroundColor: palette.blue.light,
          boxShadow: 'none',
          borderWidth: pxToRem(1),
          borderStyle: 'solid',
          borderColor: palette.app.border,
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: pxToRem(1),
            '& .MuiAccordionSummary-root': {
              minHeight: pxToRem(48),
              '& .MuiAccordionSummary-content': {
                marginTop: pxToRem(8),
                marginBottom: pxToRem(8),
              },
            },
          },
          '&:first-of-type': {
            borderTopLeftRadius: shape.borderRadius,
            borderTopRightRadius: shape.borderRadius,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: shape.borderRadius,
            borderBottomRightRadius: shape.borderRadius,
            '& .MuiCollapse-root': {
              borderBottomLeftRadius: shape.borderRadius,
              borderBottomRightRadius: shape.borderRadius,
            },
          },
          '& .MuiAccordionSummary-root': {
            minHeight: pxToRem(48),
            '& .MuiAccordionSummary-content': {
              marginTop: pxToRem(8),
              marginBottom: pxToRem(8),
            },
          },
          '& .MuiTypography-root': {
            marginBottom: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: palette.common.white,
          },
          '& .MuiCollapse-root': {
            color: palette.common.black,
            backgroundColor: palette.common.white,
            '& .MuiTypography-root': {
              marginBottom: 0,
            },
            '& .MuiAccordionDetails-root': {
              paddingTop: pxToRem(8),
              paddingBottom: pxToRem(8),
            }
          },
        },
      },
    },
  }
}