import { NotificationType } from 'components/utils/enums'
import { Action } from 'redux/utils/enums'
import { ShowAlertParams } from 'redux/utils/alerts.types'
import { ErrorProps } from 'utils/types'

export const showAlert = (alertProps: ShowAlertParams) => ({
  type: Action.ALERT_SHOW,
  payload: alertProps,
})

export const hideAlert = () => (dispatch, getState) => {
  const { alertStore: { isOpen } } = getState()

  if (isOpen) {
    dispatch({
      type: Action.ALERT_HIDE,
    })
  }
}

export const throwError = (errorProps: ErrorProps) => async(dispatch, getState) => {
  const {
    languageStore: {
      dictionary: { error: errorDictionary },
    },
  } = getState()

  // Extract the error props.
  const {
    error,
    errorConsole,
    errorStatus,
    errorMessage,
  } = errorProps

  // Log the error into the console.
  console.error(`${errorStatus}: ${errorConsole}`)
  console.error(error)

  // Set the props to be displayed into the Alert.
  const alertProps: ShowAlertParams = {
    type: NotificationType.error,
    title: errorDictionary.somethingWentWrong,
    message: errorMessage,
  }

  // Display the alert with the defined props.
  dispatch(showAlert(alertProps))

  // Sign the user out when the error was because an expired token.
  // if (errorCode === 401) {
  //   dispatch(signOut())
  // }
}