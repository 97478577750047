import { HighlightOff as CloseIcon } from '@mui/icons-material'
import { Modal } from '@mui/material'
import {
  ActionButton,
  LabelWithoutMargin,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  Row,
} from 'components/styledComponents'
import React, { FC, ReactNode } from 'react'
import { pxToRem } from 'theme/typography'

type Props = {
  isOpen: boolean
  title: string
  closable?: boolean
  children?: ReactNode
  footer?: ReactNode
  onClose: () => void
}

export const CustomModal: FC<Props> = ({
  isOpen,
  title,
  closable,
  children,
  footer,
  onClose,
}) => {
  const template = closable ? '1fr 24px' : '1fr'

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer width={{ xs: pxToRem(250)}}>  
        <ModalHeader>
          <Row groupedByTemplate={template}>
            <LabelWithoutMargin variant="body1">
              {title}
            </LabelWithoutMargin>
            {closable && (
              <ActionButton className="action-button" onClick={onClose}>
                <CloseIcon />
              </ActionButton>
            )}
          </Row>
        </ModalHeader>
        {children && (
          <ModalBody>
            {children}
          </ModalBody>
        )}
        {footer && (
          <ModalFooter>
            {footer}
          </ModalFooter>
        )}
      </ModalContainer>
    </Modal>
  )
}