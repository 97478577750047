import { Pagination } from '@mui/material'
import { NotificationAlert } from 'components/FormFields'
import { NotificationType } from 'components/utils/enums'
import { useCleanBasePath, usePathFilters, useWindowDimensions } from 'hooks'
import kebabCase from 'lodash/kebabCase'
import PageContainer from 'pages/components/PageContainer'
import MachineryFilter from 'pages/Machinery/MachineryFilter'
import ThumbnailList from 'pages/Machinery/ThumbnailList'
import { getCurrentDevice } from 'pages/Machinery/utils/libs'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCategories, getMachines, getManufacturers, getModels } from 'redux/actions/machinery'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import {
  GetCategoriesParams,
  GetMachinesParams,
  GetManufactorersParams,
  GetModelsParams,
  MachinesResponse,
} from 'redux/utils/machinery.types'
import { ReduxStore } from 'redux/utils/types'
import { DROPDOWN_UNSELECTED_OPTION, GUEST_MENU_MAP } from 'utils/constants'
import { KeyValueRecord } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  categoriesMap: KeyValueRecord<string>
  manufacturersMap: KeyValueRecord<string>
  modelsMap: KeyValueRecord<string>
  machinesList: MachinesResponse[]
  machinesError?: string
  totalPages: number
  dispatchGetCategories: (params: GetCategoriesParams) => void
  dispatchGetManufacturers: (params: GetManufactorersParams) => void
  dispatchGetModels: (params: GetModelsParams) => void
  dispatchGetMachines: (params: GetMachinesParams) => void
}

const Machinery: FC<Props> = ({
  dictionary: { machinery: machineryDictionary },
  language,
  categoriesMap,
  manufacturersMap,
  modelsMap,
  machinesList,
  machinesError,
  totalPages,
  dispatchGetCategories,
  dispatchGetManufacturers,
  dispatchGetModels,
  dispatchGetMachines,
}) => {
  const { pathname: dirtyPathname } = useLocation() || {}
  const pathname = useCleanBasePath(dirtyPathname)
  const navigate = useNavigate()
  const [currentPath, setCurrentPath] = useState<string>('')
  const [categoryName, setCategoryName] = useState<string>(DROPDOWN_UNSELECTED_OPTION)
  const [manufacturerName, setManufacturerName] = useState<string>(DROPDOWN_UNSELECTED_OPTION)
  const [modelName, setModelName] = useState<string>(DROPDOWN_UNSELECTED_OPTION)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isSortAscending, setIsSortAscending] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [shouldGetMachines, setShouldGetMachines] = useState<boolean>(false)
  const [ogDescription, setOGDescription] = useState<string | undefined>()
  const { filterItemsLength, categoryFilter, manufacturerFilter, modelFilter } = usePathFilters(pathname)
  const { windowWidth } = useWindowDimensions()
  const { isSmartphone, isSmartphoneXs, isDesktop, isTablet } = getCurrentDevice()

  const itemsPerPage = 12
  const categorySelected = categoryName !== DROPDOWN_UNSELECTED_OPTION ? categoryName : undefined
  const manufacturerSelected = manufacturerName !== DROPDOWN_UNSELECTED_OPTION ? manufacturerName : undefined
  const modelSelected = modelName !== DROPDOWN_UNSELECTED_OPTION ? modelName : undefined

  let siblingCount: number = -1
  let boundaryCount: number = 1

  if (isSmartphoneXs && windowWidth >= 320) {
    siblingCount = 0
  } else if (isSmartphone && !isSmartphoneXs) {
    siblingCount = 1
  } else if (isTablet) {
    siblingCount = 3
    boundaryCount = 2
  } else if (isDesktop) {
    siblingCount = 5
    boundaryCount = 3
  }

  /**
   * Updates the list of "Manufacturers" based on the provided "Category".
   * @param newCategoryName  The selected Category from the Category picker.
   */
  const handleCategoryChange = (newCategoryName: string): void => {
    // Exit the function when the Map for Categories has not been populated.
    if (!categoriesMap) {
      return
    }

    // Update the "Category" with the new provided value.
    setCategoryName(newCategoryName)

    // Unselect the values for the "Manufacturer" and the "Model" since their options will be replaced with the new values
    // based on the selected "Category".
    setManufacturerName(DROPDOWN_UNSELECTED_OPTION)
    setModelName(DROPDOWN_UNSELECTED_OPTION)

    const filters: string = newCategoryName
    const newOGDescription: string = machineryDictionary.pageDescriptionFilters.replace('{FILTERS}', filters)
    setOGDescription(newOGDescription)

    // Set the params to be sent to the API for getting the list of "Manufacturers" related to the selected "Category".
    const params: GetManufactorersParams = {
      categoryName: newCategoryName,
      basedOnMachines: true,
    }

    // Trigger the Redux action for fetching the list of Manufacturers.
    dispatchGetManufacturers(params)
  }

  /**
   * Updates the list of "Models" based on the provided "Manufacturer".
   * @param newManufacturerName The selected Manufacturer from the Manufacturer picker.
   * @param shouldFetchMachines Specifies wheter the Machines list should be fetched or not.
   */
  const handleManufacturerChange = (newManufacturerName: string, shouldFetchMachines = false): void => {
    // Update the "Manufacturer" with the new provided value.
    setManufacturerName(newManufacturerName)

    // Unselect the value for the "Model" since its options will be replaced with the new values based on the selected
    // "Manufacturer".
    setModelName(DROPDOWN_UNSELECTED_OPTION)

    // Updates the "shouldGetMahcines" flag based on the provided value from the params.
    setShouldGetMachines(shouldFetchMachines)

    const filters: string = `${categoryName} ${newManufacturerName}`
    const newOGDescription: string = machineryDictionary.pageDescriptionFilters.replace('{FILTERS}', filters)
    setOGDescription(newOGDescription)

    // Set the params to be sent to the API for getting the list of "Models" related to the selected "Category" and
    // "Manufacturer".
    const params: GetModelsParams = {
      categoryName,
      manufacturerName: newManufacturerName,
      basedOnMachines: true,
    }

    // Trigger the Redux action for fetching the list of Models.
    dispatchGetModels(params)
  }

  /**
   * Updates the value for the "Model" based on the provided "Manufacturer".
   * @param newManufacturerName The selected Manufacturer from the Manufacturer picker.
   * @param shouldFetchMachines Specifies wheter the Machines list should be fetched or not.
   */
  const handleModelChange = (newModelName: string): void => {
    // Update the "Model" with the new provided value.
    setModelName(newModelName)

    const filters: string = `${categoryName} ${manufacturerName} ${newModelName}`
    const newOGDescription: string = machineryDictionary.pageDescriptionFilters.replace('{FILTERS}', filters)
    setOGDescription(newOGDescription)
  }

  /**
   * Toggles the sorting type between Ascending or Descending.
   * @param ascendingSorting Specifies if the new sorting type is Ascending or not.
   */
  const handleSortChange = (ascendingSorting: boolean): void => {
    // Set the new value for the "isSortAscending" state prop.
    setIsSortAscending(ascendingSorting)
  }

  /**
   * Triggers the action for getting the list of machines based on the provided: Category, Manufacturer and Model.
   * @param category The provided Category to be used for filtering the machines.
   * @param manufacturer The provided Manufacturer to be used for filtering the machines.
   * @param model The provided Model to be used for filtering the machines.
   */
  const getMachinesList = (category?: string, manufacturer?: string, model?: string): void => {
    // Set the final value to be used for the Category, Manufacturer and Model params.
    const paramCategoryName = category !== DROPDOWN_UNSELECTED_OPTION
      ? category || categorySelected
      : undefined
    const paramManufacturerName = manufacturer !== DROPDOWN_UNSELECTED_OPTION
      ? manufacturer || manufacturerSelected
      : undefined
    const paramModelName = model !== DROPDOWN_UNSELECTED_OPTION
      ? model || modelSelected
      : undefined

    // Set the params to be sent to the API for getting the list of "Machines" based on the provided params: Category,
    // Manufacturer, Model.
    const params: GetMachinesParams = {
      language,
      categoryName: paramCategoryName,
      manufacturerName: paramManufacturerName,
      modelName: paramModelName,
      currentPage,
      itemsPerPage,
      sortBy: 'price',
      isSortAscending,
      isOnlyFromMexico: false,
      isForRent: false,
      includeSold: false,
      includeHotlist: false,
      ignoreDuplicates: true,
    }

    // Trigger the Redux action for getting the list of Machines.
    dispatchGetMachines(params)
  }

  /**
   * Analizes the current selected values and redirects the user to the corresponding page by filtering the list of Machines
   * based on the provided params.
   */
  const handleFilterApply = (): void => {
    // Sets the redirecting URL base starting form the "Machinery" path.
    let newURL: string = GUEST_MENU_MAP(language).machinery

    // Update the redirecting URL by adding the "Category" when provided.
    if (categorySelected) {
      newURL += `/${kebabCase(categoryName)}`
    }

    // Update the redirecting URL by adding the "Manufacturer" when provided.
    if (manufacturerSelected) {
      newURL += `/${kebabCase(manufacturerName)}`
    }

    // Update the redirecting URL by adding the "Model" when provided.
    if (modelSelected) {
      newURL += `/${kebabCase(modelName)}`
    }

    // Redirect the user to the new URL.
    navigate(newURL)

    // Get the list of machines.
    getMachinesList()
  }

  /**
   * Hides the current Error Message modal.
   */
  const handleHideError = (): void => {
    setShowError(false)
  }

  /**
   * Updates the value for the "Current Page" based on the new value provided.
   * @param _event The HTML change event (we don't need this).
   * @param newPage The new page number to be used to fetch the data for the Machines list.
   */
  const handlePaginationChange = (_event: ChangeEvent<unknown>, newPage: number): void => {
    setCurrentPage(newPage)
  }

  useEffect(() => {
    // Set the params to be sent to the API for getting the list of "Categories".
    const params: GetCategoriesParams = {
      basedOnMachines: true,
    }

    // Trigger the Redux action for fetching the list of Categories.
    dispatchGetCategories(params)
  }, []) // This will be triggered when the component has been mounted.

  useEffect(() => {
    // Identify wheter the path has changed or not. This could happen in case the user types the URL manually.
    const hasPathChanged: boolean = currentPath !== pathname

    // Update the "currentPath" in case the "pathname" has changed.
    if (hasPathChanged) {
      setCurrentPath(pathname)
    }

    // When no values have been selected from any picker (Category, Manufacturer, Model), then get the full list of
    // machines.
    if (filterItemsLength <= 2) {
      getMachinesList(DROPDOWN_UNSELECTED_OPTION, DROPDOWN_UNSELECTED_OPTION, DROPDOWN_UNSELECTED_OPTION)
    } else {
      // Get the list of machines based on the current selected values from the pickers.
      getMachinesList()
    }
  }, [currentPath, pathname]) // This will be tribbered when the "currentPath" or the "pathname" change.

  useEffect(() => {
    if (categoryFilter !== DROPDOWN_UNSELECTED_OPTION && !!categoriesMap) {
      const category: string = categoriesMap[categoryFilter] || DROPDOWN_UNSELECTED_OPTION
      const shouldFetchMachines = filterItemsLength === 2 || (filterItemsLength === 3 && !!categoriesMap)

      if (category !== categoryName) {
        handleCategoryChange(category)
      }

      if (shouldFetchMachines !== shouldGetMachines) {
        setShouldGetMachines(shouldFetchMachines)
      }
    } else {
      setCategoryName(DROPDOWN_UNSELECTED_OPTION)
      setManufacturerName(DROPDOWN_UNSELECTED_OPTION)
      setModelName(DROPDOWN_UNSELECTED_OPTION)

      const newOGDescription: string = machineryDictionary.pageDescriptionMain
      setOGDescription(newOGDescription)
    }
  }, [categoryFilter, categoriesMap]) // This will be triggered when the "categoryFilter" or the "categoriesMap" have changed.

  useEffect(() => {
    if (manufacturerFilter !== DROPDOWN_UNSELECTED_OPTION && !!manufacturersMap) {
      const manufacturer: string = manufacturersMap[manufacturerFilter] || DROPDOWN_UNSELECTED_OPTION
      const shouldFetchMachines: boolean = filterItemsLength === 4 && !!manufacturersMap

      if (manufacturer !== manufacturerName) {
        handleManufacturerChange(manufacturer, shouldFetchMachines)
      }
      
      if (shouldFetchMachines !== shouldGetMachines) {
        setShouldGetMachines(shouldFetchMachines)
      }
    } else {
      setManufacturerName(DROPDOWN_UNSELECTED_OPTION)
      setModelName(DROPDOWN_UNSELECTED_OPTION)
    }
  }, [manufacturerFilter, manufacturersMap]) // This will be triggered when the "manufacturerFilter" or the "manufacturersMap" have changed.

  useEffect(() => {
    if (modelFilter !== DROPDOWN_UNSELECTED_OPTION && !!modelsMap) {
      const model: string = modelsMap[modelFilter] || DROPDOWN_UNSELECTED_OPTION
      const shouldFetchMachines = filterItemsLength === 5 && !!modelsMap

      if (model !== modelName) {
        setModelName(model)
      }

      if (shouldFetchMachines !== shouldGetMachines) {
        setShouldGetMachines(shouldFetchMachines)
      }
    } else {
      setModelName(DROPDOWN_UNSELECTED_OPTION)
    }
  }, [modelFilter, modelsMap]) // This will be triggered when the "modelFilter" or the "modelsMap" have changed.

  useEffect(() => {
    if (shouldGetMachines) {
      const hasPathChanged: boolean = currentPath !== pathname

      if (hasPathChanged) {
        setCurrentPath(pathname)
      }

      getMachinesList()
    }
  }, [shouldGetMachines]) // This will be triggered only when the "shouldGetMachines" flag has changed.

  useEffect(() => {
    if (machinesList.length) {
      getMachinesList()
    }
  }, [currentPage]) // This will be triggered only when the "currentPage" value has changed.

  useEffect(() => {
    // Evaluates wheter an error alert need to be displayed or not.
    const shouldShowError: boolean = !!machinesError && !showError

    // Updates the value for the "showError" flag only when the current value is different of the previous one.
    if (shouldShowError !== showError) {
      setShowError(shouldShowError)
    }
  }, [machinesError]) // This will be triggered only when the value for "machinesError" has changed.


  return (
    <PageContainer ogDescription={ogDescription}>
      <MachineryFilter
        categoryName={categoryName}
        manufacturerName={manufacturerName}
        modelName={modelName}
        isSortAscending={isSortAscending}
        onCategoryChange={handleCategoryChange}
        onManufacturerChange={handleManufacturerChange}
        onModelChange={handleModelChange}
        onSortChange={handleSortChange}
        onFilterApply={handleFilterApply}
      />
      {!machinesError && (
        <ThumbnailList />
      )}
      <NotificationAlert
        type={NotificationType.error}
        isOpen={showError}
        onHide={handleHideError}
        message={machinesError || ''}
      />
      <Pagination
        variant="outlined"
        color="secondary"
        count={totalPages}
        page={currentPage}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        onChange={handlePaginationChange}
      />
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, machineryStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { categoriesMap, manufacturersMap, modelsMap, machines, machinesError } = machineryStore
  const { machinesList = [], totalPages = 0 } = machines || {}

  return {
    dictionary,
    language,
    categoriesMap,
    manufacturersMap,
    modelsMap,
    machinesList,
    machinesError,
    totalPages,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetCategories: (params: GetCategoriesParams) => dispatch(getCategories(params)),
  dispatchGetManufacturers: (params: GetManufactorersParams) => dispatch(getManufacturers(params)),
  dispatchGetModels: (params: GetModelsParams) => dispatch(getModels(params)),
  dispatchGetMachines: (params: GetMachinesParams) => dispatch(getMachines(params)),
})

const ConnectedMachinery: any = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Machinery)

export default ConnectedMachinery
