export default function Link() {
  return {
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          '&.machine-details': {
            marginBottom: 0,
          },
        },
      },
    }
  }
}