import { useCurrentDevice } from 'hooks'
import kebabCase from 'lodash/kebabCase'
import { MachineSocialMediaData, MachineThumbnail } from 'pages/Machinery/utils/types'
import { useSelector } from 'react-redux'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { GetMachineDetailsResponse } from 'redux/utils/machinery.types'
import { ReduxStore } from 'redux/utils/types'
import { APP_URL, CELL_NUMBER, GUEST_MENU_MAP, WHATSAPP_BASE_URL_DESKTOP, WHATSAPP_BASE_URL_MOBILE } from 'utils/constants'
import { KeyValueRecord } from 'utils/types'

export const useSocialMedia = (thumbnailMachine?: MachineThumbnail): MachineSocialMediaData => {
  const { isMobile } = useCurrentDevice()
  const dictionary: LanguageDictionary = useSelector((state: ReduxStore) => state.languageStore.dictionary)
  const machineryDictionary: KeyValueRecord<string> = dictionary.machinery
  const language: Language = useSelector((state: ReduxStore) => state.languageStore.language)
  const reduxMachine: GetMachineDetailsResponse | undefined = useSelector((state: ReduxStore) => state.machineryStore.machineDetails)
  const machine: MachineThumbnail | GetMachineDetailsResponse | undefined = thumbnailMachine || reduxMachine

  if (!machine?.category) {
    return {
      machineInfo: '',
      machineDetailsURL: '',
      whatsAppBaseURL: '',
      contactInfoMessage: '',
    }
  }

  const { category, manufacturer, model, reference } = machine

  const categoryInfo = `*${machineryDictionary.category}:* ${category}%0A%0D`
  const manufacturerInfo = `*${machineryDictionary.manufacturer}:* ${manufacturer}%0A%0D`
  const modelInfo = `*${machineryDictionary.model}:* ${model}%0A%0D`
  const referenceInfo = `*${machineryDictionary.reference}:* ${reference}%0A%0D`

  const machineDetailsURL = `${GUEST_MENU_MAP(language).machinery}/${kebabCase(category)}/${kebabCase(manufacturer)}`
    + `/${kebabCase(model)}/${reference}`
  const urlInfo = `*URL:* ${APP_URL}${machineDetailsURL}`

  const machineInfo = `${categoryInfo}${manufacturerInfo}${modelInfo}${referenceInfo}${urlInfo}`
  const whatsAppBaseURL = isMobile ? WHATSAPP_BASE_URL_MOBILE : WHATSAPP_BASE_URL_DESKTOP
  const whatsappURLParams = isMobile ? `?phone=${CELL_NUMBER}&` : `${CELL_NUMBER}?`
  const whatsappContactMessage = `${whatsAppBaseURL}${whatsappURLParams}text=${machineryDictionary.requestMessage}`
  const contactInfoMessage = `${whatsappContactMessage}%0A%0D${machineInfo}`

  return {
    machineInfo,
    machineDetailsURL,
    whatsAppBaseURL,
    contactInfoMessage,
  }
}