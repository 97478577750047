import {
  Cancel as InvalidIcon,
  CheckCircle as ValidIcon,
  Key as PasswordGeneratorIcon,
  Visibility as ShowPasswordIcon,
  VisibilityOff as HidePasswordIcon,
} from '@mui/icons-material'
import { Box, Icon, InputAdornment, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ClickableIcon } from 'components/FormFields'
import { CheckboxInput } from 'components/FormFields/CheckboxInput'
import { PasswordContainer, StyledFormControl } from 'components/FormFields/styledComponents'
import { LabelWithoutMargin, Row } from 'components/styledComponents'
import { AdornmentPosition, AlignItems, InputSize, InputType, InputVariant } from 'components/utils/enums'
import { InputProps, PasswordValidation } from 'components/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import React, { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { BorderStyle, ButtonTypes } from 'theme/utils/enums'
import { LOWERCASE_CHARS, NUMBER_CHARS, SPECIAL_CHARS, UPPERCASE_CHARS } from 'utils/constants'
import { generateSecurePassword } from 'utils/libs'

type Props = {
  dictionary: LanguageDictionary
  className?: string
  id?: string
  label: string
  value: string
  placeholder?: string
  size?: InputSize
  fullWidth?: boolean
  showPasswordAsIcon?: boolean
  securePassword?: boolean
  generatePassword?: boolean
  required?: boolean
  isMissing?: boolean
  isMismatchError?: boolean
  disabled?: boolean
  adornmentBgColor?: string
  marginTop?: string | number
  marginBottom?: string | number
  onBlur?: (hasErrors?: boolean) => void
  onChange: (newValue: string) => void
  onKeyDown?: (event: any) => void
  onKeyUpCapture?: (event: any) => void
}

const UnconnectedPasswordInput: FC<Props> = ({
  dictionary: {
    error: errorDictionary,
    shared: sharedDictionary,
    signUp: signUpDictionary,
  },
  className,
  id,
  label,
  value,
  placeholder,
  size = InputSize.small,
  fullWidth,
  showPasswordAsIcon,
  securePassword,
  generatePassword,
  required,
  isMissing,
  isMismatchError,
  disabled,
  adornmentBgColor,
  marginTop = pxToRem(8),
  marginBottom = pxToRem(16),
  onBlur,
  onChange,
  onKeyDown,
  onKeyUpCapture,
}) => {
  const { palette } = useTheme()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isInvalid, setIsInvalid] = useState<boolean>()
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
    mustBe10Chars: false,
    mustContainNumber: false,
    mustContainLowercase: false,
    mustContainUppercase: false,
    mustContainSpecial: false,
  })

  const validationLabelTemplate = `${pxToRem(16)} 1fr`
  // const hasErrors: boolean = isMissing || isMismatchError || !!errorMessage || isInvalid === false
  const inputProps: InputProps = {}

  /**
   * Validates if the password accomplishes with the required validations.
   * @param password The password to be evaluated.
   * @param currentValidation The current validations for the password.
   */
   const handleValidatePassword = (password: string, currentValidation: PasswordValidation): void => {
     // Clone the original validation in order to not affecting the original one since it's a "State" prop.
    const newPasswordValidation: PasswordValidation = cloneDeep(currentValidation)

    // Check if the password commits with the requirements.
    const containsNumber = NUMBER_CHARS.split('').findIndex(char => password.includes(char))
    const containsLowercase = LOWERCASE_CHARS.split('').findIndex(char => password.includes(char))
    const containsUppercase = UPPERCASE_CHARS.split('').findIndex(char => password.includes(char))
    const containsSpecial = SPECIAL_CHARS.split('').findIndex(char => password.includes(char))

    // Set the validation per each requirement.
    newPasswordValidation.mustBe10Chars = password.length >= 10
    newPasswordValidation.mustContainNumber = containsNumber >= 0
    newPasswordValidation.mustContainLowercase = containsLowercase >= 0
    newPasswordValidation.mustContainUppercase = containsUppercase >= 0
    newPasswordValidation.mustContainSpecial = containsSpecial >= 0

    // Validate the password.
    const isValid = Object.values(newPasswordValidation).reduce((acum, cur) => acum && cur, true)

    // Update the password validation.
    setPasswordValidation(newPasswordValidation)

    // Update the flag for confirm if the password is valid or not.
    setIsInvalid(isValid)
  }

  /**
   * Toggles the visibility for the password.
   */
  const handleShowPasswordClick = (): void => {
    if (disabled) {
      return
    }

    setShowPassword((show) => !show)
  }

  /**
   * Generates a new random password.
   */
  const handleGeneratePasswordClick = (currentValidation: PasswordValidation) => (): void => {
    if (disabled) {
      return
    }

    const newPassword: string = generateSecurePassword(10)
    setShowPassword(true)
    handleValidatePassword(newPassword, currentValidation)
    onChange(newPassword)
  }

  /**
   * Handles the event when the input value has changed.
   * @param event The input event handler which contains the typed value.
   */
  const handleChange = (currentValidation: PasswordValidation, isMainPassword?: boolean, isSecurePassword?: boolean) => (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value: newValue } = event.target

    // Validate the password when it is the main one and not the confirmation.
    if (isMainPassword && isSecurePassword) {
      handleValidatePassword(newValue, currentValidation)
    }

    onChange(newValue)
  }

  /**
   * Handles the event when the input loses focus.
   */
  const handleBlur = (currentValidation: PasswordValidation, isMainPassword?: boolean, isSecurePassword?: boolean) => (
    event: FocusEvent<HTMLInputElement>
  ): void => {
    const { value: password } = event.target

    // Validate the password when it is the main one and not the confirmation.
    if (isMainPassword && isSecurePassword) {
      handleValidatePassword(password, currentValidation)
    }

    // Set the input has been touched, which means the input has been focused at least once.
    setIsTouched(true)

    // Check wheter the input value has an error due it's required.
    const isRequiredError = required && !value

    // Keep the normal Blur behavior when there is no error.
    if (!isRequiredError) {
      onBlur?.()
      return
    }

    // Define the error message
    setErrorMessage(errorDictionary.requiredValue)
    onBlur?.(true)
  }

  useEffect(() => {
    // Set an error message when the field is required but it is missing.
    const isRequiredError = required && !value && (isTouched || isMissing)

    // Initialize the error message value.
    let newErrorMessage = ''

    // Set an error message when the field is missing or there is a mismatch on the passwords.
    if (isRequiredError) {
      newErrorMessage = errorDictionary.requiredValue
    } else if (isMismatchError) {
      newErrorMessage = signUpDictionary.passwordsMismatch
    }

    setErrorMessage(newErrorMessage)

    // Set the field as touched when it is required and missing and the "isTouched" flag is off.
    if (required && isMissing && !isTouched) {
      setIsTouched(true)
    }
  }, [value, isMissing, isMismatchError])

  inputProps.endAdornment = showPasswordAsIcon || generatePassword
    ? (
        <InputAdornment position={AdornmentPosition.end}>
          <Row groupedByColumns={showPasswordAsIcon && generatePassword ? 2 : 1} gridGap={0} height="100%">
            {showPasswordAsIcon && (
              <ClickableIcon
                component={showPassword ? HidePasswordIcon : ShowPasswordIcon}
                tooltipTitle={showPassword ? sharedDictionary.hidePassword : sharedDictionary.showPassword}
                bgColor={adornmentBgColor}
                disabled={disabled}
                borderLeftWidth={1}
                borderLeftStyle={BorderStyle.solid}
                borderLeftColor={palette.app.border}
                onClick={handleShowPasswordClick}
              />
            )}
            {generatePassword && (
              <ClickableIcon
                component={PasswordGeneratorIcon}
                tooltipTitle={sharedDictionary.generatePassword}
                bgColor={adornmentBgColor}
                disabled={disabled}
                borderLeftWidth={1}
                borderLeftStyle={BorderStyle.solid}
                borderLeftColor={palette.app.border}
                onClick={handleGeneratePasswordClick(passwordValidation)}
              />
            )}
          </Row>
        </InputAdornment>
      )
    : undefined

  return (
    <PasswordContainer>
      <StyledFormControl
        fullWidth={fullWidth}
        marginTop={marginTop}
        marginBottom={marginBottom}
        variant={InputVariant.outlined}
        size={size}
      >
        <TextField
          className={`Mui-focused ${className}`}
          id={id}
          type={showPassword ? InputType.text : InputType.password}
          placeholder={placeholder}
          label={label}
          value={value}
          size={size}
          variant={InputVariant.outlined}
          InputProps={inputProps}
          error={!!errorMessage || isInvalid === false}
          required={required}
          disabled={disabled}
          focused
          helperText={errorMessage}
          onChange={handleChange(passwordValidation, generatePassword, securePassword)}
          onBlur={handleBlur(passwordValidation, generatePassword, securePassword)}
          onKeyDown={onKeyDown}
          onKeyUpCapture={onKeyUpCapture}
        />
      </StyledFormControl>
      {generatePassword && securePassword && (
        <Box sx={{ mb: { sm: pxToRem(4) }}}>
          {Object.entries(passwordValidation).map(([key, value]) => (
            <Row
              key={key}
              groupedByTemplate={validationLabelTemplate}
              alignItems={AlignItems.center}
              gridGap={2}
            >
              <Icon
                fontSize="inherit"
                color={value ? ButtonTypes.success : ButtonTypes.error }
                component={value ? ValidIcon : InvalidIcon}
              />
              <LabelWithoutMargin
                className="ValidationLabel-root"
                variant="body1"
                color={value ? palette.success.dark : palette.error.main}
              >
                {signUpDictionary[key]}
              </LabelWithoutMargin>
            </Row>
          ))}
        </Box>
      )}
      {!showPasswordAsIcon && (
        <CheckboxInput
          label={sharedDictionary.showPassword}
          color={palette.blue.light}
          fontSize={{ xs: pxToRem(11), md: pxToRem(12) }}
          checkboxSize={{ xs: pxToRem(18), md: pxToRem(18) }}
          checked={showPassword}
          onChange={handleShowPasswordClick}
        />
      )}
    </PasswordContainer>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const PasswordInput = connect(
  mapStateToProps,
)(UnconnectedPasswordInput)