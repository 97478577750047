import { Theme, alpha } from '@mui/material/styles'
import { JustifyContent } from 'components/utils/enums'
import { pxToRem } from 'theme/typography'

export default function Pagination(theme: Theme) {
  const { palette } = theme

  return {
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: pxToRem(16),
          padding: pxToRem(8),
          color: palette.app.pagination.inactive.contrastText,
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: `${JustifyContent.center}`,
          width: '100%',
          '& .Mui-disabled': {
            color: palette.app.pagination.disabled.contrastText,
            backgroundColor: palette.app.pagination.disabled.main,
          },
          '& .MuiPaginationItem-root:hover': {
            color: palette.app.pagination.hover.contrastText,
            backgroundColor: alpha(palette.app.pagination.hover.main, 0.5),
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            color: palette.app.pagination.active.contrastText,
            backgroundColor: palette.app.pagination.active.main,
          },
          '& .MuiPaginationItem-root.Mui-selected:hover': {
            color: palette.app.pagination.active.contrastText,
            backgroundColor: palette.app.pagination.active.main,
          },
        },
      },
    },
  }
}