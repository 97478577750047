import { ResponsiveStyleValue } from '@mui/system'
import { ButtonVariant } from 'components/utils/enums'
import { ButtonTypes, InputColors } from 'theme/utils/enums'
import { Breakpoint } from 'utils/enums'

export const getButtonVariant = (buttonType: ButtonTypes): ButtonVariant => {
  switch (buttonType) {
    case ButtonTypes.primary:
      return ButtonVariant.contained
    case ButtonTypes.secondary:
      return ButtonVariant.outlined
    case ButtonTypes.tertiary:
      return ButtonVariant.contained
    case ButtonTypes.text:
      return ButtonVariant.text
    default:
      return ButtonVariant.contained
  }
}

export const getButtonColor = (buttonType: ButtonTypes, customColor?: InputColors): InputColors => {
  switch (buttonType) {
    case ButtonTypes.primary:
      return InputColors.primary
    case ButtonTypes.secondary:
      return InputColors.secondary
    case ButtonTypes.tertiary:
      return InputColors.secondary
    case ButtonTypes.info:
      return InputColors.info
    case ButtonTypes.warning:
      return InputColors.warning
    case ButtonTypes.error:
      return InputColors.error
    case ButtonTypes.success:
      return InputColors.success
    default:
      return customColor || InputColors.primary
  }
}

export const getBreakpointValue = (currentBreakPoint: Breakpoint, group?: number | ResponsiveStyleValue<string>) => {
  const xsBreakpoints = [Breakpoint.xs, Breakpoint.sm, Breakpoint.md, Breakpoint.lg, Breakpoint.xl]
  const smBreakpoints = [Breakpoint.sm, Breakpoint.md, Breakpoint.lg, Breakpoint.xl]
  const mdBreakpoints = [Breakpoint.md, Breakpoint.lg, Breakpoint.xl]
  const lgBreakpoints = [Breakpoint.lg, Breakpoint.xl]

  let result: number | undefined

  if (!!group && typeof group === 'object') {
    Object.entries(group).forEach(([key, value]) => {
      if (key === Breakpoint.xl && currentBreakPoint === Breakpoint.xl) {
        result = Number(value)
      } else if (key === Breakpoint.lg && lgBreakpoints.includes(currentBreakPoint)) {
        result = Number(value)
      } else if (key === Breakpoint.md && mdBreakpoints.includes(currentBreakPoint)) {
        result = Number(value)
      } else if (key === Breakpoint.sm && smBreakpoints.includes(currentBreakPoint)) {
        result = Number(value)
      } else if (key === Breakpoint.xs && xsBreakpoints.includes(currentBreakPoint)) {
        result = Number(value)
      }
    })
  } else if (!!group && typeof group === 'number') {
    result = group
  }

  return result
}