import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import ReduxThunk from 'redux-thunk'
import History from 'helpers/History'
import reducers from 'redux/reducers'

const logger = createLogger({ collapsed: true })
const middlewares = [
  routerMiddleware(History),
  ReduxThunk,
  logger,
]

const store = createStore(
  reducers,
  compose(
    applyMiddleware(...middlewares),
  ),
)

export default store