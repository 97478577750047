import { GoogleOAuthProvider } from '@react-oauth/google'
import AlertDialog from 'components/Dialogs/AlertDialog'
import Footer from 'components/Navigation/Footer'
import Header from 'components/Navigation/Header'
import UsersMenu from 'components/Navigation/UsersMenu'
import { RightContainer, RootContainer } from 'components/styledComponents'
import { NotificationType } from 'components/utils/enums'
import Routing from 'helpers/Routing'
import momentTz from 'moment-timezone'
import React, { FC, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { connect } from 'react-redux'
import { LoadingBar } from 'react-redux-loading'
import { BrowserRouter } from 'react-router-dom'
import { hideAlert } from 'redux/actions/alert'
import { getMachinesCarousel } from 'redux/actions/machinery'
import { verifySession } from 'redux/actions/session'
import { setTimezone } from 'redux/actions/timezone'
import { ReduxStore } from 'redux/utils/types'
import ThemeConfig from 'theme'
import GlobalStyles from 'theme/globalStyles'
import { pxToRem } from 'theme/typography'
import { APP_NAME, GOOGLE_ID } from 'utils/constants'

type Props = {
  isAlertOpen: boolean
  alertType: NotificationType
  alertTitle: string
  alertMessage: string
  alertButtonLabel?: string
  isSignedIn: boolean
  timezone: string
  dispatchHideAlert: () => void
  dispatchGetMachinesCarousel: () => void
  dispatchSetTimezone: (newTimezone: string) => void
  dispatchVerifySession: () => void
}

const App: FC<Props> = ({
  isAlertOpen,
  alertType,
  alertTitle,
  alertMessage,
  alertButtonLabel,
  isSignedIn,
  timezone,
  dispatchHideAlert,
  dispatchGetMachinesCarousel,
  dispatchSetTimezone,
  dispatchVerifySession,
}) => {
  useEffect(() => {
    const userTimezone = momentTz.tz.guess()

    if (!timezone || timezone !== userTimezone) {
      dispatchSetTimezone(userTimezone)
    }

    dispatchGetMachinesCarousel()
    dispatchVerifySession()
  }, [])

  return (
    <GoogleOAuthProvider clientId={GOOGLE_ID}>
      <HelmetProvider>
        <Helmet defaultTitle={APP_NAME}>
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
          <meta name="robots" content="no-index" />
          <link rel="shortcut icon" href="/favicon.icon" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=B612 Mono:wght@100;200;300;400;500;600;700" />
        </Helmet>
        <ThemeConfig>
          <GlobalStyles />
          <BrowserRouter>
            <LoadingBar />
            <RootContainer>
              <UsersMenu />
              <RightContainer isSignedIn={isSignedIn}>
                <Header />
                <Routing />
                <Footer />
              </RightContainer>
              {isAlertOpen && (
                <AlertDialog
                  isOpen={isAlertOpen}
                  type={alertType}
                  title={alertTitle}
                  message={alertMessage}
                  buttonLabel={alertButtonLabel}
                  width={{ xs: pxToRem(240), sm: pxToRem(320), md: pxToRem(500) }}
                  useTransition
                  closeable
                  onClose={dispatchHideAlert}
                />
              )}
            </RootContainer>
          </BrowserRouter>
        </ThemeConfig>
      </HelmetProvider>
    </GoogleOAuthProvider>
  )
}

export const Head = () =>
  (<>
    <meta name="og:image" content="test" />
  </>)

const mapStateToProps = ({ alertStore, sessionStore, timezoneStore }: ReduxStore) => {
  const {
    isOpen: isAlertOpen,
    type: alertType,
    title: alertTitle,
    message: alertMessage,
    buttonLabel: alertButtonLabel,
  } = alertStore
  const { isSignedIn } = sessionStore
  const { timezone } = timezoneStore

  return {
    isAlertOpen,
    alertType,
    alertTitle,
    alertMessage,
    alertButtonLabel,
    isSignedIn,
    timezone,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchHideAlert: () => dispatch(hideAlert()),
  dispatchGetMachinesCarousel: () => dispatch(getMachinesCarousel()),
  dispatchSetTimezone: (newTimezone: string) => dispatch(setTimezone(newTimezone)),
  dispatchVerifySession: () => dispatch(verifySession()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)