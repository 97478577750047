import { Typography } from '@mui/material'
import React, { FC } from 'react'

type Props = {
  label: string
  focused?: boolean
  error?: boolean
  required?: boolean
  disabled?: boolean
}

const CustomInputLabel: FC<Props> = ({ label, focused, error, required, disabled }) => {
  // Initialize the "className" prop.
  let className: string = 'InputLabel-root'

  // Add the "Mui-focused" class when the "focused" prop has been provided.
  if (focused) {
    className += ' Mui-focused'
  }

  // Add the "Mui-error" class when the "error" prop has been provided.
  if (error) {
    className += ' Mui-error'
  }

  // Add the "Mui-disabled" class when the "disabled" prop has been provided.
  if (disabled) {
    className += ' Mui-disabled'
  }

  return (
    <>
      <Typography className={className} component="label">
        {label}
        {required && (
          <Typography className="MuiInputLabel-asterisk" component="span">
            *
          </Typography>
        )}
      </Typography>
    </>
  )
}

export default CustomInputLabel