import { useEffect, useState } from 'react'
import { WindowDimensions } from 'utils/types'

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window

  return {
    windowWidth,
    windowHeight
  }
}

export const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}