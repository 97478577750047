import { CloseOutlined as CloseIcon } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { ResponsiveStyleValue } from '@mui/system'
import { PrimaryButton, SecondaryButton } from 'components/FormFields'
import { LabelWithoutMargin } from 'components/styledComponents'
import { useGetDialogIcon } from 'hooks'
import React, { FC, forwardRef, ReactElement, Ref } from 'react'
import { connect } from 'react-redux'
import { ConfirmState } from 'redux/utils/confirm.types'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'

const Transition = forwardRef((props: TransitionProps & { children: ReactElement<any, any>}, ref: Ref<unknown>) =>
  <Slide direction="down" ref={ref} {...props} />
)

type Props = ConfirmState & {
  dictionary: LanguageDictionary
  width?: string | ResponsiveStyleValue<string>
  closeable?: boolean
  useTransition?: boolean
  onClose: () => void
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
}

const UnconnectedConfirmDialog: FC<Props> = ({
  dictionary: { shared: sharedDictionary },
  isOpen,
  type,
  title,
  message,
  primaryButtonLabel = sharedDictionary.ok,
  secondaryButtonLabel = sharedDictionary.cancel,
  width,
  closeable,
  useTransition,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => {
  const dialogStyles = {
    '& .MuiDialog-paperScrollPaper': {
      width: width || 'auto',
    },
  }

  const iconType = useGetDialogIcon(type)

  const handleOnClose = (_event, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }

    onClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={useTransition ? Transition : undefined}
        keepMounted
        sx={dialogStyles}
        onClose={handleOnClose}
      >
        <DialogTitle className={`${type} ${closeable ? 'closable' : ''}`}>
          {iconType && (
            <Icon color="inherit" component={iconType} fontSize="small" />
          )}
          {title}
          {closeable && (
            <Icon color="inherit" component={CloseIcon} fontSize="small" onClick={onClose} />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <LabelWithoutMargin>{message}</LabelWithoutMargin>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            label={secondaryButtonLabel}
            onClick={onSecondaryButtonClick}
          />
          <PrimaryButton
            label={primaryButtonLabel}
            onClick={onPrimaryButtonClick}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

const ConfirmDialog = connect(
  mapStateToProps,
)(UnconnectedConfirmDialog)

export default ConfirmDialog