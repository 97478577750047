import {
  THEME_APP_COLORS,
  THEME_COLOR_ACTIONS,
  THEME_COLOR_BLUE,
  THEME_COLOR_COMMON,
  THEME_COLOR_ERROR,
  THEME_COLOR_GREY,
  THEME_COLOR_INFO,
  THEME_COLOR_ORANGE,
  THEME_COLOR_PRIMARY,
  THEME_COLOR_SECONDARY,
  THEME_COLOR_SOCIAL_MEDIA,
  THEME_COLOR_SUCCESS,
  THEME_COLOR_WARNING,
  THEME_COLOR_YELLOW,
} from 'theme/utils/constants'
import { CustomPalette } from 'theme/utils/types'

const palette: CustomPalette = {
  action: { ...THEME_COLOR_ACTIONS },
  app: { ...THEME_APP_COLORS },
  blue: { ...THEME_COLOR_BLUE },
  common: { ...THEME_COLOR_COMMON },
  error: { ...THEME_COLOR_ERROR },
  grey: { ...THEME_COLOR_GREY },
  info: { ...THEME_COLOR_INFO },
  orange: { ...THEME_COLOR_ORANGE },
  primary: { ...THEME_COLOR_PRIMARY },
  secondary: { ...THEME_COLOR_SECONDARY },
  socialMedia: { ...THEME_COLOR_SOCIAL_MEDIA },
  success: { ...THEME_COLOR_SUCCESS },
  warning: { ...THEME_COLOR_WARNING },
  yellow: THEME_COLOR_YELLOW,
}

export default palette