import { DROPDOWN_UNSELECTED_OPTION } from 'utils/constants'
import { PathFilters } from 'utils/types'

export const usePathFilters = (pathname: string): PathFilters => {
  const pathItems: string[] = pathname.split('/')
  const filterItemsLength = pathItems.length
  const categoryFilter = filterItemsLength > 2 ? pathItems[2] : DROPDOWN_UNSELECTED_OPTION
  const manufacturerFilter = filterItemsLength > 3 ? pathItems[3] : DROPDOWN_UNSELECTED_OPTION
  const modelFilter = filterItemsLength > 4 ? pathItems[4] : DROPDOWN_UNSELECTED_OPTION
  const referenceFilter = filterItemsLength > 5 ? pathItems[5] : null

  return {
    filterItemsLength,
    categoryFilter,
    manufacturerFilter,
    modelFilter,
    referenceFilter,
  }
}