import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import kebabCase from 'lodash/kebabCase'
import { DeviceType, MachineSocialMediaData } from 'pages/Machinery/utils/types'
import { Language } from 'redux/utils/enums'
import { APP_URL, CELL_NUMBER, GUEST_MENU_MAP, WHATSAPP_BASE_URL_DESKTOP, WHATSAPP_BASE_URL_MOBILE } from 'utils/constants'
import { KeyValueRecord } from 'utils/types'

export const formatValueToHours = (value: number | string): string => {
  const numericValue = (typeof value === 'string') ? Number.parseInt(value, 10) : value

  return `${numericValue.toLocaleString()} hrs.`
}

export const formatValueToMoney = (value: number | string, prefix = '$', sufix = 'USD'): string => {
  const numericValue = (typeof value === 'string') ? Number.parseFloat(value) : value

  return `${prefix} ${numericValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${sufix}`
}

export const getMachineSocialMediaData = (
  language: Language,
  machineryDictionary: KeyValueRecord<string>,
  isMobile: boolean,
  category: string,
  manufacturer: string,
  model: string,
  reference: string,
): MachineSocialMediaData => {
  if (!category) {
    return {
      machineInfo: '',
      machineDetailsURL: '',
      whatsAppBaseURL: '',
      contactInfoMessage: '',
    }
  }

  const categoryInfo = `*${machineryDictionary.category}:* ${category}%0A%0D`
  const manufacturerInfo = `*${machineryDictionary.manufacturer}:* ${manufacturer}%0A%0D`
  const modelInfo = `*${machineryDictionary.model}:* ${model}%0A%0D`
  const referenceInfo = `*${machineryDictionary.reference}:* ${reference}%0A%0D`

  const machineDetailsURL = `${GUEST_MENU_MAP(language).machinery}/${kebabCase(category)}/${kebabCase(manufacturer)}`
    + `/${kebabCase(model)}/${reference}`
  const urlInfo = `*URL:* ${APP_URL}${machineDetailsURL}`

  const machineInfo = `${categoryInfo}${manufacturerInfo}${modelInfo}${referenceInfo}${urlInfo}`
  const whatsAppBaseURL = isMobile ? WHATSAPP_BASE_URL_MOBILE : WHATSAPP_BASE_URL_DESKTOP
  const whatsappURLParams = isMobile ? `?phone=${CELL_NUMBER}&` : `${CELL_NUMBER}?`
  const whatsappContactMessage = `${whatsAppBaseURL}${whatsappURLParams}text=${machineryDictionary.requestMessage}`
  const contactInfoMessage = `${whatsappContactMessage}%0A%0D${machineInfo}`

  return {
    machineInfo,
    machineDetailsURL,
    whatsAppBaseURL,
    contactInfoMessage,
  }
}

export const getCurrentDevice = (): DeviceType => {
  const { breakpoints } = useTheme()
  const breakpointXs = useMediaQuery(breakpoints.down('sm'))
  const breakpointSm = useMediaQuery(breakpoints.between('sm', 'md'))
  const breakpointMd = useMediaQuery(breakpoints.between('md', 'lg'))
  const breakpointLg = useMediaQuery(breakpoints.between('lg', 'xl'))
  const breakpointXl = useMediaQuery(breakpoints.up('lg'))
  const isMobile = !breakpointLg && !breakpointXl
  const isSmartphone = breakpointXs || breakpointSm
  const isSmartphoneXs = breakpointXs
  const isTablet = breakpointMd
  const isDesktop = breakpointLg || breakpointXl
  const isDesktopXl = breakpointXl

  return {
    isMobile,
    isSmartphone,
    isSmartphoneXs,
    isTablet,
    isDesktop,
    isDesktopXl,
  }
}