import { SESSION_INITIAL_STATE } from 'redux/utils/constants'
import { Action } from 'redux/utils/enums'
import { SessionAction, SessionState } from 'redux/utils/session.types'

const session = (state: SessionState = SESSION_INITIAL_STATE, action: SessionAction): SessionState => {
  switch (action.type) {
    case Action.SESSION_SIGN_IN: {
      const { isSignedIn, token, sessionData } = action.payload
      return {
        ...state,
        isSignedIn,
        token,
        sessionData,
      }
    }
    case Action.SESSION_SIGN_OUT:
      return SESSION_INITIAL_STATE
    case Action.SESSION_SIGN_IN_LOADING:
    case Action.SESSION_SIGN_OUT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case Action.SESSION_PASSWORD_RESET:
      return {
        ...state,
        isPasswordResetSuccessful: action.payload,
      }
    case Action.SESSION_PASSWORD_RESET_LOADING:
      return {
        ...state,
        isPasswordResetLoading: action.payload,
      }
    case Action.SESSION_GOOGLE_PROFILE_UPDATE:
      return {
        ...state,
        googleProfile: action.payload,
      }
    default:
      return state
  }
}

export default session