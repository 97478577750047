import { MachineDetailsPDFProps, MachineThumbnail, PDFDetailsParams } from 'pages/Machinery/utils/types'
import { useSelector } from 'react-redux'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { GetMachineDetailsResponse } from 'redux/utils/machinery.types'
import { ReduxStore } from 'redux/utils/types'

export const usePdfDetails = ({ thumbnailMachine, quotationValues, quotationDetails }: PDFDetailsParams): MachineDetailsPDFProps | null => {
  const dictionary: LanguageDictionary = useSelector((state: ReduxStore) => state.languageStore.dictionary)
  const language: Language = useSelector((state: ReduxStore) => state.languageStore.language)
  const reduxMachine: GetMachineDetailsResponse | undefined = useSelector((state: ReduxStore) => state.machineryStore.machineDetails)
  const machine: MachineThumbnail | GetMachineDetailsResponse | undefined = thumbnailMachine || reduxMachine

  if (!machine) {
    return null
  }

  const {
    category = '',
    manufacturer = '',
    model = '',
    reference = '',
    year = 0,
    hours = 0,
    price = 0,
    country = '',
    state = '',
    machineContact = '',
    machineContactPhone1 = '',
    machineContactPhone2 = '',
    contactMobile = '',
    machineContactEmail = '',
    description = '',
    photos = [],
  } = machine

  return {
    dictionary,
    language,
    fileName: `${category}-${manufacturer}-${model}-${reference}.pdf`,
    machine: {
      category,
      manufacturer,
      model,
      reference,
      year,
      hours,
      country,
      state,
      machineContact,
      machineContactPhone1,
      machineContactPhone2,
      contactMobile,
      machineContactEmail,
      description,
      photos,
      price,
    },
    quotationValues,
    quotationDetails,
  }
}