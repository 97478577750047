import { Card, Link } from '@mui/material'
import { DynamicRow, PasswordInput, PrimaryButton, SecondaryButton, StringInput } from 'components/FormFields'
import { LabelSeparator, Row } from 'components/styledComponents'
import { AlignItems, JustifyContent } from 'components/utils/enums'
import AccessControl from 'helpers/AccessControl'
import GoogleSignIn from 'helpers/GoogleSignIn'
import PageContainer from 'pages/components/PageContainer'
import { ADORNMENT_BG_COLOR } from 'pages/utils/constants'
import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { signIn } from 'redux/actions/session'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { SignInParams } from 'redux/utils/session.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { GUEST_MENU_MAP } from 'utils/constants'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  dispatchSignIn: (jsonParams: SignInParams) => void
}

const UnconnectedLogin: FC<Props> = ({
  dictionary: {
    navbar: navbarDictionary,
    signIn: signInDictionary,
    signUp: signUpDictionary,
  },
  language,
  dispatchSignIn,
}) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleUsernameChange = (newValue: string) => {
    setUsername(newValue)
  }

  const handlePasswordChange = (newValue: string) => {
    setPassword(newValue)
  }

  const handleRedirectToSignUpClick = () => {
    navigate(GUEST_MENU_MAP(language).signUp)
  }

  const handleSigninClick = (userAccount: string, userPassword: string) => (): void => {
    const jsonParams: SignInParams = {
      username: userAccount,
      password: userPassword,
    }

    dispatchSignIn(jsonParams)
  }

  return (
    <PageContainer>
      <Card className="login-card" variant="outlined">
        <StringInput
          id="username"
          label={signUpDictionary.fieldUsername}
          value={username}
          fullWidth
          required
          onChange={handleUsernameChange}
        />
        <PasswordInput
          id="password"
          label={signUpDictionary.fieldPassword}
          value={password}
          adornmentBgColor={ADORNMENT_BG_COLOR}
          fullWidth
          required
          onChange={handlePasswordChange}
        />
        <Link className="forgot-password" href={GUEST_MENU_MAP(language).forgotPassword}>
          {signInDictionary.forgotPassword}
        </Link>
        <Row marginTop={pxToRem(16)} justifyContent={JustifyContent.center}>
          <DynamicRow
            width={{ md: pxToRem(272) }}
            alignItems={AlignItems.baseline}
            justifyContent={JustifyContent.center}
            groupedByColumns={{ xs: '1', md: '2' }}
            gridRowGap={{ xs: pxToRem(8) }}
            gridColumnGap={{ xs: '0', md: pxToRem(8) }}
          >
            <SecondaryButton
              label={navbarDictionary.signUp}
              onClick={handleRedirectToSignUpClick}
            />
            <PrimaryButton
              label={signInDictionary.signIn}
              onClick={handleSigninClick(username, password)}
            />
          </DynamicRow>
        </Row>
      </Card>
      <Row isColumnDirection>
        <LabelSeparator>{signInDictionary.orLoginVia}</LabelSeparator>
        <GoogleSignIn />
      </Row>
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, usersStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { errorCode, isEmailValidated, validateEmailLoading } = usersStore

  return {
    dictionary,
    language,
    errorCode,
    isEmailValidated,
    validateEmailLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSignIn: (jsonParams: SignInParams) => dispatch(signIn(jsonParams)),
})

const Login: any = AccessControl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedLogin))

export default Login
