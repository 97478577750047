import { Grid, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CarouselFooterContainer } from 'components/styledComponents'
import React, { FC } from 'react'

type Props = {
  message: string
  linkLabel?: string
  linkURL?: string
}

const CarouselFooter: FC<Props> = ({ message, linkLabel, linkURL }) => {
  const { palette } = useTheme()

  return (
    <CarouselFooterContainer>
      {!!linkLabel && !!linkURL
        ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="button" color={palette.common.white}>
                  {message}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Link href={linkURL} variant="caption" color="secondary">
                  {linkLabel}
                </Link>
              </Grid>
            </Grid>
          )
        : (
            <Typography variant="button" color={palette.common.white}>
              {message}
            </Typography>
          )
      }
    </CarouselFooterContainer>
  )
}

export default CarouselFooter
