import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Breakpoint } from 'utils/enums'

export const useCurrentBreakpoint = (): Breakpoint => {
  const { breakpoints } = useTheme()

  const breakpointSm = useMediaQuery(breakpoints.between('sm', 'md'))
  const breakpointMd = useMediaQuery(breakpoints.between('md', 'lg'))
  const breakpointLg = useMediaQuery(breakpoints.between('lg', 'xl'))
  const breakpointXl = useMediaQuery(breakpoints.up('lg'))

  if (breakpointXl) {
    return Breakpoint.xl
  }

  if (breakpointLg) {
    return Breakpoint.lg
  }

  if (breakpointMd) {
    return Breakpoint.md
  }

  if (breakpointSm) {
    return Breakpoint.sm
  }

  return Breakpoint.xs
}