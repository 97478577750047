import { Switch } from '@mui/material'
import { ToggleContainer } from 'components/FormFields/styledComponents'
import React, { FC } from 'react'

type Props = {
  className?: string
  checked?: boolean
  onChange: (isChecked: boolean) => void
}

export const ToggleSwitch: FC<Props> = ({ className, checked, onChange }) => (
  <ToggleContainer className={className}>
    <Switch
      checked={checked}
      onChange={(_event, checked) => onChange(checked)}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  </ToggleContainer>
)