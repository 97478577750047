import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer'
import LogoImage from 'assets/imc_logo.png'
import monserratFont from 'fonts/Montserrat-ExtraLight.ttf'
import monserratBoldFont from 'fonts/Montserrat-Medium.ttf'
import cloneDeep from 'lodash/cloneDeep'
import momentTz from 'moment-timezone'
import 'moment/locale/es'
import { formatValueToHours } from 'pages/Machinery/utils/libs'
import { styles } from 'pages/Machinery/utils/pdfStyles'
import { MachineDetailsPDFProps } from 'pages/Machinery/utils/types'
import React, { FC } from 'react'
import { MachinePhoto } from 'redux/utils/machinery.types'

Font.register({
  family: 'Montserrat',
  src: monserratFont,
  format: 'truetype',
})

Font.register({
  family: 'Montserrat-Bold',
  src: monserratBoldFont,
  format: 'truetype',
})

const MachineDetailsPDF: FC<MachineDetailsPDFProps> = ({
  dictionary: {
    machinery: machineryDictionary,
    machineryPDF: pdfDictionary,
  },
  language,
  machine: {
    category,
    manufacturer,
    model,
    reference,
    year,
    hours,
    country,
    state,
    machineContact,
    machineContactPhone1,
    machineContactPhone2,
    contactMobile,
    machineContactEmail,
    description,
    photos,
  },
  quotationValues,
  quotationDetails,
}) => {
  if (!quotationValues || !quotationDetails) {
    return null
  }

  momentTz.locale(language)
  momentTz.localeData(language)
  const userTimezone = momentTz.tz.guess()
  const currentDatetime = momentTz().tz(userTimezone).format(pdfDictionary.dateFormat)
  const currentYear = momentTz().tz(userTimezone).format('YYYY')
  const photosCloned: MachinePhoto[] = cloneDeep(photos || [])
  let mainPhoto: string | undefined

  if (photosCloned.length) {
    mainPhoto = photosCloned[0].photo
    photosCloned.shift()
  }

  const hoursValue = hours
    ? formatValueToHours(hours)
    : machineryDictionary.na
  // const priceValue = price
  //   ? formatValueToMoney(price)
  //   : machineryDictionary.na
  // const usaShippingValue = usaShipping
  //   ? formatValueToMoney(usaShipping)
  //   : machineryDictionary.na
  // const usaInsuranceValue = usaInsurance
  //   ? formatValueToMoney(usaInsurance)
  //   : machineryDictionary.na
  // const mexShippingValue = mexShipping
  //   ? formatValueToMoney(mexShipping)
  //   : machineryDictionary.na
  // const mexFeeValue = mexFee
  //   ? formatValueToMoney(mexFee)
  //   : machineryDictionary.na
  // const mexHandlingValue = mexHandling
  //   ? formatValueToMoney(mexHandling)
  //   : machineryDictionary.na
  // const totalValue = total
  //   ? formatValueToMoney(total)
  //   : machineryDictionary.na
  // const isTotalRowEven = (!usaInsurance && !mexShipping) || (!!usaInsurance && !!mexShipping)

  return (
    <Document
      title={`${category} ${manufacturer} ${model} ${reference}`}
      author="IMCMEXICO"
      creator="Fernando Daniel Colón González"
    >
      <Page size="LETTER" orientation="portrait" style={styles.body}>
        <View style={styles.headerContainer}>
          <Image style={styles.headerLogo} src={LogoImage} />
          <View style={styles.headerDescriptionContainer}>
            <Text style={styles.headerDescriptionTitle}>{pdfDictionary.title}</Text>
            <Text style={styles.headerDescriptionSubtitle}>
              {pdfDictionary.subtitle} {currentDatetime}
            </Text>
          </View>
        </View>
        <View style={styles.machineCard}>
          <View style={styles.machinePhotoContainer}>
            {mainPhoto && (
              <Image
                src={mainPhoto}
                style={styles.machinePhoto}
                cache={false}
              />
            )}
          </View>
          <View style={styles.machineDetailsContainer}>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineCategory}>{category}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.manufacturer}</Text>
              <Text style={styles.machineValue}>{manufacturer}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.model}</Text>
              <Text style={styles.machineValue}>{model}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.reference}</Text>
              <Text style={styles.machineValue}>{reference}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.year}</Text>
              <Text style={styles.machineValue}>{year}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.hours}</Text>
              <Text style={styles.machineValue}>{hoursValue}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.machineLocation}</Text>
              <Text style={styles.machineValue}>{state}, {country}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.contactName}</Text>
              <Text style={styles.machineValue}>{machineContact}</Text>
            </View>
            <View style={styles.machineDetailsRow}>
              <Text style={styles.machineDescription}>{machineryDictionary.contactPhoneMain}</Text>
              <Text style={styles.machineValue}>{machineContactPhone1}</Text>
            </View>
            {machineContactPhone2 && (
              <View style={styles.machineDetailsRow}>
                <Text style={styles.machineDescription}>{machineryDictionary.contactPhoneSecondary}</Text>
                <Text style={styles.machineValue}>{machineContactPhone2}</Text>
              </View>
            )}
            {contactMobile && (
              <View style={styles.machineDetailsRow}>
                <Text style={styles.machineDescription}>{machineryDictionary.contactPhoneCell}</Text>
                <Text style={styles.machineValue}>{contactMobile}</Text>
              </View>
            )}
            {machineContactEmail && (
              <View style={styles.machineDetailsRow}>
                <Text style={styles.machineDescription}>{machineryDictionary.contactEmail}</Text>
                <Text style={styles.machineValue}>{machineContactEmail}</Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.descriptionCard}>
          <Text style={styles.descriptionTitle}>{machineryDictionary.description}</Text>
          <Text style={styles.descriptionContent}>{description}</Text>
        </View>
        {!!quotationValues && !!quotationDetails && (
          <View style={styles.quotationCard}>
            <Text style={styles.quotationTitle}>{machineryDictionary.quotation}</Text>
            <View style={styles.quotationTableRowOdd}>
              <Text style={styles.quotationDescription}>{machineryDictionary.quotationOrigin}</Text>
              <Text style={styles.quotationValue}>{quotationValues.origin}</Text>
            </View>
            <View style={styles.quotationTableRowEven}>
              <Text style={styles.quotationDescription}>{machineryDictionary.quotationDestination}</Text>
              <Text style={styles.quotationValue}>{quotationValues.destination}</Text>
            </View>
            <View style={styles.quotationTableRowOdd}>
              <Text style={styles.quotationDescription}>{machineryDictionary.quotationPlatformType}</Text>
              <Text style={styles.quotationValue}>{quotationValues.platform}</Text>
            </View>
            {quotationDetails.map((details, index) => {
              const rowStyle = index % 2 !== 0 ? styles.quotationTableRowOdd : styles.quotationTableRowEven

              return (
                <View style={rowStyle}>
                  <Text style={styles.quotationDescription}>{details.concept}</Text>
                  <Text style={styles.quotationValue}>{details.value}</Text>
                </View>
              )
            })}
          </View>
        )}

        {/* {!!usaShipping && !!mexShipping && (
          <View style={styles.quotationCard}>
            <Text style={styles.quotationTitle}>{machineryDictionary.quotation}</Text>
            {!!usaShipping && (
              <>
                <View style={styles.quotationTableRowOdd}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationOrigin}</Text>
                  <Text style={styles.quotationValue}>{origin}</Text>
                </View>
                <View style={styles.quotationTableRowEven}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationDestination}</Text>
                  <Text style={styles.quotationValue}>{destination}</Text>
                </View>
                <View style={styles.quotationTableRowOdd}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationPlatformType}</Text>
                  <Text style={styles.quotationValue}>{platform}</Text>
                </View>
                <View style={styles.quotationTableRowEven}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationPrice}</Text>
                  <Text style={styles.quotationValue}>{priceValue}</Text>
                </View>
                <View style={styles.quotationTableRowOdd}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationFreightUSA}</Text>
                  <Text style={styles.quotationValue}>
                    {usaShippingValue}
                  </Text>
                </View>
                {!!usaInsurance && (
                  <View style={styles.quotationTableRowEven}>
                    <Text style={styles.quotationDescription}>{machineryDictionary.quotationShippingInsurance}</Text>
                    <Text style={styles.quotationValue}>{usaInsuranceValue}</Text>
                  </View>
                ) }
              </>
            )}
            {!!mexShipping && (
              <>
                <View style={usaInsurance ? styles.quotationTableRowOdd : styles.quotationTableRowEven}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationFreightMEX}</Text>
                  <Text style={styles.quotationValue}>{mexShippingValue}</Text>
                </View>
                <View style={usaInsurance ? styles.quotationTableRowEven : styles.quotationTableRowOdd}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationFee}</Text>
                  <Text style={styles.quotationValue}>{mexFeeValue}</Text>
                </View>
                <View style={usaInsurance ? styles.quotationTableRowOdd : styles.quotationTableRowEven}>
                  <Text style={styles.quotationDescription}>{machineryDictionary.quotationHandling}</Text>
                  <Text style={styles.quotationValue}>{mexHandlingValue}</Text>
                </View>
              </>
            )}
            <View style={isTotalRowEven ? styles.quotationTableRowEven : styles.quotationTableRowOdd}>
              <Text style={styles.quotationDescription}>{machineryDictionary.quotationTotal}</Text>
              <Text style={styles.quotationValue}>{totalValue}</Text>
            </View>
          </View>
        )} */}
        <View style={styles.photosContainer}>
          {photosCloned.map(item => (
            <View key={item.photo} style={styles.photoBorder}>
              <Image
                src={item.photo}
                style={styles.photo}
                cache={false}
              />
            </View>
          ))}
        </View>
        <View style={styles.footer} fixed>
          <Text>
            IMCMEXICO, Copyright {currentYear}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default MachineDetailsPDF