import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CarouselHeaderContainer } from 'components/styledComponents'
import React, { FC } from 'react'

type Props = {
  message: string
}

const CarouselHeader: FC<Props> = ({ message }) => {
  const { palette } = useTheme()

  return (
    <CarouselHeaderContainer>
      <Typography variant="button" color={palette.common.white}>
        {message}
      </Typography>
    </CarouselHeaderContainer>
  )
}

export default CarouselHeader
