import { connectRouter } from 'connected-react-router'
import History from 'helpers/History'
import { loadingBarReducer } from 'react-redux-loading'
import { combineReducers } from 'redux'
import { alert } from 'redux/reducers/alert'
import { confirm } from 'redux/reducers/confirm'
import countries from 'redux/reducers/countries'
import language from 'redux/reducers/language'
import location from 'redux/reducers/location'
import machinery from 'redux/reducers/machinery'
import quotations from 'redux/reducers/quotations'
import requests from 'redux/reducers/requests'
import session from 'redux/reducers/session'
import states from 'redux/reducers/states'
import timezone from 'redux/reducers/timezone'
import users from 'redux/reducers/users'

const reducersList = {
  alertStore: alert,
  confirmStore: confirm,
  countriesStore: countries,
  languageStore: language,
  loadingBarStore: loadingBarReducer,
  locationStore: location,
  machineryStore: machinery,
  quotationsStore: quotations,
  requestsStore: requests,
  routerStore: connectRouter(History),
  sessionStore: session,
  statesStore: states,
  timezoneStore: timezone,
  usersStore: users,
}

export default combineReducers(reducersList)
